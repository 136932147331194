import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsExclamationTriangleFill, BsThreeDots } from "react-icons/bs";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

import {
  Modal,
  Alert,
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  Grid,
  Paper,
  Divider,
  Switch,
  Radio,
  FormControlLabel,
  InputLabel,
} from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Tabs } from "antd";
import axios from "axios";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomCalendar } from "./customCalendar";
import { ComboBox, MultiSelect } from "@progress/kendo-react-dropdowns";
import Loading from "./loading";

import {
  InputPrefix,
  InputSeparator,
  InputSuffix,
} from "@progress/kendo-react-inputs";

import Enumerable from "linq";
import { useNavigate } from "react-router-dom";
import { formatNumber, formatDate, numberSymbols } from "@telerik/kendo-intl";
import { useSelector } from "react-redux";
import { selectTheme } from "../features/theme/themeSlice";
import { SvgIcon } from "@mui/material";
import { blueGrey, green, lightBlue } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";
//
import { useMemo, useRef } from "react";
import { orderBy } from "@progress/kendo-data-query";
import { BsFillInfoCircleFill } from "react-icons/bs";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Col, Row } from "react-bootstrap";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Slide } from "@progress/kendo-react-animation";
import { lastDayOfMonth, isEqual } from "@progress/kendo-date-math";

const BlueSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: blueGrey[800],
    "&:hover": {
      backgroundColor: alpha(blueGrey[800], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: blueGrey[800],
  },
}));
const BlueenabledSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: lightBlue[400],
    "&:hover": {
      backgroundColor: alpha(lightBlue[700], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: lightBlue[400],
  },
}));
function CustomStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <SvgIcon
      {...props}
      className={className}
      sx={{
        width: 24,
        height: 24,
        color: completed || active ? "blue" : "gray", // Set color based on active/completed state
        "& .MuiStepIcon-text": {
          fill: completed || active ? "white" : "blue", // Set number color
        },
      }}
    >
      {/* You can also define a custom SVG icon here or use default icon*/}
      <circle cx="12" cy="12" r="10" stroke="black" strokeWidth="3" />
      <text
        x="50%"
        y="50%"
        alignmentBaseline="middle"
        textAnchor="middle"
        fontSize="12"
        fill="white"
      >
        {props.icon}
      </text>
    </SvgIcon>
  );
}
const custommodal = {
  main: "#1976d2",
  light: "#42a5f5",
  dark: "#1565c0",
  contrastText: "#fff",
};

//
const displaytype = [
  { typeId: "I", typeNm: "Investment Class Type" },
  { typeId: "N", typeNm: "Minor Asset Type" },
];

const options = [
  { text: "Major Asset Type", value: 0 },
  { text: "Minor Asset Type", value: 1 },
  { text: "Risk Class", value: 2 },
  { text: "Investment Class", value: 3 },
];
const marketCapitalList = [
  { CdId: 1, CdVlDesc: "Large Cap", SortKey: 5 },
  { CdId: 2, CdVlDesc: "Mid Cap", SortKey: 4 },
  { CdId: 3, CdVlDesc: "Small Cap", SortKey: 3 },
  { CdId: 4, CdVlDesc: "Others", SortKey: null },
  { CdId: 5, CdVlDesc: "Mega cap", SortKey: 6 },
  { CdId: 6, CdVlDesc: "Micro cap", SortKey: 2 },
  { CdId: 7, CdVlDesc: "Nano cap", SortKey: 1 },
];

const processingDate = new Date(localStorage.getItem("processingDate"));
const maxEndDate = lastDayOfMonth(processingDate);

var fromDate = new Date(localStorage.getItem("processingDate"));
fromDate.setMonth(fromDate.getMonth() - 12);
var RiskMetStrtDate = new Date(processingDate);
RiskMetStrtDate.setMonth(RiskMetStrtDate.getMonth() - 60);
RiskMetStrtDate = lastDayOfMonth(RiskMetStrtDate);

const paramsStatic = [
  {
    // "Account Profile"
    1: {
      ReportId: 1,
      AsOfDate: processingDate,
      PriceDate: processingDate,
      PriceFlag: 1,
      GrpByMnrAst: 0,
      ShowExcludeAsset: true,
      RunInAggregate: false,
    },
    // "Portfolio Holdings"
    2: {
      ReportId: 2,
      AsOfDate: processingDate,
      PriceDate: processingDate,
      PriceFlag: 1,
      DisplyPrcnt: 1,
      ShowExcludeAsset: true,
      ShowAllPositionsInd: true,
      ShwPrpsdTrds: false,
      RunInAggregate: false,
    },
    // "Account Holdings"
    3: {
      ReportId: 3,
      AsOfDate: processingDate,
      EffectiveDtInd: false,
      PriceDate: processingDate,
      RltnshpCd: "",
      PriceFlag: 1,
      ShwCPIAdjShrs: false,
    },
    // "Projected Cash Flow"
    4: {
      ReportId: 4,
      OverdraftRangeCheckBox: false,
      OverdraftRange: 0,
    },
    // "Account Performance Summary"
    5: {
      ReportId: 5,
      StartDate: fromDate,
      EndDate: processingDate,
      NetOfFeesInd: 1,
      AstType: { cdId: 2, cdVlDesc: "Major Asset Type" },
    },
    // "Asset Market By Capital"
    6: {
      ReportId: 6,
      MrkCap: marketCapitalList[0],
      RunInAggregate: false,
    },
    // "Fixed Income Fundamentals"
    7: {
      ReportId: 7,
      AsOfDate: processingDate,
      PriceDate: processingDate,
      PriceFlag: 1,
      ShowExcludeAsset: true,
      RunInAggregate: false,
    },
    // "Account Transaction",
    8: {
      ReportId: 8,
      StartDate: fromDate,
      EndDate: processingDate,
      EffectiveDtInd: true,
      ExcludeSupp: false,
      Swpind: false,
      RltnshpCd: "",
      PageNo: 0,
    },
    // "Account Sector Comparison"
    9: {
      ReportId: 9,
      //modelId will sent
      BenchMarkId: {
        modelDesc: "None",
        modelId: -1,
      },
      AsOfDate: processingDate,
      PriceDate: processingDate,
      PriceFlag: 1,
      ShowExcludeAsset: true,
      AggregateMode: 0,
    },
    //  "Portfolio Performance By Security"
    10: {
      ReportId: 10,
      mPriceDt: processingDate,
      ePriceDt: processingDate,
      mPriceFlag: 1,
      ePriceFlag: 1,
      ShowExcludeAsset: true,
      AsOfStartDt: processingDate,
      AsOfEndDt: processingDate,
      RunInAggregate: false,
    },
    // "Portfolio Breakdown"
    11: {
      ReportId: 11,
      Model: { modelDesc: "None", modelId: -1 },
      BenchMark: {
        modelDesc: "None",
        modelId: -1,
      },
      AsOfDate: processingDate,
      PriceDate: processingDate,
      PriceFlag: 1,
      ShowExcludeAsset: true,
      RunInAggregate: false,
    },
    // "Asset Allocation Vs Model"
    12: {
      ReportId: 12,
      ModelId: -1,
      InvMix: 1,
      AsOfDate: processingDate,
      PriceDate: processingDate,
      PriceFlag: 1,
      ShowExcludeAsset: true,
      RunInAggregate: false,
    },
    // "Purchase & Sell"
    13: {
      ReportId: 13,
      StartDate: fromDate,
      EndDate: processingDate,
      IncludeOrders: 2,
      ArchvInd: false,
      MutualFundInd: 1,
      RunInAggregate: false,
    },
    // "Portfolio Snapshot Comparison"
    14: {
      ReportId: 14,
      GrpByMnrAst: options[0],
      currDt: processingDate,
      snapDt: processingDate,
      PortfolioPriceDt: processingDate,
      PortfolioPriceFlag: 1,
      SnapPriceDt: processingDate,
      SnapPriceFlag: 1,
      ShowExcludeAsset: true,
      RunInAggregate: false,
    },
    // "Account Sector Return"
    15: {
      ReportId: 15,
      StartDate: fromDate,
      EndDate: processingDate,
      // PerfId: "0",
    },
    // "Time Weighted Return For Selected Period"
    16: {
      ReportId: 16,
      NetOfFeesInd: 1,
      PageId: 1,
      StartDate: fromDate,
      EndDate: processingDate,
      RltnshpCd: "",
      RunInAggregate: false,
    },
    // "Fixed Income Portfolio Overview"
    17: {
      ReportId: 17,
      AsOfDate: processingDate,
      PriceDate: processingDate,
      PriceFlag: 1,
      BandYearFlag: 1,
      ShowExcludeAsset: true,
      ShwPrpsdTrds: true,
      ShwBondByIndSct: true,
      ShwAssetType: {
        typeId: "I",
        typeNm: "Investment Class Type",
      },
      RunInAggregate: false,
    },
    // "Fixed Income Maturity Ladder"
    18: {
      ReportId: 18,
      StartDate: fromDate,
      EndDate: processingDate,
      AsOfDate: processingDate,
      PriceDate: processingDate,
      PriceFlag: 1,
      ShowExcludeAsset: true,
      RunInAggregate: false,
    },
    // "Historical Market Performance"
    19: {
      ReportId: 19,
      AsOfDate: processingDate,
      StartDate: fromDate,
      EndDate: processingDate,
      RunInAggregate: false,
    },
    // "Asset Growth Allocation report"
    20: {
      ReportId: 20,
      AsOfDate: processingDate,
      HistYearCount: 6,
      NetFeeInd: false,
      OvrdIncptn: false,
      RunInAggregate: false,
    },
    // "Annual Portfolio Values"
    21: {
      ReportId: 21,
      AsOfDate: processingDate,
      NetFeeInd: true,
      OvrdIncptn: true,
      RunInAggregate: false,
    },
    // "Performance Risk Metrics"
    22: {
      ReportId: 22,
      StartDate: RiskMetStrtDate,
      //End date => As of Date
      EndDate: maxEndDate,
      showAccountPortfolio: true,
      showL1SegmentBenchmark: true,
      showCheckedMetrices: "3767,3768,3769,3770,3773,3774,3778,3779,3780,3781",
      showSL1Category:
        "1,2,3,13,4,5,6,7,9,10,11,12,22,14,15,23,24,25,26,27,28,29,30,31,32,33,34,35,36",
      RiskFreeBenchmarkId: 145,
    },
    // "Historical Monthly Performance"
    23: {
      ReportId: 23,
      StartDate: fromDate,
      EndDate: processingDate,
      AggregateMode: 0,
      NetOfFeesInd: 1,
      DsplyBnchmrks: 1,
      BlndBnchmrk: 1,
    },
    // "Benchmark Selected Period Performance Report"
    24: {
      ReportId: 24,
      AsOfDate: processingDate,
      BenchmarkList: ",14,2,3,11,6,5,16,8,7,4,1,9",
      RelatedBnchmrkInd: 0,
      RunInAggregate: false,
    },
    // "Selected Period ROR SMA Report"
    25: {
      ReportId: 25,
      AsOfDate: processingDate,
    },
  },
];

const reportOptions = [
  {
    id: 1,
    title: "Account Profile",
    description: " ",
  },
  {
    id: 2,
    title: "Portfolio Holdings",
    description:
      "A comprehensive list of all securities held in the portfolio, including relevant details like cost and market value.",
  },
  {
    id: 3,
    title: "Account Holdings",
    description: " ",
  },
  {
    id: 4,
    title: "Projected Cash Flow",
    description: " ",
  },
  {
    id: 5,
    title: "Account Performance Summary",
    description: " ",
  },

  {
    id: 6,
    title: "Asset Market By Capital",
    description: " ",
  },
  {
    id: 7,
    title: "Fixed Income Fundamentals",
    description: " ",
  },
  {
    id: 8,
    title: "Account Transaction",
    description: " ",
  },
  {
    id: 9,
    title: "Account Sector Comparison",
    description: " ",
  },
  {
    id: 10,
    title: "Portfolio Performance By Security",
    description: " ",
  },
  {
    id: 11,
    title: "Portfolio Breakdown",
    description:
      "A visual and data-driven representation of portfolio allocations across asset classes, sectors, or geographies.",
  },
  {
    id: 12,
    title: "Asset Allocation Vs Model",
    description: " ",
  },
  {
    id: 13,
    title: "Purchase & Sell",
    description: " ",
  },
  {
    id: 14,
    title: "Portfolio Snapshot Comparison",
    description: " ",
  },
  {
    id: 15,
    title: "Account Sector Return",
    description: " ",
  },
  {
    id: 16,
    title: "Time Weighted Return For Selected Period",
    description: " ",
  },
  {
    id: 17,
    title: "Fixed Income Portfolio Overview",
    description:
      "A high-level summary of the fixed-income portfolio, showcasing allocation, performance, and key metrics.",
  },
  {
    id: 18,
    title: "Fixed Income Maturity Ladder",
    description: " ",
  },

  {
    id: 19,
    title: "Historical Market Performance",
    description:
      "An analysis of past market trends and movements to assess overall market conditions.",
  },

  {
    id: 20,
    title: "Asset Growth Allocation report",
    description: " ",
  },
  {
    id: 21,
    title: "Annual Portfolio Values",
    description: " ",
  },
  {
    id: 22,
    title: "Performance Risk Metrics",
    description:
      "Detailed insights into risk factors, including volatility, drawdowns, and performance-related risks.",
  },
  {
    id: 23,
    title: "Historical Monthly Performance",
    description:
      "Month-by-month performance tracking for historical evaluation and trend identification.",
  },
  {
    id: 24,
    title: "Benchmark Selected Period Performance Report",
    description: " ",
  },
  {
    id: 25,
    title: "Selected Period ROR SMA Report",
    description: " ",
  },
];

// const steps = ["Report Details Form", "Parameter Selection", "Preview"];
const steps = ["Report Details Form", "Parameter Selection"];

const marcaptyplst = [
  { CdId: 1, CdVlDesc: "Large Cap", SortKey: 5 },
  { CdId: 2, CdVlDesc: "Mid Cap", SortKey: 4 },
  { CdId: 3, CdVlDesc: "Small Cap", SortKey: 3 },
  { CdId: 4, CdVlDesc: "Others", SortKey: null },
  { CdId: 5, CdVlDesc: "Mega cap", SortKey: 6 },
  { CdId: 6, CdVlDesc: "Micro cap", SortKey: 2 },
  { CdId: 7, CdVlDesc: "Nano cap", SortKey: 1 },
];

const paramDefaults = {
  AsOfDate: false,
  PriceDate: false,
  PriceFlag: false,
  ShowExcludeAsset: false,
  SwpInd: false,
  UserId: false,
  AcctId: false,
  ConsolidationId: false,
  ShwPrpsdTrads: false,
  EffectiveDtInd: false,
  ArchvInd: false,
  IncludeOrders: false,
  ExcludeSupp: false,
  DisplyPrcnt: false,
  BenchmarkId: false,
  ModelId: false,
  FromDate: false,
  ToDate: false,
  ShwCPIAdjShares: false,
  MFInd: false,
  ShowAccountPortfolio: false,
  BnchMarkList: false,
  Marketcap: false,
  InvMix: false,
  ShowAllPositionInd: false,
};

const reportsParams = {
  1: ["AsOfDate", "PriceDate", "PriceFlag", "ShowExcludeAsset"],
  2: [
    "AsOfDate",
    "PriceDate",
    "PriceFlag",
    "DisplyPrcnt",
    "ShowExcludeAsset",
    "ShowAllPositionInd",
    "ShwPrpsdTrads",
  ],
  3: [
    "AsOfDate",
    "EffectiveDtInd",
    "PriceDate",
    "PriceFlag",
    "ShwCPIAdjShares",
  ],
  4: [],
  5: ["FromDate", "ToDate"],
  6: ["Marketcap"],
  7: ["AsOfDate", "PriceDate", "PriceFlag", "ShowExcludeAsset"],
  8: ["FromDate", "ToDate", "EffectiveDtInd", "ExcludeSupp", "SwpInd"],
  9: ["BenchmarkId", "AsOfDate", "PriceDate", "PriceFlag", "ShowExcludeAsset"],
  10: ["PriceDate", "PriceFlag", "ShowExcludeAsset", "FromDate", "ToDate"],
  11: [
    "ModelId",
    "BenchmarkId",
    "AsOfDate",
    "PriceDate",
    "PriceFlag",
    "ShowExcludeAsset",
  ],
  12: [
    "ModelId",
    "InvMix",
    "AsOfDate",
    "PriceDate",
    "PriceFlag",
    "ShowExcludeAsset",
  ],
  13: ["FromDate", "ToDate", "IncludeOrders", "ArchvInd"],
  14: ["AsOfDate", "PriceDate", "PriceFlag", "ShowExcludeAsset"],
  15: ["FromDate", "ToDate"],
  16: ["FromDate", "ToDate"],
  17: [
    "AsOfDate",
    "PriceDate",
    "PriceFlag",
    "ShowExcludeAsset",
    "ShwPrpsdTrads",
  ],
  18: [
    "FromDate",
    "ToDate",
    "AsOfDate",
    "PriceDate",
    "PriceFlag",
    "ShowExcludeAsset",
  ],
  19: ["AsOfDate"],
  20: ["AsOfDate"],
  21: ["AsOfDate"],
  22: ["FromDate", "AsOfDate", "ShowAccountPortfolio"],
  23: ["FromDate", "ToDate"],
  24: ["AsOfDate", "BnchMarkList"],
  25: ["AsOfDate"],
};

const CustomReportModal = ({
  menuStateData,
  update,
  open,
  existTemplateName,
  existTemplateDescription,
  onClose,
  onCstmRptModal,
  reportList,
  disableFlag,
  tmpId,
  vieweditdisable,
  addNewState,
  newTemp = false,
}) => {
  console.log("reportList", reportList);
  const selectedReportList = reportList.map((report) => {
    return report.reportId;
  });

  const [TabOptions, setTabOptions] = useState([
    {
      id: 0,
      title: "General",
      description: " ",
    },
  ]);

  console.log("selectedReportList", selectedReportList);
  console.log("tmpId", tmpId);
  const themes = useSelector(selectTheme);
  const [selectedReports, setSelectedReports] = useState(selectedReportList);
  const [activeStep, setActiveStep] = useState(0);
  const [isPublic, setIsPublic] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const [paramstaticobj, setparamstaticobj] = useState(paramsStatic);
  const [minFrmDt, setminFrmDt] = React.useState(minDate);
  const [reportdisable, setreportdisable] = useState(false);

  // for step 2 [use states]
  var date = new Date(localStorage.getItem("processingDate"));
  var minDate = new Date(localStorage.getItem("processingDate"));
  minDate.setMonth(minDate.getMonth() - 60);
  var initialFrmDate = new Date(localStorage.getItem("processingDate"));
  initialFrmDate.setMonth(date.getMonth() - 12);
  var maxDate = date;
  var maxFrmDt = date;

  let userId = JSON.parse(localStorage.getItem("userId"));

  const navigate = useNavigate();

  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [asOfDt, setasOfDt] = React.useState(date);
  const [frmDate, setfrmDate] = React.useState(initialFrmDate);
  const [toDate, setToDate] = useState(date);
  const [isDisabled, setisDisabled] = React.useState(false);

  const [priceDt, setpriceDt] = React.useState(date);
  const [priceFlagInd, setPriceFlagInd] = useState(1);
  const [showAllPositionsIndic, setShowAllPositionsIndic] = useState(false);
  const [invmixcheck, setinvmixcheck] = useState(false);
  const [effectivecDtInd, seteffectiveDtInd] = useState(false);
  const [excludedAsset, setExculdedAsset] = useState(true);
  const [excludeSuppress, setexcludeSuppress] = useState(false);
  const [excludeSweep, setexcludeSweep] = useState(false);
  const [displayPercentInd, setDisplayPercentInd] = useState(1);
  const [displayPercentOption, setDisplayPercentOption] = useState(1);
  const [showProcessTrades, setShowProcessTreds] = useState(false);
  const [tipsInd, SetTipsInd] = useState(false);
  const [tradeOptionValue, setTradeOptionValue] = useState(2);
  const [selectedTradeOptions, setselectedTradeOptions] = useState({
    stagedTrades: false,
    pendingTrades: true,
  });

  const [shwTtlPortfolio, setShwTtlPortfolio] = useState(true);
  const [mutualFundTrades, setMutualFundTrades] = useState(1);
  const [selMarCapTyp, setSelMarCapTyp] = useState(marcaptyplst[0]);
  const [validationError, setValidationError] = useState("");

  const [countries, seCountries] = React.useState([]);

  const [state, setState] = React.useState({
    value: [],
    allSelected: true,
  });

  const [benchmarkList, setBenchmarkList] = React.useState([]);
  const [flagBenchmark, setFlagBenchmark] = React.useState(false);
  const [session, setSession] = useState("");

  let ArchvInd = false;

  const [customParams, setCustomParams] = useState(paramDefaults);
  const [selectedReportParams, setSelectedReportParams] = useState([]);

  const [updatedDefaults, setUpdatedDefaults] = useState(paramDefaults);

  const [updatedParams, setUpdatedParams] = useState(paramDefaults);
  const [selectedOption, setSelectedOption] = useState(1);

  const [templateID, setTemplateID] = useState(0);

  const [templateName, setTemplateName] = useState(
    existTemplateName !== null ? existTemplateName : ""
  );
  const [templateDescription, setTemplateDescription] = useState(
    existTemplateDescription !== null ? existTemplateDescription : ""
  );

  let AcctId =
    JSON.parse(localStorage.getItem("SelAcctId")) != 0
      ? JSON.parse(localStorage.getItem("SelAcctId"))
      : 0;

  let AccountId =
    JSON.parse(localStorage.getItem("SelAcctId")) != null
      ? JSON.parse(localStorage.getItem("SelAcctId"))
      : 0;

  let consolidationId =
    JSON.parse(localStorage.getItem("pConsId")) != null
      ? JSON.parse(localStorage.getItem("pConsId"))
      : 0;

  const [templateParams, setTemplateParams] = useState({
    templateId: disableFlag ? tmpId : templateID,
    userId: userId,
    accountId: AccountId,
    consolidationId: consolidationId,
    asOfDt: asOfDt,
    priceDt: priceDt,
    priceFlag: 1,
    showExcludAsset: true,
    fromDate: frmDate,
    toDate: toDate,
    effectiveDtInd: false,
    excludeSupp: false,
    swpInd: false,
    showCPIAdjShares: false,
    displayPrcnt: 0,
    showAllPositionInd: true,
    showProposedTrades: false,
    marketCap: 0,
    modelId: -1,
    benchMarkId: -1,
    invMix: 1,
    includeOrders: 3,
    archvInd: 0,
    mfInd: 0,
    benchmarkList: ",14,2,3,11,6,5,16,8,7,4,1,9",
    relatedBenchmarkInd: true,
    showAcctPortfolio: true,
  });

  const [priceDtAcPr, setpriceDtAcPr] = React.useState(date);
  const [selectedOptionAcPr, setSelectedOptionAcPr] = useState(1);
  const [priceDtPortH, setpriceDtPortH] = React.useState(date);
  const [priceDtAccH, setpriceDtAccH] = React.useState(date);
  const [showOverdraftCash, setshowOverdraftCash] = useState(false);
  const [overdraftValCash, setOverdraftValCash] = React.useState("");
  const [errorvisible, seterrorvisible] = useState(false);

  const [assetTypLst, setAssetTypLst] = useState([]);
  const [assetType, setAssetType] = useState();

  const [modellist, setModellist] = useState(
    JSON.parse(localStorage.getItem("modelList"))
  );

  const [selectedOptions, setSelectedOptions] = useState({
    stagedTrades: false,
    pendingTrades: true,
  });

  const [showCustomPeriod, setshowCustomPeriod] = useState(false);
  const [isDisabledCstPeriod, setisDisabledCstPeriod] = React.useState(true);

  const [runInAggregate, setRunInAggregate] = useState(false);

  const [assetAllocModel, setAssetAllocModel] = useState([]);
  const [benchmarkListSelPer, setBenchmarkListSelPer] = useState([]);
  const [portfolioBreakdownBenchmark, setPortfolioBreakdownBenchmark] =
    useState([]);
  const [portfolioBreakdownModel, setPortfolioBreakdownModel] = useState([]);

  const [selChangeModel, setSelChangeModel] = useState([]);

  //   const [existTemplateName, setExistTemplateName] = useState(existTemplateName);
  // const [existTemplateDescription, setExistTemplateDescription] = useState(existTemplateDescription);

  console.log("existTemplateName", existTemplateName);
  console.log("existTemplateDescription", existTemplateDescription);

  // functions

  const GetAllModelBnchmrk = async () => {
    let token = tempToken;

    let asOfId = JSON.parse(localStorage.getItem("userId"));
    let acctId =
      JSON.parse(localStorage.getItem("SelAcctId")) != null
        ? JSON.parse(localStorage.getItem("SelAcctId"))
        : 0;
    let consolidationId =
      JSON.parse(localStorage.getItem("pConsId")) != null
        ? JSON.parse(localStorage.getItem("pConsId"))
        : 0;

    const postData = { asOfId, acctId, consolidationId };
    debugger;
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    await axios
      .post("/RTGetAllModelBnchmrk ", postData, config)
      .then((response) => {
        debugger;
        let rowdata = response.data;
        localStorage.setItem(
          "modelList",
          JSON.stringify(response.data.portfolioBreakDownModel)
        );
        console.log("response for model benchmark", response.data);

        localStorage.setItem(
          "assetAllocModelId",
          JSON.stringify(response.data.assetAllocModelT1[0].modelId)
        );

        // let modellist = Enumerable.from(rowdata.model)

        // .where((w) => w.modelId !== -1)

        // .toArray();

        let selmdl = rowdata.assetAllocModelT1.filter(
          (x) => x.modelId === response.data.assetAllocModelT1[0].modelId
        );

        setSelChangeModel(
          selmdl[0].modelId === -1
            ? { modelId: -1, modelDesc: "None" }
            : selmdl[0]
        );

        console.log("rowdata.assetAllocModelT1", rowdata.assetAllocModelT1);

        if (rowdata.assetAllocModelT1.length != 0) {
          setAssetAllocModel(rowdata.assetAllocModelT1);
        }

        if (rowdata.benchmarkList.length != 0) {
          setBenchmarkListSelPer(rowdata.benchmarkList);
        }

        if (rowdata.portfolioBreakDownBenchmark.length != 0) {
          setPortfolioBreakdownBenchmark(rowdata.portfolioBreakDownBenchmark);
        }

        if (rowdata.portfolioBreakDownModel.length != 0) {
          setPortfolioBreakdownModel(rowdata.portfolioBreakDownModel);
        }
      })
      .catch((error) => {
        debugger;
        console.log("GetAllModelBnchmrkERROR:", error);
        if (error.response.status === 401) {
          refreshToken();
        }
        // return error;
      });
  };

  const [portfolioBreakSelModel, setPortfolioBreakSelModel] = useState(
    portfolioBreakdownModel
      ? portfolioBreakdownModel[0]
      : { modelDesc: "None", modelId: -1 }
  );

  const [selBnchmrk, setSelBnchmrk] = useState({
    modelDesc: "None",
    modelId: -1,
  });

  const handlerunInAggregate = (e, id) => {
    debugger;
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].RunInAggregate = e.currentTarget.checked;
    if (id == 9 || 23) {
      tempparamstaticobj[0][id].AggregateMode = e.currentTarget.checked ? 1 : 0;
    }
    setparamstaticobj(tempparamstaticobj);

    // setRunInAggregate(!runInAggregate);
  };

  const handleRadioChange = (e) => {
    debugger;
    for (let i = 1; i <= 25; i++) {
      debugger;
      if (paramstaticobj[0]?.[i]?.PriceFlag !== undefined) {
        paramstaticobj[0][i].PriceFlag = parseInt(e.target.value);
      }
    }
    setSelectedOption(parseInt(e.target.value));
    setPriceFlagInd(parseInt(e.target.value));
    console.log("e.target.value", e.target.value);
    console.log("e", e);
    setTemplateParams((prevParams) => ({
      ...prevParams,
      priceFlag: parseInt(e.target.value),
    }));
    if (e.target.value == 1 || e.target.value == 2) {
      //setpriceDt(formatDate(date, "MM/dd/yyyy")); // to be checked
      setpriceDt(date);
      setTemplateParams((prevParams) => ({
        ...prevParams,
        priceDt: date,
      }));
    }
    // console.log("selectedOption", selectedOption);
    // console.log("Selected Price Date", priceDt);
  };

  useEffect(() => {
    //
    const fetchData = async () => {
      // setLoading(true);
      try {
        GetBenchmarkList();
        //getColumnStateDb();
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
    if (newTemp != true) {
      handleNext();
    }
  }, []);

  const handleExculdedAssets = (e) => {
    debugger;
    for (let i = 1; i <= 25; i++) {
      debugger;
      if (paramstaticobj[0]?.[i]?.ShowExcludeAsset !== undefined) {
        paramstaticobj[0][i].ShowExcludeAsset = e.target.checked;
      }
    }
    setExculdedAsset(e.target.checked);
    setTemplateParams((prevParams) => ({
      ...prevParams,
      showExcludAsset: e.target.checked,
    }));
  };

  const handleExcludeSweep = (e) => {
    for (let i = 1; i <= 25; i++) {
      debugger;
      if (paramstaticobj[0]?.[i]?.Swpind !== undefined) {
        paramstaticobj[0][i].Swpind = e.target.checked;
      }
    }
    setexcludeSweep(e.target.checked);
    setTemplateParams((prevParams) => ({
      ...prevParams,
      swpInd: e.target.checked,
    }));
  };

  const handleEffectiveDtInd = (e) => {
    for (let i = 1; i <= 25; i++) {
      debugger;
      if (paramstaticobj[0]?.[i]?.EffectiveDtInd !== undefined) {
        paramstaticobj[0][i].EffectiveDtInd = e.target.checked;
      }
    }
    seteffectiveDtInd(e.target.checked ? true : false);
    setTemplateParams((prevParams) => ({
      ...prevParams,
      effectiveDtInd: e.target.checked ? true : false,
    }));
  };
  const handleExcludeSuppress = (e) => {
    for (let i = 1; i <= 25; i++) {
      debugger;
      if (paramstaticobj[0]?.[i]?.ExcludeSupp !== undefined) {
        paramstaticobj[0][i].ExcludeSupp = e.target.checked;
      }
    }
    setexcludeSuppress(e.target.checked);
    setTemplateParams((prevParams) => ({
      ...prevParams,
      excludeSupp: e.target.checked,
    }));
  };

  const initialSort = [
    {
      field: "id",
      dir: "asc",
    },
  ];

  const handleInvmix = (e) => {
    for (let i = 1; i <= 25; i++) {
      debugger;
      if (paramstaticobj[0]?.[i]?.InvMix !== undefined) {
        paramstaticobj[0][i].InvMix = e.target.checked ? 1 : 0;
      }
    }
    setinvmixcheck(e.target.checked ? 1 : 0);
    // setSelChangeModel(
    //   e.target.checked ? { modelId: -1, modelNm: "None" } : selChangeModel
    // );
    localStorage.setItem("modelId", JSON.stringify(-1));
    setTemplateParams((prevParams) => ({
      ...prevParams,
      invMix: e.target.checked ? 1 : 0,
    }));
  };
  const handleDisplayPercent = (e) => {
    setDisplayPercentOption(parseInt(e.target.value));
    setDisplayPercentInd(parseInt(e.target.value));
    setTemplateParams((prevParams) => ({
      ...prevParams,
      displayPrcnt: parseInt(e.target.value),
    }));
  };
  const handleAllPositionsIndicator = (e) => {
    for (let i = 1; i <= 25; i++) {
      debugger;
      if (paramstaticobj[0]?.[i]?.ShowAllPositionsInd !== undefined) {
        paramstaticobj[0][i].ShowAllPositionsInd = e.target.checked ? 1 : 0;
      }
    }
    setShowAllPositionsIndic(e.target.checked ? true : false);

    setTemplateParams((prevParams) => ({
      ...prevParams,
      showAllPositionInd: e.target.checked ? true : false,
    }));
  };

  const handleTipsInd = (e) => {
    for (let i = 1; i <= 25; i++) {
      debugger;
      if (paramstaticobj[0]?.[i]?.ShwCPIAdjShrs !== undefined) {
        paramstaticobj[0][i].ShwCPIAdjShrs = e.target.checked;
      }
    }
    SetTipsInd(e.target.checked ? true : false);
    setTemplateParams((prevParams) => ({
      ...prevParams,
      showCPIAdjShares: e.target.checked ? true : false,
    }));
  };

  const handleShowProcessTrades = (e) => {
    for (let i = 1; i <= 25; i++) {
      debugger;
      if (paramstaticobj[0]?.[i]?.ShwPrpsdTrds !== undefined) {
        paramstaticobj[0][i].ShwPrpsdTrds = e.target.checked;
      }
    }
    setShowProcessTreds(e.target.checked ? true : false);

    setTemplateParams((prevParams) => ({
      ...prevParams,
      showProposedTrades: e.target.checked ? true : false,
    }));
  };
  const handleTradeOptionsChange = (e) => {
    const { name, checked } = e.target;

    // Update the selectedTradeOptions state
    setselectedTradeOptions((prev) => ({
      ...prev,
      [name]: checked,
    }));

    // Calculate the new value dynamically based on the checkbox states
    const newselectedTradeOptions = {
      ...selectedTradeOptions,
      [name]: checked,
    };

    setTradeOptionValue(
      (newselectedTradeOptions.stagedTrades ? 1 : 0) +
        (newselectedTradeOptions.pendingTrades ? 2 : 0)
    );
    setTemplateParams((prevParams) => ({
      ...prevParams,
      includeOrders:
        (newselectedTradeOptions.stagedTrades ? 1 : 0) +
        (newselectedTradeOptions.pendingTrades ? 2 : 0),
    }));
  };

  const handleShwTtlPortfolio = (event) => {
    for (let i = 1; i <= 25; i++) {
      debugger;
      if (paramstaticobj[0]?.[i]?.showAccountPortfolio !== undefined) {
        paramstaticobj[0][i].showAccountPortfolio = event.target.checked;
      }
    }

    setShwTtlPortfolio(event.target.checked); // Capture whether the checkbox is checked or unchecked
  };

  const handleMutualFundTrades = (e) => {
    for (let i = 1; i <= 25; i++) {
      debugger;
      if (paramstaticobj[0]?.[i]?.MutualFundInd !== undefined) {
        paramstaticobj[0][i].MutualFundInd = e.target.checked ? 1 : 0;
      }
    }
    setMutualFundTrades(e.target.checked ? 1 : 0);
    setTemplateParams((prevParams) => ({
      ...prevParams,
      mfInd: e.target.checked ? 1 : 0,
    }));
  };

  const mrktCapTypChange = (event) => {
    if (event.target.value === null) {
      setSelMarCapTyp({ CdId: -1, CdVlDesc: "None", SortKey: -1 });
      setTemplateParams((prevParams) => ({
        ...prevParams,
        marketCap: { CdId: -1, CdVlDesc: "None", SortKey: -1 }.CdId,
      }));
    } else {
      for (let i = 1; i <= 25; i++) {
        debugger;
        if (paramstaticobj[0]?.[i]?.MrkCap !== undefined) {
          paramstaticobj[0][i].MrkCap = event.target.value?.CdId;
        }
      }
      setSelMarCapTyp(event.target.value);
      setValidationError("");
      setTemplateParams({
        ...templateParams,
        marketCap: event.target.value?.CdId,
      });
    }
  };

  const itemRender = (li, itemProps) => {
    const itemChildren = (
      <span>
        <input
          type="checkbox"
          name={itemProps.dataItem}
          checked={itemProps.selected}
          onChange={(e) => itemProps.onClick(itemProps.index, e)}
          style={{ zIndex: 9999999999 }}
        />
        &nbsp;{li.props.children}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const value = state.value;
  const selected = value.length;

  const handleChangeBenchmark = (event) => {
    const currentSelectAll = state.value.some((i) => i === "Select All");
    const nextSelectAll = event.value.some((i) => i === "Select All");
    let value = event.value;
    const currentCount = state.value.length;
    const nextCount = value.length;

    if (
      nextCount > currentCount &&
      !currentSelectAll &&
      !nextSelectAll &&
      countries.length - 1 === nextCount
    ) {
      value = countries;
    } else if (
      nextCount < currentCount &&
      currentCount === countries.length &&
      currentSelectAll &&
      nextSelectAll
    ) {
      value = value.filter((v) => v !== "Select All");
    } else if (!currentSelectAll && nextSelectAll) {
      value = countries;
    } else if (currentSelectAll && !nextSelectAll) {
      value = [];
    }

    setState({ value });

    // for post data
    var newBenchmarkList = "";

    for (var i = 0; i < state.value.length; i++) {
      if (state.value[i] != "Select All") {
        var arrSelBenchmark = Enumerable.from(
          JSON.parse(localStorage.getItem("benchmarkList"))
        )
          .where("$.bnchmrkName =='" + state.value[i] + "'")
          .select("$.blndBnchmrkId")
          .toArray();
        if (arrSelBenchmark.length > 0)
          newBenchmarkList = newBenchmarkList + "," + arrSelBenchmark[0];
      }
    }
    var benchmarkList = newBenchmarkList;

    setTemplateParams((prevParams) => ({
      ...prevParams,
      benchmarkList: benchmarkList,
    }));
  };

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetBenchmarkList();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

  const GetBenchmarkList = async () => {
    let token = tempToken;

    const postData = {};

    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    await axios
      .post("/RTGetBenchmarkList ", postData, config)
      .then((response) => {
        setBenchmarkList(response.data.results);
        localStorage.setItem(
          "benchmarkList",
          JSON.stringify(response.data.results)
        );
        //  setBenchmarkList(response.data.results);
        let bAll = [
          { blndBnchmrkId: -1, bnchmrkName: "Select All" },
          ...response.data.results,
        ];

        let country = Enumerable.from(bAll)

          .select("$.bnchmrkName")
          .toArray();
        seCountries(country);
        // let bAll = [
        //   { mnrAstTypId: -1, mnrAstTypName: "Select All" },
        //   ...response.data.results,
        // ];

        // let country = Enumerable.from(bAll)

        //   .select("$.mnrAstTypName")
        //   .toArray();

        setState({ value: country, allSelected: true });
        setFlagBenchmark(true);
        console.log(response);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        // return error;
      });
  };

  const submitTemplate = async () => {
    let token = tempToken;

    let userId = JSON.parse(localStorage.getItem("userId"));

    let templateId =
      tmpId.length == 0 ? parseInt(localStorage.getItem("TmpltId")) : tmpId;

    let acctId =
      JSON.parse(localStorage.getItem("SelAcctId")) != null
        ? JSON.parse(localStorage.getItem("SelAcctId"))
        : 0;

    let consolidationId =
      JSON.parse(localStorage.getItem("pConsId")) != null
        ? JSON.parse(localStorage.getItem("pConsId"))
        : 0;
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,

        Accept: "application/json",

        "Content-Type": "application/json",
      },
    };

    var inputXML = "";

    inputXML = "<Template tempid='" + templateId + "'>";

    for (var i = 0; i < selectedReports.length; i++) {
      if (selectedReports[i] == 1) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='AsOfDt'   Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].AsOfDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mPriceDt'  Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].PriceDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mPriceFlag' Value='" +
          paramstaticobj[0][selectedReports[i]].PriceFlag +
          "' />";

        inputXML +=
          "<Params   Name='ShowExcldAst' Value='" +
          paramstaticobj[0][selectedReports[i]].ShowExcludeAsset +
          "' />";

        inputXML +=
          "<Params   Name='GrpByMnrAst' Value='" +
          paramstaticobj[0][selectedReports[i]].GrpByMnrAst +
          "' />";

        inputXML +=
          "<Params   Name='RunInAggregate' Value='" +
          paramstaticobj[0][selectedReports[i]].RunInAggregate +
          "' />";

        inputXML += "</Report>";

        debugger;
      }

      var localStorageRunInAggregate = [];

      if (selectedReports[i] == 2) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='AsOfDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].AsOfDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mPriceDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].PriceDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mPriceFlag' Value='" +
          paramstaticobj[0][selectedReports[i]].PriceFlag +
          "' />";

        inputXML +=
          "<Params   Name='DsplyPrcnt' Value='" +
          paramstaticobj[0][selectedReports[i]].DisplyPrcnt +
          "' />";

        inputXML +=
          "<Params   Name='ShowExcldAst' Value='" +
          paramstaticobj[0][selectedReports[i]].ShowExcludeAsset +
          "' />";

        inputXML +=
          "<Params   Name='ShowAllPositionsInd' Value='" +
          paramstaticobj[0][selectedReports[i]].ShowAllPositionsInd +
          "' />";

        inputXML +=
          "<Params   Name='ShwPrpsdTrds' Value='" +
          paramstaticobj[0][selectedReports[i]].ShwPrpsdTrds +
          "' />";

        inputXML +=
          "<Params   Name='RunInAggregate' Value='" +
          paramstaticobj[0][selectedReports[i]].RunInAggregate +
          "' />";

        inputXML += "</Report>";
      }

      if (selectedReports[i] == 3) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='AsOfDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].AsOfDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='EffectiveDtInd' Value='" +
          paramstaticobj[0][selectedReports[i]].EffectiveDtInd +
          "' />";

        inputXML +=
          "<Params   Name='mPriceDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].PriceDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='RltnshpCd' Value='" +
          paramstaticobj[0][selectedReports[i]].RltnshpCd +
          "' />";

        inputXML +=
          "<Params   Name='mPriceFlag' Value='" +
          paramstaticobj[0][selectedReports[i]].PriceFlag +
          "' />";

        inputXML +=
          "<Params   Name='ShwCPIAdjShrs' Value='" +
          paramstaticobj[0][selectedReports[i]].ShwCPIAdjShrs +
          "' />";

        inputXML += "</Report>";
      }

      if (selectedReports[i] == 4) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='OverdraftRange' Value='" +
          paramstaticobj[0][selectedReports[i]].OverdraftRange +
          "' />";

        inputXML += "</Report>";
      }

      if (selectedReports[i] == 5) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='mStartDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].StartDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mEndDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].EndDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='NetOfFeesInd' Value='" +
          paramstaticobj[0][selectedReports[i]].NetOfFeesInd +
          "' />";

        inputXML +=
          "<Params   Name='AstType' Value='" +
          paramstaticobj[0][selectedReports[i]].AstType.cdId +
          "' />";

        inputXML += "</Report>";
      }

      if (selectedReports[i] == 6) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='MrkCap' Value='" +
          paramstaticobj[0][selectedReports[i]].MrkCap.CdId +
          "' />";

        inputXML +=
          "<Params   Name='RunInAggregate' Value='" +
          paramstaticobj[0][selectedReports[i]].RunInAggregate +
          "' />";

        inputXML += "</Report>";
      }

      if (selectedReports[i] == 7) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='AsOfDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].AsOfDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mPriceDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].PriceDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mPriceFlag' Value='" +
          paramstaticobj[0][selectedReports[i]].PriceFlag +
          "' />";

        inputXML +=
          "<Params   Name='ShowExcldAst' Value='" +
          paramstaticobj[0][selectedReports[i]].ShowExcludeAsset +
          "' />";

        inputXML +=
          "<Params   Name='RunInAggregate' Value='" +
          paramstaticobj[0][selectedReports[i]].RunInAggregate +
          "' />";

        inputXML += "</Report>";
      }

      if (selectedReports[i] == 8) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='mStartDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].StartDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mEndDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].EndDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='EffectiveDtInd' Value='" +
          paramstaticobj[0][selectedReports[i]].EffectiveDtInd +
          "' />";

        inputXML +=
          "<Params   Name='ExcludeSupp' Value='" +
          paramstaticobj[0][selectedReports[i]].ExcludeSupp +
          "' />";

        inputXML +=
          "<Params   Name='Swpind' Value='" +
          paramstaticobj[0][selectedReports[i]].Swpind +
          "' />";

        inputXML +=
          "<Params   Name='RltnshpCd' Value='" +
          paramstaticobj[0][selectedReports[i]].RltnshpCd +
          "' />";

        inputXML +=
          "<Params   Name='PageNo' Value='" +
          paramstaticobj[0][selectedReports[i]].PageNo +
          "' />";

        inputXML += "</Report>";
      }

      if (selectedReports[i] == 9) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='BenchMarkId' Value='" +
          paramstaticobj[0][selectedReports[i]].BenchMarkId.modelId +
          "' />";

        inputXML +=
          "<Params   Name='AsOfDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].AsOfDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mPriceDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].PriceDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mPriceFlag' Value='" +
          paramstaticobj[0][selectedReports[i]].PriceFlag +
          "' />";

        inputXML +=
          "<Params   Name='ShowExcldAst' Value='" +
          paramstaticobj[0][selectedReports[i]].ShowExcludeAsset +
          "' />";

        inputXML +=
          "<Params   Name='AggregateMode' Value='" +
          paramstaticobj[0][selectedReports[i]].AggregateMode +
          "' />";

        inputXML += "</Report>";
      }

      if (selectedReports[i] == 10) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='mPriceDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].mPriceDt,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='ePriceDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].ePriceDt,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mPriceFlag' Value='" +
          paramstaticobj[0][selectedReports[i]].mPriceFlag +
          "' />";

        inputXML +=
          "<Params   Name='ePriceFlag' Value='" +
          paramstaticobj[0][selectedReports[i]].ePriceFlag +
          "' />";

        inputXML +=
          "<Params   Name='ShowExcldAst' Value='" +
          paramstaticobj[0][selectedReports[i]].ShowExcludeAsset +
          "' />";

        inputXML +=
          "<Params   Name='AsOfStartDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].AsOfStartDt,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='AsOfEndDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].AsOfEndDt,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='RunInAggregate' Value='" +
          paramstaticobj[0][selectedReports[i]].RunInAggregate +
          "' />";

        inputXML += "</Report>";
      }

      if (selectedReports[i] == 11) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='ModelId' Value='" +
          (paramstaticobj[0][selectedReports[i]].Model.modelId === -1
            ? portfolioBreakdownModel[0].modelId
            : paramstaticobj[0][selectedReports[i]].Model.modelId) +
          "' />";

        inputXML +=
          "<Params   Name='BenchMarkId' Value='" +
          (paramstaticobj[0][selectedReports[i]].BenchMark.modelId === -1
            ? portfolioBreakdownBenchmark[0].modelId
            : paramstaticobj[0][selectedReports[i]].BenchMark.modelId) +
          "' />";

        inputXML +=
          "<Params   Name='AsOfDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].AsOfDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mPriceDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].PriceDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mPriceFlag' Value='" +
          paramstaticobj[0][selectedReports[i]].PriceFlag +
          "' />";

        inputXML +=
          "<Params   Name='ShowExcldAst' Value='" +
          paramstaticobj[0][selectedReports[i]].ShowExcludeAsset +
          "' />";

        inputXML +=
          "<Params   Name='RunInAggregate' Value='" +
          paramstaticobj[0][selectedReports[i]].RunInAggregate +
          "' />";

        inputXML += "</Report>";
      }

      if (selectedReports[i] == 12) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='ModelId' Value='" +
          paramstaticobj[0][selectedReports[i]].ModelId +
          "' />";

        inputXML +=
          "<Params   Name='InvMix' Value='" +
          paramstaticobj[0][selectedReports[i]].InvMix +
          "' />";

        inputXML +=
          "<Params   Name='AsOfDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].AsOfDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mPriceDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].PriceDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mPriceFlag' Value='" +
          paramstaticobj[0][selectedReports[i]].PriceFlag +
          "' />";

        inputXML +=
          "<Params   Name='ShowExcldAst' Value='" +
          paramstaticobj[0][selectedReports[i]].ShowExcludeAsset +
          "' />";

        inputXML +=
          "<Params   Name='RunInAggregate' Value='" +
          paramstaticobj[0][selectedReports[i]].RunInAggregate +
          "' />";

        inputXML += "</Report>";
      }

      if (selectedReports[i] == 13) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='mStartDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].StartDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mEndDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].EndDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='IncludeOrders' Value='" +
          paramstaticobj[0][selectedReports[i]].IncludeOrders +
          "' />";

        inputXML +=
          "<Params   Name='ArchvInd' Value='" +
          paramstaticobj[0][selectedReports[i]].ArchvInd +
          "' />";

        inputXML +=
          "<Params   Name='MutualFundInd' Value='" +
          paramstaticobj[0][selectedReports[i]].MutualFundInd +
          "' />";

        inputXML +=
          "<Params   Name='RunInAggregate' Value='" +
          paramstaticobj[0][selectedReports[i]].RunInAggregate +
          "' />";

        inputXML += "</Report>";
      }

      if (selectedReports[i] == 14) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='GrpByMnrAst' Value='" +
          paramstaticobj[0][selectedReports[i]].GrpByMnrAst.value +
          "' />";

        inputXML +=
          "<Params   Name='currDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].currDt,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='snapDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].snapDt,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='PortfolioPriceDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].PortfolioPriceDt,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='PortfolioPriceFlag' Value='" +
          paramstaticobj[0][selectedReports[i]].PortfolioPriceFlag +
          "' />";

        inputXML +=
          "<Params   Name='SnapPriceDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].SnapPriceDt,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='SnapPriceFlag' Value='" +
          paramstaticobj[0][selectedReports[i]].SnapPriceFlag +
          "' />";

        inputXML +=
          "<Params   Name='ShowExcldAst' Value='" +
          paramstaticobj[0][selectedReports[i]].ShowExcludeAsset +
          "' />";

        inputXML +=
          "<Params   Name='RunInAggregate' Value='" +
          paramstaticobj[0][selectedReports[i]].RunInAggregate +
          "' />";

        inputXML += "</Report>";
      }

      if (selectedReports[i] == 15) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='mStartDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].StartDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mEndDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].EndDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML += "</Report>";
      }

      if (selectedReports[i] == 16) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='NetOfFeesInd' Value='" +
          paramstaticobj[0][selectedReports[i]].NetOfFeesInd +
          "' />";

        inputXML +=
          "<Params   Name='PageId' Value='" +
          paramstaticobj[0][selectedReports[i]].PageId +
          "' />";

        inputXML +=
          "<Params   Name='mStartDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].StartDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mEndDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].EndDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='RltnshpCd' Value='" +
          paramstaticobj[0][selectedReports[i]].RltnshpCd +
          "' />";

        inputXML +=
          "<Params   Name='RunInAggregate' Value='" +
          paramstaticobj[0][selectedReports[i]].RunInAggregate +
          "' />";

        inputXML += "</Report>";
      }

      if (selectedReports[i] == 17) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='AsOfDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].AsOfDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mPriceDt' Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].PriceDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mPriceFlag' Value='" +
          paramstaticobj[0][selectedReports[i]].PriceFlag +
          "' />";

        inputXML +=
          "<Params   Name='BandYearFlag' Value='" +
          paramstaticobj[0][selectedReports[i]].BandYearFlag +
          "' />";

        inputXML +=
          "<Params   Name='ShowExcldAst' Value='" +
          paramstaticobj[0][selectedReports[i]].ShowExcludeAsset +
          "' />";

        inputXML +=
          "<Params   Name='ShwPrpsdTrds' Value='" +
          paramstaticobj[0][selectedReports[i]].ShwPrpsdTrds +
          "' />";

        inputXML +=
          "<Params   Name='ShwBondByIndSct' Value='" +
          paramstaticobj[0][selectedReports[i]].ShwBondByIndSct +
          "' />";

        inputXML +=
          "<Params   Name='ShwAssetType' Value='" +
          paramstaticobj[0][selectedReports[i]].ShwAssetType.typeId +
          "' />";

        inputXML +=
          "<Params   Name='RunInAggregate' Value='" +
          paramstaticobj[0][selectedReports[i]].RunInAggregate +
          "' />";

        inputXML += "</Report>";
      }

      if (selectedReports[i] == 18) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='mStartDt'   Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].StartDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mEndDt'   Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].EndDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='AsOfDt'   Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].AsOfDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mPriceDt'   Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].PriceDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mPriceFlag'   Value='" +
          paramstaticobj[0][selectedReports[i]].PriceFlag +
          "' />";

        inputXML +=
          "<Params   Name='ShowExcldAst'   Value='" +
          paramstaticobj[0][selectedReports[i]].ShowExcludeAsset +
          "' />";

        inputXML +=
          "<Params   Name='RunInAggregate'   Value='" +
          paramstaticobj[0][selectedReports[i]].RunInAggregate +
          "' />";

        inputXML += "</Report>";
      }

      if (selectedReports[i] == 19) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='AsOfDt'   Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].AsOfDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mStartDt'   Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].StartDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mEndDt'   Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].EndDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='RunInAggregate'   Value='" +
          paramstaticobj[0][selectedReports[i]].RunInAggregate +
          "' />";

        inputXML += "</Report>";
      }

      if (selectedReports[i] == 20) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='AsOfDt'   Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].AsOfDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='HistYearCount'   Value='" +
          paramstaticobj[0][selectedReports[i]].HistYearCount +
          "' />";

        inputXML +=
          "<Params   Name='NetFeeInd'   Value='" +
          paramstaticobj[0][selectedReports[i]].NetFeeInd +
          "' />";

        inputXML +=
          "<Params   Name='OvrdIncptn'   Value='" +
          paramstaticobj[0][selectedReports[i]].OvrdIncptn +
          "' />";

        inputXML +=
          "<Params   Name='RunInAggregate'   Value='" +
          paramstaticobj[0][selectedReports[i]].RunInAggregate +
          "' />";

        inputXML += "</Report>";
      }

      if (selectedReports[i] == 21) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='AsOfDt'   Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].AsOfDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='NetFeeInd'   Value='" +
          paramstaticobj[0][selectedReports[i]].NetFeeInd +
          "' />";

        inputXML +=
          "<Params   Name='OvrdIncptn'   Value='" +
          paramstaticobj[0][selectedReports[i]].OvrdIncptn +
          "' />";

        inputXML +=
          "<Params   Name='RunInAggregate'   Value='" +
          paramstaticobj[0][selectedReports[i]].RunInAggregate +
          "' />";

        inputXML += "</Report>";
      }

      if (selectedReports[i] == 22) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='mStartDt'   Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].StartDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='AsOfDt'   Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].EndDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='showAccountPortfolio'   Value='" +
          paramstaticobj[0][selectedReports[i]].showAccountPortfolio +
          "' />";

        inputXML +=
          "<Params   Name='showL1SegmentBenchmark'   Value='" +
          paramstaticobj[0][selectedReports[i]].showL1SegmentBenchmark +
          "' />";

        inputXML +=
          "<Params   Name='showCheckedMetrices'   Value='" +
          paramstaticobj[0][selectedReports[i]].showCheckedMetrices +
          "' />";

        inputXML +=
          "<Params   Name='showSL1Category'   Value='" +
          paramstaticobj[0][selectedReports[i]].showSL1Category +
          "' />";

        inputXML +=
          "<Params   Name='RiskFreeBenchmarkId'   Value='" +
          paramstaticobj[0][selectedReports[i]].RiskFreeBenchmarkId +
          "' />";

        inputXML += "</Report>";
      }

      if (selectedReports[i] == 23) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='mStartDt'   Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].StartDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='mEndDt'   Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].EndDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='AggregateMode'   Value='" +
          paramstaticobj[0][selectedReports[i]].AggregateMode +
          "' />";

        inputXML +=
          "<Params   Name='NetOfFeesInd'   Value='" +
          paramstaticobj[0][selectedReports[i]].NetOfFeesInd +
          "' />";

        inputXML +=
          "<Params   Name='DsplyBnchmrks'   Value='" +
          paramstaticobj[0][selectedReports[i]].DsplyBnchmrks +
          "' />";

        inputXML +=
          "<Params   Name='BlndBnchmrk'   Value='" +
          paramstaticobj[0][selectedReports[i]].BlndBnchmrk +
          "' />";

        inputXML += "</Report>";
      }

      if (selectedReports[i] == 24) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='AsOfDt'   Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].AsOfDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML +=
          "<Params   Name='BenchmarkList'   Value='" +
          // paramstaticobj[0][selectedReports[i]].BenchmarkList +
          templateParams.benchmarkList +
          "' />";

        inputXML +=
          "<Params   Name='RelatedBnchmrkInd'   Value='" +
          paramstaticobj[0][selectedReports[i]].RelatedBnchmrkInd +
          "' />";

        inputXML +=
          "<Params   Name='RunInAggregate'   Value='" +
          paramstaticobj[0][selectedReports[i]].RunInAggregate +
          "' />";

        inputXML += "</Report>";
      }

      if (selectedReports[i] == 25) {
        inputXML += "<Report rptid='" + selectedReports[i] + "'>";

        inputXML +=
          "<Params   Name='AsOfDt'   Value='" +
          formatDate(
            paramstaticobj[0][selectedReports[i]].AsOfDate,

            "MM/dd/yyyy"
          ) +
          "' />";

        inputXML += "</Report>";
      }
    }

    inputXML += "</Template>";
    var localStorageRunInAggregate = [];

    paramstaticobj.forEach((reportObj) => {
      Object.values(reportObj).forEach((report) => {
        const { ReportId, RunInAggregate, AggregateMode } = report;
        // Only store the ReportId and RunInAggregate, ignore undefined values
        localStorageRunInAggregate.push({
          ReportId,
          RunInAggregate,
          AggregateMode,
        });
      });
    });

    localStorage.setItem(
      "RunInAggregate",
      JSON.stringify(localStorageRunInAggregate)
    );

    const postData = {
      templateId,

      userId,

      acctId,

      consolidationId,

      inputXML,
    };

    await axios
      .post("/Template", postData, config)
      .then((response) => {
        const rowData = response.data;
        console.log("rowData", rowData);
        localStorage.setItem("rowData", JSON.stringify(rowData));
        setLoading(false);
        onClose();

        // navigate("/cstmRptMain", { state: { rowData } });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        console.log("my error is " + error);
      });
  };

  // const formSubmit = (event) => {
  //
  //   event.preventDefault();
  //   setLoading(true);
  //   console.log("templateParams", templateParams);
  //   submitTemplate();

  //   if (disableFlag) {
  //     UpdateTmplt();
  //   }

  //   localStorage.setItem("Editable", "1");
  // };
  const opencustomrepoprt = () => {
    // console.log("opencustomreport")
    // OpenCustomReport(true);
  };
  const formSubmit = async (event) => {
    localStorage.setItem("tmpltNm", templateName);
    event.preventDefault();
    setLoading(true);
    console.log("templateParams", templateParams);

    // Execute UpdateTmplt() and wait for it to complete
    if (disableFlag) {
      // await UpdateTmplt(); // Wait for UpdateTmplt to complete
    }

    // Then execute submitTemplate after UpdateTmplt has finished
    await submitTemplate(); // Wait for submitTemplate to complete

    // After both actions are complete, store in localStorage
    localStorage.setItem("Editable", "1");

    onCstmRptModal();
    console.log("onCstmRptModal", onCstmRptModal);
    console.log("onClose", onClose);
    // onClose();
  };

  const handleSelect = (selectedid) => {
    debugger;

    if (selectedReports.length < 5 || selectedReports.includes(selectedid)) {
      setSelectedReports((prev) =>
        prev.includes(selectedid)
          ? prev.filter((item) => item !== selectedid)
          : [...prev, selectedid]
      );
    }
    var tempOptions = TabOptions;
    console.log(TabOptions);
    for (var i = 0; i < reportOptions.length; i++) {
      const result = tempOptions.findIndex(({ id }) => id === selectedid);

      if (result !== -1) {
        if (result > -1) {
          tempOptions.splice(result, 1);
          setreportdisable(false);
          break;
        }
      } else if (reportOptions[i].id === selectedid) {
        debugger;
        if (tempOptions.length > 4) {
          setreportdisable(true);
        }
        if (tempOptions.length < 6) {
          tempOptions.push(reportOptions[i]);
          break;
        }
      }
    }

    setTabOptions(orderBy(tempOptions, initialSort));

    // var tempOptions={
    //       id: 0,
    //       title: "General",
    //       description: " ",
    //     }
  };

  // const handleNext = () => {
  //   if (activeStep < steps.length - 1) {
  //     setActiveStep((prevStep) => prevStep + 1);
  //   } else {
  //     console.log("Form submitted with:", { selectedReports });
  //     onClose();
  //   }
  // };

  // const handleNext = () => {
  //   if (activeStep < steps.length - 1) {
  //
  //     const paramsForSelectedReports = selectedReports.map((reportId) => reportsParams[reportId] || []);
  //     const flattenedParams = paramsForSelectedReports.flat();

  //     setSelectedReportParams(flattenedParams);

  //     setActiveStep((prevStep) => prevStep + 1);

  //     console.log("paramsForSelectedReports", paramsForSelectedReports)
  //     console.log("flattenedParams", flattenedParams)
  //   } else {
  //     console.log("Form submitted with:", { selectedReports, selectedReportParams });
  //     onClose();
  //   }

  // };

  const handleAsOfDate = (newDate) => {
    // setasOfDt(formatDate(newDate, "MM/dd/yyyy"));
    for (let i = 1; i <= 25; i++) {
      debugger;
      if (paramstaticobj[0]?.[i]?.AsOfDate !== undefined) {
        paramstaticobj[0][i].AsOfDate = newDate;
      }
    }
    setasOfDt(newDate);
    setTemplateParams((prevParams) => ({
      ...prevParams,
      asOfDt: formatDate(newDate, "MM/dd/yyyy"),
    }));
  };

  const handleFromDate = (newDate) => {
    // setfrmDate(formatDate(newDate, "MM/dd/yyyy"));
    for (let i = 1; i <= 25; i++) {
      debugger;
      if (paramstaticobj[0]?.[i]?.StartDate !== undefined) {
        paramstaticobj[0][i].StartDate = newDate;
      }
    }
    setfrmDate(newDate);
    setTemplateParams((prevParams) => ({
      ...prevParams,
      fromDate: formatDate(newDate, "MM/dd/yyyy"),
    }));
  };

  const handleToDate = (newDate) => {
    // setToDate(formatDate(newDate, "MM/dd/yyyy"));
    for (let i = 1; i <= 25; i++) {
      debugger;
      if (paramstaticobj[0]?.[i]?.EndDate !== undefined) {
        paramstaticobj[0][i].EndDate = newDate;
      }
    }
    setToDate(newDate);
    setTemplateParams((prevParams) => ({
      ...prevParams,
      toDate: formatDate(newDate, "MM/dd/yyyy"),
    }));
  };

  const handlePriceDate = (newDate) => {
    // setpriceDt(formatDate(newDate, "MM/dd/yyyy"));
    for (let i = 1; i <= 25; i++) {
      debugger;
      if (paramstaticobj[0]?.[i]?.PriceDate !== undefined) {
        paramstaticobj[0][i].PriceDate = newDate;
      }
    }
    setpriceDt(newDate);
    setTemplateParams((prevParams) => ({
      ...prevParams,
      priceDt: newDate,
    }));
  };

  const saveNewTemplate = async () => {
    let idXml = "<Root>";
    idXml =
      idXml +
      selectedReports.map(function (val) {
        return val !== ","
          ? "<Records><ReportId>" + val + "</ReportId></Records>"
          : "";
      });
    idXml = idXml + "</Root>";
    let RptIds = idXml;

    let TemplateNm = templateName;
    let TemplateDesc = templateDescription;
    localStorage.setItem("tmpltNm", templateName);
    let UserId = JSON.parse(localStorage.getItem("userId"));
    //let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    let IsPrivate = !isPublic;
    const data = { UserId, TemplateNm, TemplateDesc, RptIds, IsPrivate };

    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    await axios
      .post("TemplateProfile/TemplateProfile", data, config)
      .then((response) => {
        console.log(response);

        if (response.statusText === "OK" || response.statusText === "") {
          localStorage.setItem("tmpltNm", templateName);
          // setRptIds([]);
          // SetTmpltDetails(response.data.ocTemplateProfile);
          //
          if (response.data.saveTemplateId !== 0) {
            localStorage.setItem(
              "tmpltIdFrmDshBrd",
              JSON.stringify(response.data.saveTemplateId)
            );
            // navigate("/cstmRptMain");
          }
          setTemplateID(response.data.saveTemplateId);
          JSON.parse(
            localStorage.setItem(
              "tmpltIdFrmDshBrd",
              response.data.saveTemplateId
            )
          );
          setTemplateParams((prevParams) => ({
            ...prevParams,
            templateId: response.data.saveTemplateId,
          }));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        console.log("my error is " + error);
      });

    // handleClose();
  };

  const UpdateTmplt = async () => {
    let token = tempToken;
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    let TemplateId = tmpId;
    let TemplateNm = templateName;
    let TemplateDesc = templateDescription;
    let InsrtUpdtInd = 1;
    let UserId = JSON.parse(localStorage.getItem("userId"));
    const postData = {
      TemplateId,
      TemplateNm,
      TemplateDesc,
      UserId,
      InsrtUpdtInd,
    };
    setLoading(true);
    await axios
      .post("TemplateProfile/UpdateTemplate", postData, config)
      .then((response) => {
        // setLoading(false);
        const rowData = response.data;
        console.log("rowData", rowData);
        const templateData = rowData.ocTmpltDetailsT;
        // tmplRecords = rowData.ocTmpltDetailsT;
        const lstRpts = rowData.ocQualRptLstT;
        // SetTmpltDetails(rowData.ocTmpltDetailsT);
        // setRptList(Enumerable.from(rowData.ocQualRptLstT).toArray());
        // setResult(rowData.ocTmpltDetailsT, initialDataState);
        // setRptList(rowData.oCQualRptLstT);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          // refreshTokenForUpdt();
        }
        console.log("my error is " + error);
      });
  };

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      const paramsForSelectedReports = selectedReports.map(
        (reportId) => reportsParams[reportId] || []
      );
      const flattenedParams = paramsForSelectedReports.flat();

      // Create a new object with updated defaults
      const newUpdatedParams = { ...paramDefaults };
      Object.keys(newUpdatedParams).forEach((key) => {
        newUpdatedParams[key] = flattenedParams.includes(key);
      });

      // Update state
      setSelectedReportParams(flattenedParams);
      setUpdatedParams(newUpdatedParams); // Update the state for use elsewhere

      // Move to the next step
      setActiveStep((prevStep) => prevStep + 1);

      // Debugging logs
      console.log("paramsForSelectedReports", paramsForSelectedReports);
      console.log("flattenedParams", flattenedParams);
      console.log("newUpdatedParams", newUpdatedParams);
      if (!disableFlag) {
        // saveNewTemplate();
      }
    } else {
      console.log("Submit Button Press");
      console.log("Form submitted with:", {
        selectedReports,
        selectedReportParams,
      });

      // formSubmit();
      onClose();
    }
  };

  console.log("updatedParams", updatedParams);

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const handlecancel = () => {
    onClose();
    menuStateData(false);
  };

  const GetAssetType = async () => {
    let userId = JSON.parse(localStorage.getItem("userId"));
    const postData = { userId };
    let token = tempToken;
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    await axios
      .post("/GetAssetType", postData, config)
      .then((response) => {
        console.log("response", response);
        setAssetTypLst(response.data?.ocAssetType);
        setAssetType(response.data?.ocAssetType[0]);
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response.status === 401) {
          refreshToken();
        }
      });
  };

  const initialtaboptions = () => {
    debugger;
    console.log(reportList);

    var tempOptions = TabOptions;
    for (var i = 0; i < selectedReportList.length; i++) {
      debugger;
      const result = reportOptions.findIndex(
        ({ id }) => id === selectedReportList[i]
      );
      debugger;
      tempOptions.push(reportOptions[result]);
    }
    setTabOptions(tempOptions);
  };

  useMemo(() => {
    debugger;
    initialtaboptions();
    try {
      GetBenchmarkList();
      GetAssetType();
      GetAllModelBnchmrk();
    } catch (error) {
      console.error(error.message);
    }
  }, [update]);

  const assetTypeChange = (e) => {
    debugger;

    let tempparamstaticobj = [...paramstaticobj];
    // tempparamstaticobj[0][5].AstType = e.target.value;

    if (e.target.value === null) {
      tempparamstaticobj[0][5].AstType = {
        cdId: "na",
        cdVlDesc: "None",
      };
    } else {
      tempparamstaticobj[0][5].AstType = e.target.value;
    }

    setparamstaticobj(tempparamstaticobj);
  };

  const handleExcluddedAsset = (e, id) => {
    debugger;
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].ShowExcludeAsset = e.currentTarget.checked;
    setparamstaticobj(tempparamstaticobj);
  };

  const handleEffectiveDtIndChange = (e, id) => {
    debugger;
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].EffectiveDtInd = e.currentTarget.checked;
    setparamstaticobj(tempparamstaticobj);
  };

  const handleExcludeSuppChange = (e, id) => {
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].ExcludeSupp = e.currentTarget.checked;
    setparamstaticobj(tempparamstaticobj);
  };

  const handleExcludeSweepInd = (e, id) => {
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].Swpind = e.currentTarget.checked;
    setparamstaticobj(tempparamstaticobj);
  };

  const handleTipsIndChange = (e, id) => {
    debugger;
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].ShwCPIAdjShrs = e.currentTarget.checked;
    setparamstaticobj(tempparamstaticobj);
  };

  const handleRadioChangeAcPr = (e, id) => {
    debugger;
    setSelectedOptionAcPr(parseInt(e.target.value));
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].PriceFlag = parseInt(e.target.value);

    // setPriceFlagIndAcPr(parseInt(e.target.value));
    // console.log("Selected Option:", e.target.value);
    if (e.target.value == 1 || e.target.value == 2) {
      tempparamstaticobj[0][id].PriceDate = date;
    }
    setparamstaticobj(tempparamstaticobj);
  };

  const handleOverdraftCheckBox = (e) => {
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][4].OverdraftRangeCheckBox = e.currentTarget.checked;
    tempparamstaticobj[0][4].OverdraftRange = e.currentTarget.checked
      ? tempparamstaticobj[0][4].OverdraftRange
      : 0;
    if (e.target.checked === false) {
      localStorage.setItem("overdraftvalueCstmRpt", "");
    }
    setparamstaticobj(tempparamstaticobj);
  };

  const handleOverdraftValue = (e) => {
    seterrorvisible(true);
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][4].OverdraftRange = parseFloat(e.target.value);
    setparamstaticobj(tempparamstaticobj);
  };

  const handleShowProcessTradesChange = (e, id) => {
    debugger;
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].ShwPrpsdTrds = e.target.checked ? true : false;
    setparamstaticobj(tempparamstaticobj);
  };

  const handlechnageAsOfDate = (e, id) => {
    debugger;
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].AsOfDate = e.value;

    setparamstaticobj(tempparamstaticobj);

    // setparamstaticobj({
    //   ...paramstaticobj,
    //   AsOfDate:e.value
    // })
  };

  const handlechnageAsOfDateStartDate = (e, id) => {
    debugger;
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].AsOfStartDt = e.value;

    setparamstaticobj(tempparamstaticobj);

    // setparamstaticobj({
    //   ...paramstaticobj,
    //   AsOfDate:e.value
    // })
  };

  const fromPriceDtHandleRadioChange = (e, id) => {
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].mPriceFlag = parseInt(e.target.value);

    if (e.target.value == 1 || e.target.value == 2) {
      // to be checked
      tempparamstaticobj[0][id].mPriceDt = date;
    }
    setparamstaticobj(tempparamstaticobj);
    // console.log("fromPriceDtSelOpt", fromPriceDtSelOpt);
    // console.log("Selected Price Date", fromPriceDt);
  };

  const toPriceDtHandleRadioChange = (e, id) => {
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].ePriceFlag = parseInt(e.target.value);

    if (e.target.value == 1 || e.target.value == 2) {
      // to be checked
      tempparamstaticobj[0][id].ePriceDt = date;
    }
    setparamstaticobj(tempparamstaticobj);
  };

  const handleCurrentPortPriceFlag = (e, id) => {
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].PortfolioPriceFlag = parseInt(e.target.value);

    if (e.target.value == 1 || e.target.value == 2) {
      tempparamstaticobj[0][id].PortfolioPriceDt = date;
    }
    setparamstaticobj(tempparamstaticobj);
  };

  const handleSnapPriceFlag = (e, id) => {
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].SnapPriceFlag = parseInt(e.target.value);

    if (e.target.value == 1 || e.target.value == 2) {
      tempparamstaticobj[0][id].SnapPriceDt = date;
    }
    setparamstaticobj(tempparamstaticobj);
  };

  const handleCurrentPriceDate = (e, id) => {
    debugger;
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].PortfolioPriceDt = e.value;

    setparamstaticobj(tempparamstaticobj);
  };

  const handleSnapPriceDate = (e, id) => {
    debugger;
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].SnapPriceDt = e.value;

    setparamstaticobj(tempparamstaticobj);
  };

  const handlechnageAsOfDateEndDate = (e, id) => {
    debugger;
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].AsOfEndDt = e.value;

    setparamstaticobj(tempparamstaticobj);

    // setparamstaticobj({
    //   ...paramstaticobj,
    //   AsOfDate:e.value
    // })
  };

  const handlechnagePriceDate = (e, id) => {
    debugger;
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].PriceDate = e.value;

    setparamstaticobj(tempparamstaticobj);

    // setparamstaticobj({
    //   ...paramstaticobj,
    //   AsOfDate:e.value
    // })
  };

  const handleChangeCurrDt = (e, id) => {
    debugger;
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].currDt = e.value;

    setparamstaticobj(tempparamstaticobj);
  };

  const handleChangeSnapDt = (e, id) => {
    debugger;
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].snapDt = e.value;

    setparamstaticobj(tempparamstaticobj);
  };

  const handleChangeStartDate = (e, id) => {
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].StartDate = e.value;

    setparamstaticobj(tempparamstaticobj);
  };

  const handleChangeEndDate = (e, id) => {
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].EndDate = e.value;

    setparamstaticobj(tempparamstaticobj);
  };

  const handleGrpByMnrAst = (e, id) => {
    debugger;
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].GrpByMnrAst = e.target.value;

    setparamstaticobj(tempparamstaticobj);
  };

  const handleDisplayPercentChange = (e, id) => {
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][2].DisplyPrcnt = parseInt(e.target.value);

    setparamstaticobj(tempparamstaticobj);
    // setDisplayPercentIndPortH(parseInt(e.target.value));
  };

  const handleChangeNetOfFeeInd = (e, id) => {
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].NetOfFeesInd =
      tempparamstaticobj[0][id].NetOfFeesInd === 1 ? 0 : 1;

    setparamstaticobj(tempparamstaticobj);
  };

  const modelChange = (event, id) => {
    debugger;
    let tempparamstaticobj = [...paramstaticobj];

    if (event.target.value === null) {
      debugger;
      tempparamstaticobj[0][id].BenchMarkId = {
        modelDesc: "None",
        modelId: -1,
      };
    } else {
      debugger;
      tempparamstaticobj[0][id].BenchMarkId = event.target.value;
      setValidationError("");
    }
    setparamstaticobj(tempparamstaticobj);
  };

  const handleChangeMutualFund = (e, id) => {
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].MutualFundInd = e.currentTarget.checkedb ? 1 : 0;
    setparamstaticobj(tempparamstaticobj);
  };

  const handleshwPrpsdTrd = (e, id) => {
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].ShwPrpsdTrds = e.currentTarget.checked;
    setparamstaticobj(tempparamstaticobj);
    // setshwPrpsdTrds(e.target.checked ? true : false);
  };

  const handleshwBondByIndSctr = (e) => {
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][17].ShwBondByIndSct = e.target.checked;
    setparamstaticobj(tempparamstaticobj);
    // setshwBondByIndSctr(e.target.checked ? true : false);
  };

  const handleChangeShowPortfolio = (e) => {
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][22].showAccountPortfolio = e.target.checked;
    setparamstaticobj(tempparamstaticobj);
    // setShwTtlPortfolio(event.target.checked); // Capture whether the checkbox is checked or unchecked
  };

  const handlebandYrFlag = (e, id) => {
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][17].BandYearFlag = parseInt(e.target.value);
    setparamstaticobj(tempparamstaticobj);
    // setbandYearFlag(parseInt(e.target.value));
    // setbandYearFlag(e.target.checked ? true : false);
  };

  const handleChangeIncludeOrders = (e) => {
    debugger;
    const { name, checked } = e.target;

    // Update the selectedOptions state
    setSelectedOptions((prev) => ({
      ...prev,
      [name]: checked,
    }));

    // Calculate the new value dynamically based on the checkbox states
    const newSelectedOptions = {
      ...selectedOptions,
      [name]: checked,
    };

    // setTradeOptionValue(
    //   (newSelectedOptions.stagedTrades ? 1 : 0) +
    //     (newSelectedOptions.pendingTrades ? 2 : 0)
    // );
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][13].IncludeOrders =
      (newSelectedOptions.stagedTrades ? 1 : 0) +
      (newSelectedOptions.pendingTrades ? 2 : 0);
    setparamstaticobj(tempparamstaticobj);
  };

  const handleChangeAssetType = (e) => {
    debugger;
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][17].ShwAssetType = e.value;
    setparamstaticobj(tempparamstaticobj);
    // settypeval(e.value);
  };

  const handleChangeGrpByMnrAsst = (e, id) => {
    let tempparamstaticobj = [...paramstaticobj];
    const selectedValue = e.target.value;
    const selectedObj = options.find(
      (option) => option.value === selectedValue
    );
    tempparamstaticobj[0][14].GrpByMnrAst = selectedObj;
    setparamstaticobj(tempparamstaticobj);
    // const selectedValue = event.target.value;
    // const selectedObj = options.find(
    //   (option) => option.value === selectedValue
    // );
    // setSelectedOption(selectedObj);
    // console.log("Selected Value:", selectedObj);
  };

  const handleCustomPeriod = (e) => {
    setshowCustomPeriod(e.target.checked);
    setisDisabledCstPeriod(showCustomPeriod);
    //setOverdraftVal(e.target.checked ? overdraftVal : "");
  };

  const handleNetFeeInd = (e, id) => {
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].NetFeeInd = e.currentTarget.checked;
    setparamstaticobj(tempparamstaticobj);
  };

  const handleNextBomInd = (e, id) => {
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].OvrdIncptn = e.currentTarget.checked;
    setparamstaticobj(tempparamstaticobj);
  };

  const handleNumberOfYearsChange = (e) => {
    //must check for submit disable
    const value = e.target.value;
    const parsedValue = parseInt(value, 10);

    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][20].HistYearCount = parsedValue;
    setparamstaticobj(tempparamstaticobj);

    if (isNaN(parsedValue) || parsedValue < 1 || parsedValue > 9) {
      // setIsSubmitDisabled(true);
      // setValidationMessage("Please enter a year between 1 to 9");
    } else {
      // setIsSubmitDisabled(false);
      // setValidationMessage("");
    }
  };

  const handleChangeAllocModel = (e) => {
    debugger;
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][12].ModelId = e.target.value;
    setparamstaticobj(tempparamstaticobj);
    setSelChangeModel(e.target.value);
    localStorage.setItem(
      "assetAllocModelId",
      JSON.stringify(e.target.value.modelId)
    );
  };

  const handleChangeInvmix = (e, id) => {
    debugger;
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].InvMix = e.currentTarget.checked ? 1 : 0;
    setparamstaticobj(tempparamstaticobj);
    setSelChangeModel(
      e.target.checked ? { modelId: -1, modelDesc: "None" } : selChangeModel
    );
    localStorage.setItem("assetAllocModelId", JSON.stringify(-1));
  };

  const handleModelChange = (event) => {
    let tempparamstaticobj = [...paramstaticobj];

    if (event.target.value === null) {
      tempparamstaticobj[0][11].Model = { modelDesc: "None", modelId: -1 };
      // setPortfolioBreakSelModel({ modelDesc: "None", modelId: -1 });
    } else {
      tempparamstaticobj[0][11].Model = event.target.value;
      // setPortfolioBreakSelModel(event.target.value);
      // localStorage.setItem("modelId", JSON.stringify(event.target.value));
      setValidationError("");
      // GetPortfolioBreakDownData();
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }

    setparamstaticobj(tempparamstaticobj);
  };

  const bnchmrkChange = (event) => {
    let tempparamstaticobj = [...paramstaticobj];
    if (event.target.value === null) {
      tempparamstaticobj[0][11].BenchMark = { modelDesc: "None", modelId: -1 };
    } else {
      tempparamstaticobj[0][11].BenchMark = event.target.value;

      // setValidationError("");
    }

    setparamstaticobj(tempparamstaticobj);

    // if (event.target.value === null) {
    //   setSelBnchmrk({ modelDesc: "None", modelId: -1 });
    // } else {
    //   setSelBnchmrk(event.target.value);
    //   localStorage.setItem("bnchmrkId", JSON.stringify(event.target.value));
    //   setValidationError("");
    // }
  };

  const handleBenchmarkDisplay = (e, id) => {
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].DsplyBnchmrks = e.currentTarget.checked;
    setparamstaticobj(tempparamstaticobj);
  };

  const handleChangeShowBenchmark = (e, id) => {
    let tempparamstaticobj = [...paramstaticobj];
    tempparamstaticobj[0][id].BlndBnchmrk = parseInt(e.target.value);
    setparamstaticobj(tempparamstaticobj);
    // setSelectedOption(event.target.value);
  };

  function isEndOfMonth(date) {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      console.log("cant read data");
      return false; // If the date is invalid, return false
    }
    // Get the last day of the current month
    const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0); // Last day of current month

    // Compare the day of the given date with the last day of the month
    return date.getDate() === nextMonth.getDate();
  }

  console.log("Final paramstaticobj", paramstaticobj);

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <Modal
          BackdropProps={{
            onClick: (e) => e.stopPropagation(), // This will stop the modal from closing when the backdrop is clicked
          }}
          open={open}
          onClose={onClose}
        >
          {activeStep != 0 ? (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                p: 4,
                borderRadius: 2,
                boxShadow: 24,
                width: "90%",
                maxHeight: "95vh",
                overflowY: "auto",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  color: themes === "dark" ? "white" : "black",
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                Report Package
              </Typography>

              <Divider />

              <Box sx={{ my: 3, color: "red" }}>
                <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                  sx={{
                    "& .MuiStepIcon-root": {
                      color: "gray", // Set the default icon color
                    },
                    "& .Mui-active .MuiStepIcon-root": {
                      color: "#0b94e3", // Active step icon color
                    },
                    "& .Mui-completed .MuiStepIcon-root": {
                      color: "green", // Completed step icon color
                    },
                    "& .MuiStepLabel-root": {
                      color: "gray", // Default label color
                    },
                    "& .Mui-active .MuiStepLabel-root": {
                      color: "#0b94e3", // Active step label color
                    },
                    "& .Mui-completed .MuiStepLabel-root": {
                      color: "blue", // Completed step label color
                    },
                    // Change the color of the step number
                    "& .MuiStepIcon-text": {
                      color: "blue", // Change number color to blue
                    },
                    "& .Mui-active .MuiStepIcon-text": {
                      color: "white", // Active step number color (white on blue background)
                    },
                    "& .Mui-completed .MuiStepIcon-text": {
                      color: "white", // Completed step number color (white on blue background)
                    },
                  }}
                >
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              {console.log("updatedParams", updatedParams)}

              {activeStep === 0 && newTemp ? (
                <Box>
                  <Box sx={{ display: "flex", gap: 2, mb: 1 }}>
                    <TextField
                      label="Template Name"
                      fullWidth
                      disabled={vieweditdisable}
                      // value={templateName}
                      value={templateName}
                      onChange={(e) => setTemplateName(e.target.value)}
                    />
                    <TextField
                      label="Template Description"
                      fullWidth
                      disabled={vieweditdisable}
                      // value={templateDescription}
                      value={templateDescription}
                      onChange={(e) => setTemplateDescription(e.target.value)}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 1,
                      p: 1,
                      border: "1px solid #ddd",
                      borderRadius: 1,
                      backgroundColor:
                        themes === "light" ? "#f9f9f9" : "#171717",
                    }}
                  >
                    <Typography
                      style={{ color: themes === "dark" ? "white" : "black" }}
                      variant="h6"
                    >
                      Reports selected:
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      View report selection.
                    </Typography>
                    <FormControlLabel
                      control={
                        <BlueSwitch
                          checked={isPublic}
                          disabled={vieweditdisable}
                          onChange={(event) =>
                            setIsPublic(event.target.checked)
                          }
                          //color="primary"
                        />
                      }
                      label={isPublic ? "Public" : "Private"}
                    />
                  </Box>

                  <Box
                    sx={{
                      height: 300,
                      overflowY: "auto",
                      border: "1px solid #ddd",
                      borderRadius: 1,
                      p: 1,
                      backgroundColor: themes === "dark" ? "#2b2b2b" : "white",
                    }}
                  >
                    <Grid container spacing={2}>
                      {reportOptions.map((report) => (
                        <Grid item xs={6} key={report.id}>
                          <Box
                            sx={{
                              cursor: "default",
                              display: "flex",
                              alignItems: "center",
                              p: 2,
                              border: selectedReports.includes(report.id)
                                ? "2px solid #1976d2"
                                : "1px solid #ddd",
                              borderRadius: 1,

                              backgroundColor: selectedReports.includes(
                                report.id
                              )
                                ? themes === "light"
                                  ? "#f0f8ff"
                                  : "#373c40"
                                : themes === "light"
                                ? "#fff"
                                : "#424242",
                            }}
                            onClick={
                              disableFlag
                                ? () => {}
                                : () => handleSelect(report.id)
                            }
                          >
                            <Checkbox
                              checked={selectedReports.includes(report.id)}
                              sx={{
                                mr: 2,
                                color: "#226087",
                                "&.Mui-checked": {
                                  color: "#226087",
                                },
                              }}
                              disabled={disableFlag}
                            />
                            <Box>
                              <Typography
                                style={{
                                  color: themes === "dark" ? "white" : "black",
                                }}
                                variant="subtitle1"
                                fontWeight={600}
                              >
                                {report.title}
                              </Typography>
                              {/* <Typography variant="body2" color="textSecondary">
                          {report.description}
                        </Typography> */}
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  <Box
                    mt={3}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Alert
                      severity="warning"
                      icon={<BsExclamationTriangleFill size={20} />}
                      sx={{ marginTop: "0.5rem" }}
                    >
                      You cannot add or remove reports in view mode.
                    </Alert>
                    <Box display="flex" gap={2}>
                      <Button
                        //className="btn btn-outline-primary me-3"
                        variant="outlined"
                        color="error"
                        onClick={activeStep === 0 ? handlecancel : handleBack}
                      >
                        {activeStep === 0 ? "Cancel" : "Back"}
                      </Button>
                      <button
                        type={activeStep === 0 ? "button" : "submit"}
                        className="btn btn-outline-primary me-3"
                        //style={{color:themes === "dark"?"primary":"#1395d6",outlineColor:themes === "dark"?"primary":"#1395d6"}}
                        //color="secondary"
                        variant="outlined"
                        onClick={activeStep === 0 ? handleNext : null}
                        disabled={
                          activeStep === 0 && selectedReports.length === 0
                        }
                      >
                        {activeStep === steps.length - 1 ? "Submit" : "Next"}
                      </button>
                    </Box>
                  </Box>
                </Box>
              ) : activeStep === 1 ? (
                <div>
                  <Tabs
                    tabPosition="left"
                    items={TabOptions.map((option) => ({
                      label: option.title,
                      key: String(option.id),
                      children: (
                        <div>
                          {/* {option.id === 1 && (
                <div className="form-group-gwad border rounded p-3">
                  <label htmlFor="dpAsOfDt" className="form-label-gwad">
                    As Of Date
                  </label>
                  <DatePicker
                    id="dpAsOfDt"
                    value={asOfDt}
                    minDate={new Date("1/1/1000")}
                    maxDate={maxDate}
                    onChange={handleAsOfDate}
                    className="form-input-gwad w-100"
                  />
                </div>
              )} */}
                          {option.id === 0 && (
                            <div className="form-container-gwad w-100">
                              {/* Responsive Grid Layout */}
                              <div className="row">
                                {/* Date Fields on Top Row */}
                                {updatedParams.AsOfDate && (
                                  <div className="col-12 col-md-4 mb-1">
                                    <div className="form-group-gwad border rounded p-3">
                                      <label
                                        htmlFor="dpAsOfDt"
                                        className="form-label-gwad"
                                      >
                                        As Of Date
                                      </label>
                                      <DatePicker
                                        id="dpAsOfDt"
                                        value={asOfDt}
                                        min={new Date("1/1/1000")}
                                        max={maxDate}
                                        onChange={(e) =>
                                          handleAsOfDate(e.value)
                                        }
                                        className="form-input-gwad w-100"
                                      />
                                    </div>
                                  </div>
                                )}
                                {updatedParams.FromDate && (
                                  <div className="col-12 col-md-4 mb-1">
                                    <div className="form-group-gwad border rounded p-3">
                                      <label
                                        htmlFor="dpFrm"
                                        className="form-label-gwad"
                                      >
                                        From
                                      </label>
                                      <DatePicker
                                        id="dpFrm"
                                        value={frmDate}
                                        min={minDate}
                                        max={maxDate}
                                        disabled={isDisabled}
                                        onChange={(e) =>
                                          handleFromDate(e.target.value)
                                        }
                                        className="form-input-gwad w-100"
                                      />
                                    </div>
                                  </div>
                                )}
                                {updatedParams.ToDate && (
                                  <div className="col-12 col-md-4 mb-1">
                                    <div className="form-group-gwad border rounded p-3">
                                      <label
                                        htmlFor="dpTo"
                                        className="form-label-gwad"
                                      >
                                        To
                                      </label>
                                      <DatePicker
                                        id="dpTo"
                                        value={toDate}
                                        min={minDate}
                                        max={maxDate}
                                        onChange={(e) =>
                                          handleToDate(e.target.value)
                                        }
                                        className="form-input-gwad w-100"
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>

                              {/* Pricing Options */}
                              {updatedParams.PriceFlag && (
                                <div className="row mb-3">
                                  <div className="col-12">
                                    <div className="form-group-gwad border rounded p-3">
                                      <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                      >
                                        Select Pricing Option
                                      </Typography>
                                      <div className="radio-checkbox-row-gwad">
                                        {[1, 2, 3].map((option) => (
                                          <label
                                            key={option}
                                            className="radio-label-gwad"
                                          >
                                            <input
                                              type="radio"
                                              value={option}
                                              checked={
                                                selectedOption === option
                                              }
                                              onChange={handleRadioChange}
                                              className="radio-input-gwad"
                                            />
                                            {option === 1
                                              ? "Use Current Price"
                                              : option === 2
                                              ? "Use End of Month Price"
                                              : "Use Non EOM Price"}
                                          </label>
                                        ))}
                                      </div>
                                      {selectedOption === 3 && (
                                        <div className="mt-3 w-25">
                                          <label
                                            htmlFor="dpPriceDt"
                                            className="form-label-gwad"
                                          >
                                            Price Date
                                          </label>
                                          <DatePicker
                                            id="dpPriceDt"
                                            value={priceDt}
                                            calendar={CustomCalendar}
                                            min={new Date("1/1/1000")}
                                            max={maxFrmDt}
                                            onChange={(e) =>
                                              handlePriceDate(e.value)
                                            }
                                            className="form-input-gwad w-100"
                                            style={{ width: "100px" }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Other Inputs */}
                              <div className="row">
                                {updatedParams.Marketcap && (
                                  <div className="col-12 col-md-6 mb-1">
                                    <div className="form-group-gwad border rounded p-3">
                                      <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                      >
                                        Market Capital Type
                                      </Typography>
                                      <ComboBox
                                        data={marcaptyplst}
                                        textField="CdVlDesc"
                                        dataItemKey="CdId"
                                        value={selMarCapTyp}
                                        onChange={mrktCapTypChange}
                                        className="combobox-gwad w-100"
                                      />
                                    </div>
                                  </div>
                                )}
                                {updatedParams.BnchMarkList && (
                                  <div className="col-12 col-md-12 mb-1">
                                    <div className="form-group-gwad border rounded p-3">
                                      <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                      >
                                        Benchmark List &nbsp;&nbsp;{" "}
                                        <BsFillInfoCircleFill
                                          title="Please select atleast one benchmark"
                                          color="#0384fc"
                                          size={15}
                                        />
                                      </Typography>

                                      <MultiSelect
                                        data={countries}
                                        value={value}
                                        itemRender={itemRender}
                                        autoClose={false}
                                        onChange={handleChangeBenchmark}
                                        placeholder="Select benchmarks"
                                        className="multiselect-gwad w-100"
                                        style={{
                                          // height: "90px",
                                          overflowY: "auto",
                                          width: "100%",
                                        }}
                                        tags={
                                          selected > 0
                                            ? [
                                                {
                                                  text: `${selected} items selected`,
                                                  data: [...value],
                                                },
                                              ]
                                            : []
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>

                              {/* Toggles and Switches */}
                              <div className="row">
                                <div className="col-12">
                                  <div className="form-group-gwad border rounded p-3">
                                    <div className="radio-checkbox-row-gwad">
                                      {updatedParams.ShowExcludeAsset && (
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              checked={excludedAsset}
                                              onChange={handleExculdedAssets}
                                              className="switch-gwad"
                                            />
                                          }
                                          label="Show Excluded Assets"
                                        />
                                      )}
                                      {updatedParams.ExcludeSupp && (
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              checked={excludeSuppress}
                                              onChange={handleExcludeSuppress}
                                              className="switch-gwad"
                                            />
                                          }
                                          label="Exclude Suppress Transactions"
                                        />
                                      )}
                                      {updatedParams.SwpInd && (
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              checked={excludeSweep}
                                              onChange={handleExcludeSweep}
                                              className="switch-gwad"
                                            />
                                          }
                                          label="Exclude Sweep Transactions"
                                        />
                                      )}
                                      {updatedParams.ShwPrpsdTrads && (
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              checked={showProcessTrades}
                                              onChange={handleShowProcessTrades}
                                              className="switch-gwad"
                                            />
                                          }
                                          label="Show Proposed Trades"
                                        />
                                      )}
                                      {updatedParams.ShwCPIAdjShares && (
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              checked={tipsInd}
                                              onChange={handleTipsInd}
                                              className="switch-gwad"
                                            />
                                          }
                                          label="Show Adjusted Shares for Tips"
                                        />
                                      )}
                                      {updatedParams.EffectiveDtInd && (
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              checked={effectivecDtInd}
                                              onChange={handleEffectiveDtInd}
                                              className="switch-gwad"
                                            />
                                          }
                                          label="Effective Date Indicator"
                                        />
                                      )}
                                      {updatedParams.InvMix && (
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              checked={invmixcheck}
                                              onChange={handleInvmix}
                                              className="switch-gwad"
                                            />
                                          }
                                          label="Compare against Investment Target Mix"
                                        />
                                      )}
                                      {/* {updatedParams.ShowAllPositionInd && (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={showAllPositionsIndic}
                                    onChange={handleAllPositionsIndicator}
                                    className="switch-gwad"
                                  />
                                }
                                label="Show All Positions"
                              />
                            )} */}
                                      {updatedParams.ShowAccountPortfolio && (
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              checked={shwTtlPortfolio}
                                              onChange={handleShwTtlPortfolio}
                                              className="switch-gwad"
                                            />
                                          }
                                          label="Show Total Portfolio"
                                        />
                                      )}
                                      {updatedParams.MFInd && (
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              checked={mutualFundTrades}
                                              onClick={handleMutualFundTrades}
                                              className="switch-gwad"
                                            />
                                          }
                                          label="Include Mutual Fund Trades"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {option.id === 1 && (
                            <div>
                              {/* As Of Date */}
                              <div style={{ marginBottom: "20px" }}>
                                <label
                                  htmlFor="dpAsOfDt"
                                  className="form-label"
                                  style={{
                                    display: "block",
                                    fontWeight: "bold",
                                    marginBottom: "5px",
                                  }}
                                >
                                  As Of Date
                                </label>
                                <DatePicker
                                  id="dpAsOfDt"
                                  value={paramstaticobj[0][1].AsOfDate}
                                  calendar={CustomCalendar}
                                  // min={new Date("1/1/1000")}
                                  // max={maxFrmDt}
                                  onChange={(e) => handlechnageAsOfDate(e, 1)}
                                  style={{ width: "100%" }}
                                  validationMessage={
                                    paramstaticobj[0][1].AsOfDate == null
                                      ? "Enter a valid date"
                                      : paramstaticobj[0][1].AsOfDate <
                                        new Date("1/1/1000")
                                      ? "Enter a date in the correct format (mm/dd/yyyy)"
                                      : paramstaticobj[0][1].AsOfDate > maxFrmDt
                                      ? "As Of Date cannot exceed the Processing Date"
                                      : ""
                                  }
                                />
                              </div>
                              <hr style={{ margin: "20px 0" }} />

                              {/* Pricing Options */}
                              <h6>Select Pricing Option</h6>
                              <div style={{ marginBottom: "20px" }}>
                                {[1, 2, 3].map((option) => (
                                  <label
                                    key={option}
                                    style={{
                                      display: "block",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      value={option}
                                      checked={
                                        paramstaticobj[0][1].PriceFlag ===
                                        option
                                      }
                                      onChange={(e) => {
                                        handleRadioChangeAcPr(e, 1);
                                      }}
                                    />
                                    {option === 1
                                      ? " Use Current Price"
                                      : option === 2
                                      ? " Use End of Month Price"
                                      : " Use Non EOM Price"}
                                  </label>
                                ))}
                              </div>

                              {/* Conditional Price Date Field */}
                              {paramstaticobj[0][1].PriceFlag === 3 && (
                                <div style={{ marginBottom: "20px" }}>
                                  <label
                                    htmlFor="dpPriceDt"
                                    className="form-label"
                                    style={{
                                      display: "block",
                                      fontWeight: "bold",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    Price Date
                                  </label>
                                  <DatePicker
                                    id="dpPriceDt"
                                    value={paramstaticobj[0][1].PriceDate}
                                    calendar={CustomCalendar}
                                    min={new Date("1/1/1000")}
                                    max={maxFrmDt}
                                    onChange={(e) =>
                                      handlechnagePriceDate(e, 1)
                                    }
                                    style={{ width: "100%" }}
                                    validationMessage={
                                      priceDtAcPr == null
                                        ? "Enter a valid date"
                                        : priceDtAcPr < new Date("1/1/1000")
                                        ? "Enter a date in the correct format (mm/dd/yyyy)"
                                        : priceDtAcPr > maxFrmDt
                                        ? "Price Date cannot exceed the Max Date"
                                        : ""
                                    }
                                  />
                                </div>
                              )}

                              <hr style={{ margin: "20px 0" }} />
                              <h6>Miscellaneous Section</h6>
                              <div style={{ marginBottom: "20px" }}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][1].ShowExcludeAsset
                                      }
                                      onChange={(e) =>
                                        handleExcluddedAsset(e, 1)
                                      }
                                      name="reportDisplaySwitch"
                                    />
                                  }
                                  label="Show Excluded Assets"
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              </div>

                              {/* Report Display Option */}
                              <hr style={{ margin: "20px 0" }} />
                              <h6>Report Display Option</h6>

                              <div style={{ marginBottom: "20px" }}>
                                <FormControl
                                  sx={{ m: 1, width: 350 }}
                                  size="small"
                                >
                                  <Select
                                    value={paramstaticobj[0][1].GrpByMnrAst}
                                    onChange={(e) => handleGrpByMnrAst(e, 1)}
                                    //displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                  >
                                    <MenuItem value={0}>
                                      Display Results by Major Asset Type
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      Display Results by Minor Asset Type
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      Display Results by PMR
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      Display Results by Sector
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      Display Results by Risk Class Type
                                    </MenuItem>
                                    <MenuItem value={5}>
                                      Display Results by Investment Class Type
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                              {AcctId == 0 && (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][1].RunInAggregate
                                      }
                                      onChange={(e) =>
                                        handlerunInAggregate(e, 1)
                                      }
                                      name="chkCtr"
                                    />
                                  }
                                  label="Run In Aggregate."
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              )}
                            </div>
                          )}
                          {option.id === 2 && (
                            <div>
                              {/* As Of Date */}
                              <div style={{ marginBottom: "20px" }}>
                                <label
                                  htmlFor="dpAsOfDt"
                                  className="form-label"
                                  style={{
                                    display: "block",
                                    fontWeight: "bold",
                                    marginBottom: "5px",
                                  }}
                                >
                                  As Of Date
                                </label>
                                <DatePicker
                                  id="dpAsOfDt"
                                  value={paramstaticobj[0][2].AsOfDate}
                                  // calendar={CustomCalendar}
                                  min={new Date("1/1/1000")}
                                  max={maxFrmDt}
                                  onChange={(e) => handlechnageAsOfDate(e, 2)}
                                  style={{ width: "100%" }}
                                  valid={
                                    paramstaticobj[0][2].AsOfDate <= maxFrmDt &&
                                    paramstaticobj[0][2].AsOfDate >=
                                      new Date("1/1/1000")
                                  }
                                  validationMessage={
                                    paramstaticobj[0][2].AsOfDate == null
                                      ? "Enter a valid date"
                                      : paramstaticobj[0][2].AsOfDate <
                                        new Date("1/1/1000")
                                      ? "Enter a date in the correct format (mm/dd/yyyy)"
                                      : paramstaticobj[0][2].AsOfDate > maxFrmDt
                                      ? "As Of Date cannot exceed the Processing Date"
                                      : ""
                                  }
                                />
                              </div>

                              <hr style={{ margin: "20px 0" }} />

                              {/* Pricing Options */}
                              <h6 className="pt-2">Select Pricing Option</h6>
                              <div style={{ marginBottom: "20px" }}>
                                {[1, 2, 3].map((option) => (
                                  <label
                                    key={option}
                                    style={{
                                      display: "block",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      value={option}
                                      checked={
                                        paramstaticobj[0][2].PriceFlag ===
                                        option
                                      }
                                      onChange={(e) => {
                                        handleRadioChangeAcPr(e, 2);
                                      }}
                                    />
                                    {option === 1
                                      ? " Use Current Price"
                                      : option === 2
                                      ? " Use End of Month Price"
                                      : " Use Non EOM Price"}
                                  </label>
                                ))}
                              </div>

                              <hr></hr>

                              {paramstaticobj[0][2].PriceFlag === 3 && (
                                <div style={{ marginBottom: "20px" }}>
                                  <label
                                    htmlFor="dpPriceDt"
                                    className="form-label"
                                    style={{
                                      display: "block",
                                      fontWeight: "bold",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    Price Date
                                  </label>
                                  <DatePicker
                                    id="dpPriceDt"
                                    value={paramstaticobj[0][2].PriceDate}
                                    calendar={CustomCalendar}
                                    min={new Date("1/1/1000")}
                                    max={maxFrmDt}
                                    onChange={(e) =>
                                      handlechnagePriceDate(e, 2)
                                    }
                                    style={{ width: "100%" }}
                                    validationMessage={
                                      priceDtPortH == null
                                        ? "Enter a valid date"
                                        : priceDtPortH < new Date("1/1/1000")
                                        ? "Enter a date in the correct format (mm/dd/yyyy)"
                                        : priceDtPortH > maxFrmDt
                                        ? "Price Date cannot exceed the Processing Date"
                                        : ""
                                    }
                                  />

                                  <hr></hr>
                                </div>
                              )}

                              <h6>Display Percent</h6>
                              <div style={{ marginBottom: "20px" }}>
                                {[1, 2, 3].map((option) => (
                                  <label
                                    key={option}
                                    style={{
                                      display: "block",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      value={option}
                                      checked={
                                        paramstaticobj[0][2].DisplyPrcnt ===
                                        option
                                      }
                                      onChange={(e) =>
                                        handleDisplayPercentChange(e, 2)
                                      }
                                    />
                                    {option === 1
                                      ? " Total Portfolio"
                                      : option === 2
                                      ? " Selected Portfolio"
                                      : " Major Asset Class"}
                                  </label>
                                ))}
                              </div>

                              <hr></hr>
                              {/* Miscellaneous Section */}
                              <h6>Miscellaneous Section</h6>
                              <div style={{ marginBottom: "20px" }}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][2].ShowExcludeAsset
                                      }
                                      onChange={(e) =>
                                        handleExcluddedAsset(e, 2)
                                      }
                                      name="excludeAsset"
                                    />
                                  }
                                  label=" Show Excluded Assets"
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />

                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][2].ShwPrpsdTrds
                                      }
                                      onChange={handleShowProcessTradesChange}
                                      name="tipsSwitch"
                                    />
                                  }
                                  label=" Show Proposed Trades"
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              </div>

                              {AcctId == 0 && (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][2].RunInAggregate
                                      }
                                      onChange={(e) =>
                                        handlerunInAggregate(e, 2)
                                      }
                                      name="chkCtr"
                                    />
                                  }
                                  label="Run In Aggregate."
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              )}
                            </div>
                          )}
                          {option.id === 3 && (
                            <div>
                              {/* As Of Date */}
                              <div style={{ marginBottom: "20px" }}>
                                <label
                                  htmlFor="dpAsOfDt"
                                  className="form-label"
                                  style={{
                                    display: "block",
                                    fontWeight: "bold",
                                    marginBottom: "5px",
                                  }}
                                >
                                  As Of Date
                                </label>
                                <DatePicker
                                  id="dpAsOfDt"
                                  value={paramstaticobj[0][3].AsOfDate}
                                  calendar={CustomCalendar}
                                  min={new Date("1/1/1000")}
                                  max={maxFrmDt}
                                  onChange={(e) => handlechnageAsOfDate(e, 3)}
                                  style={{ width: "100%" }}
                                  validationMessage={
                                    paramstaticobj[0][3].AsOfDate == null
                                      ? "Enter a valid date"
                                      : paramstaticobj[0][3].AsOfDate <
                                        new Date("1/1/1000")
                                      ? "Enter a date in the correct format (mm/dd/yyyy)"
                                      : paramstaticobj[0][3].AsOfDate > maxFrmDt
                                      ? "As Of Date cannot exceed the Processing Date"
                                      : ""
                                  }
                                />
                              </div>

                              <hr style={{ margin: "20px 0" }} />

                              {/* Pricing Options */}
                              <h6>Select Pricing Option</h6>
                              <div style={{ marginBottom: "20px" }}>
                                {[1, 2, 3].map((option) => (
                                  <label
                                    key={option}
                                    style={{
                                      display: "block",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      value={option}
                                      checked={
                                        paramstaticobj[0][3].PriceFlag ===
                                        option
                                      }
                                      onChange={(e) => {
                                        handleRadioChangeAcPr(e, 3);
                                      }}
                                    />
                                    {option === 1
                                      ? " Use Current Price"
                                      : option === 2
                                      ? " Use End of Month Price"
                                      : " Use Non EOM Price"}
                                  </label>
                                ))}
                              </div>

                              {/* Conditional Price Date Field */}
                              {paramstaticobj[0][3].PriceFlag === 3 && (
                                <div style={{ marginBottom: "20px" }}>
                                  <label
                                    htmlFor="dpPriceDt"
                                    className="form-label"
                                    style={{
                                      display: "block",
                                      fontWeight: "bold",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    Price Date
                                  </label>
                                  <DatePicker
                                    id="dpPriceDt"
                                    value={paramstaticobj[0][3].PriceDate}
                                    calendar={CustomCalendar}
                                    min={new Date("1/1/1000")}
                                    max={maxFrmDt}
                                    onChange={(e) =>
                                      handlechnagePriceDate(e, 3)
                                    }
                                    style={{ width: "100%" }}
                                    validationMessage={
                                      priceDtAccH == null
                                        ? "Enter a valid date"
                                        : priceDtAccH < new Date("1/1/1000")
                                        ? "Enter a date in the correct format (mm/dd/yyyy)"
                                        : priceDtAccH > maxFrmDt
                                        ? "Price Date cannot exceed the Processing Date"
                                        : ""
                                    }
                                  />
                                </div>
                              )}

                              <hr style={{ margin: "20px 0" }} />

                              {/* Miscellaneous Section */}
                              <h6>Miscellaneous Section</h6>
                              <div style={{ marginBottom: "20px" }}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][3].EffectiveDtInd
                                      }
                                      onChange={(e) =>
                                        handleEffectiveDtIndChange(e, 3)
                                      }
                                      name="effectiveDtSwitch"
                                    />
                                  }
                                  label="Effective Date Indicator"
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][3].ShwCPIAdjShrs
                                      }
                                      onChange={(e) =>
                                        handleTipsIndChange(e, 3)
                                      }
                                      name="tipsSwitch"
                                    />
                                  }
                                  label="Show Adjusted Shares for Tips"
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          {option.id === 4 && (
                            <div>
                              <div className="subheader">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="chkInvTrgMix"
                                  checked={
                                    paramstaticobj[0][4].OverdraftRangeCheckBox
                                  }
                                  onChange={handleOverdraftCheckBox}
                                ></input>
                                <span className="pl-2">
                                  &nbsp; Show Overdraft Only
                                </span>
                              </div>
                              <br></br>

                              <div className="subheader  col-md-2">
                                <span>Overdraft Value : </span>
                                <input
                                  className="textbox"
                                  placeholder="Enter Value"
                                  type="number"
                                  name="chkInvTrgMix"
                                  // value={
                                  //   showOverdraft ? localStorage.getItem("overdraftvalue") : ""
                                  // }
                                  value={paramstaticobj[0][4].OverdraftRange}
                                  onChange={handleOverdraftValue}
                                  disabled={
                                    !paramstaticobj[0][4].OverdraftRangeCheckBox
                                  }
                                ></input>
                              </div>

                              <NotificationGroup
                                style={{
                                  right: 600,
                                  top: 0,
                                  zIndex: 20000,
                                  alignItems: "flex-start",
                                  flexWrap: "wrap-reverse",
                                  width: "auto",
                                }}
                              >
                                <Slide direction={"down"}>
                                  {(showOverdraftCash === false
                                    ? false
                                    : showOverdraftCash === true &&
                                      parseFloat(overdraftValCash) === 0 &&
                                      errorvisible
                                    ? true
                                    : false) && (
                                    <Notification
                                      type={{
                                        style: "error",
                                        icon: false,
                                      }}
                                      closable={true}
                                      onClose={() => seterrorvisible(false)}
                                    >
                                      <span>
                                        Enter a non-zero value for Overdraft
                                        range.
                                      </span>
                                    </Notification>
                                  )}
                                </Slide>
                              </NotificationGroup>
                            </div>
                          )}
                          {option.id === 5 && (
                            <div>
                              {/* Start Date */}
                              <div style={{ marginBottom: "20px" }}>
                                <label
                                  htmlFor="dpAsOfDt"
                                  className="form-label"
                                  style={{
                                    display: "block",
                                    fontWeight: 600,
                                    marginBottom: "5px",
                                  }}
                                >
                                  From
                                </label>
                                <DatePicker
                                  id="dpFrm"
                                  value={paramstaticobj[0][5].StartDate}
                                  valid={paramstaticobj[0][5].StartDate <= date}
                                  max={date}
                                  onChange={(e) => handleChangeStartDate(e, 5)}
                                  validationMessage={
                                    paramstaticobj[0][5].StartDate == null
                                      ? "Enter Valid Date"
                                      : paramstaticobj[0][5].StartDate <
                                        new Date("1/1/1000")
                                      ? "Enter date in correct format (mm/dd/yyyy)"
                                      : paramstaticobj[0][5].StartDate > date
                                      ? "From date cannot exceed Processing date."
                                      : paramstaticobj[0][5].StartDate > toDate
                                      ? "From date cannot exceed to date."
                                      : paramstaticobj[0][5].StartDate <
                                        new Date("1/2/1000")
                                      ? "From date is not valid."
                                      : "Validation error"
                                  }
                                />
                              </div>
                              <hr></hr>

                              <div style={{ marginBottom: "20px" }}>
                                <label
                                  htmlFor="dpAsOfDt"
                                  className="form-label"
                                  style={{
                                    display: "block",
                                    fontWeight: 600,
                                    marginBottom: "5px",
                                  }}
                                >
                                  To
                                </label>
                                <DatePicker
                                  //disabled={false}
                                  value={paramstaticobj[0][5].EndDate}
                                  max={date}
                                  // formatPlaceholder={{
                                  //   year: "yyyy",
                                  //   month: "mm",
                                  //   day: "dd",
                                  // }}
                                  // format="MM/dd/yyyy"
                                  valid={
                                    frmDate <= paramstaticobj[0][5].EndDate &&
                                    paramstaticobj[0][5].EndDate <= date &&
                                    paramstaticobj[0][5].EndDate != null
                                  }
                                  onChange={(e) => handleChangeEndDate(e, 5)}
                                  // calendar={CustomCalendar}
                                  validationMessage={
                                    paramstaticobj[0][5].EndDate == null
                                      ? "Enter Valid Date"
                                      : paramstaticobj[0][5].EndDate <
                                        new Date("1/1/1000")
                                      ? "Enter date in correct format (mm/dd/yyyy)"
                                      : paramstaticobj[0][5].EndDate > date
                                      ? "To date cannot exceed Processing date."
                                      : paramstaticobj[0][5].EndDate < frmDate
                                      ? "To date cannot be less than from date"
                                      : paramstaticobj[0][5].EndDate <
                                        new Date("1/2/1000")
                                      ? "To date is not valid."
                                      : "Validation error"
                                  }
                                />
                              </div>
                              <hr></hr>
                              <div className="col">
                                <div style={{ fontWeight: 600 }}>
                                  Display Results By:
                                </div>
                                <ComboBox
                                  style={{
                                    width: "350px",
                                  }}
                                  data={assetTypLst}
                                  textField="cdVlDesc"
                                  dataItemKey="cdId"
                                  value={paramstaticobj[0][5].AstType}
                                  onChange={(e) => assetTypeChange(e)}
                                />
                              </div>
                              <hr></hr>

                              <h6>Miscellaneous Section</h6>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      paramstaticobj[0][5].NetOfFeesInd === 1
                                    }
                                    onChange={(e) =>
                                      handleChangeNetOfFeeInd(e, 5)
                                    }
                                    name="chkCtr"
                                  />
                                }
                                label="Net Fees in the ROR Calculation."
                                style={{
                                  marginBottom: "10px",
                                  display: "block",
                                }}
                              />
                            </div>
                          )}

                          {option.id === 6 && (
                            <div>
                              <h6>Market Capital Type: </h6>
                              <ComboBox
                                data={marketCapitalList}
                                textField="CdVlDesc"
                                dataItemKey="CdId"
                                filterable={true}
                                value={selMarCapTyp}
                                onChange={mrktCapTypChange}
                              />
                              {validationError && (
                                <div
                                  style={{ color: "red", marginTop: "10px" }}
                                >
                                  {validationError}
                                </div>
                              )}

                              {AcctId == 0 && (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][6].RunInAggregate
                                      }
                                      onChange={(e) =>
                                        handlerunInAggregate(e, 6)
                                      }
                                      name="chkCtr"
                                    />
                                  }
                                  label="Run In Aggregate."
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              )}
                            </div>
                          )}

                          {option.id === 7 && (
                            <div>
                              <h6>As Of Date</h6>
                              <DatePicker
                                id="dpFrm"
                                value={paramstaticobj[0][7].AsOfDate}
                                // format="MM/dd/yyyy"
                                calendar={CustomCalendar}
                                // min={minFrmDt}
                                // formatPlaceholder={{
                                //   year: "yyyy",
                                //   month: "mm",
                                //   day: "dd",
                                // }}
                                width={300}
                                // max={maxFrmDt}
                                //disabled={isDisabled}
                                onChange={(e) => handlechnageAsOfDate(e, 7)}
                              />

                              <hr></hr>

                              {/* Pricing Options */}
                              <h6>Price Selection</h6>

                              <div style={{ marginBottom: "20px" }}>
                                {[1, 2, 3].map((option) => (
                                  <label
                                    key={option}
                                    style={{
                                      display: "block",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      value={option}
                                      checked={
                                        paramstaticobj[0][7].PriceFlag ===
                                        option
                                      }
                                      onChange={(e) => {
                                        handleRadioChangeAcPr(e, 7);
                                      }}
                                    />
                                    {option === 1
                                      ? " Use Current Price"
                                      : option === 2
                                      ? " Use End of Month Price"
                                      : " Use Non EOM Price"}
                                  </label>
                                ))}
                              </div>

                              {/* Conditional Price Date Field */}
                              {paramstaticobj[0][7].PriceFlag === 3 && (
                                <div style={{ marginBottom: "20px" }}>
                                  <label
                                    htmlFor="dpPriceDt"
                                    className="form-label"
                                    style={{
                                      display: "block",
                                      fontWeight: "bold",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    From Price Date
                                  </label>
                                  <DatePicker
                                    id="dpPriceDt"
                                    value={paramstaticobj[0][7].PriceDate}
                                    calendar={CustomCalendar}
                                    // min={new Date("1/1/1000")}
                                    // max={maxFrmDt}
                                    width={300}
                                    onChange={(e) =>
                                      handlechnagePriceDate(e, 7)
                                    }
                                    style={{ width: "100%" }}
                                  />
                                </div>
                              )}

                              <hr></hr>

                              {/* Miscellaneous Section */}
                              <h6>Miscellaneous Section</h6>

                              <div style={{ marginBottom: "20px" }}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][7].ShowExcludeAsset
                                      }
                                      onChange={(e) =>
                                        handleExcluddedAsset(e, 7)
                                      }
                                      name="excludeAsset"
                                    />
                                  }
                                  label=" Show Excluded Asset"
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              </div>

                              {AcctId == 0 && (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][7].RunInAggregate
                                      }
                                      onChange={(e) =>
                                        handlerunInAggregate(e, 7)
                                      }
                                      name="chkCtr"
                                    />
                                  }
                                  label="Run In Aggregate."
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              )}
                            </div>
                          )}

                          {option.id === 8 && (
                            <div>
                              <div style={{ marginBottom: "20px" }}>
                                <label
                                  htmlFor="dpFrm"
                                  className="form-label"
                                  style={{
                                    display: "block",
                                    fontWeight: "bold",
                                    marginBottom: "5px",
                                  }}
                                >
                                  From
                                </label>
                                <DatePicker
                                  id="dpFrm"
                                  value={paramstaticobj[0][8].StartDate}
                                  // calendar={CustomCalendar}
                                  // min={minFrmDt}
                                  // max={maxFrmDt}
                                  // disabled={isDisabled}
                                  style={{ width: "100%" }}
                                  // valid={
                                  //   frmDate >= minFrmDt && frmDate <= maxFrmDt && frmDate != null
                                  // }
                                  onChange={(e) => handleChangeStartDate(e, 8)}
                                  // validationMessage={
                                  //   frmDate == null
                                  //     ? "Enter Valid Date"
                                  //     : frmDate < new Date("1/1/1000")
                                  //     ? "Enter date in correct format (mm/dd/yyyy)"
                                  //     : frmDate > maxFrmDt
                                  //     ? "From date cannot exceed one year from Processing date."
                                  //     : frmDate > toDate
                                  //     ? "From date cannot exceed to date."
                                  //     : frmDate < minFrmDt
                                  //     ? "From date cannot be less than Processing date."
                                  //     : "Validation error"
                                  // }
                                />
                              </div>

                              <div style={{ marginBottom: "20px" }}>
                                <label
                                  htmlFor="dpTo"
                                  className="form-label"
                                  style={{
                                    display: "block",
                                    fontWeight: "bold",
                                    marginBottom: "5px",
                                  }}
                                >
                                  To
                                </label>
                                <DatePicker
                                  id="dpTo"
                                  value={paramstaticobj[0][8].EndDate}
                                  // format="MM/dd/yyyy"
                                  // calendar={CustomCalendar}
                                  // min={minFrmDt}
                                  // max={maxFrmDt}
                                  // disabled={false}
                                  onChange={(e) => handleChangeEndDate(e, 8)}
                                  style={{ width: "100%" }}
                                  // valid={
                                  //   frmDate <= toDate && toDate <= maxFrmDt && toDate >= minFrmDt
                                  // }
                                  // validationMessage={
                                  //   toDate == null
                                  //     ? "Enter Valid Date"
                                  //     : toDate < new Date("1/1/1000")
                                  //     ? "Enter date in correct format (mm/dd/yyyy)"
                                  //     : toDate > maxFrmDt
                                  //     ? "To date cannot exceed one year from Processing date."
                                  //     : toDate < frmDate
                                  //     ? "To date cannot be less than from date"
                                  //     : toDate < minFrmDt
                                  //     ? "To date cannot be less than Processing date."
                                  //     : "Validation error"
                                  // }
                                />
                              </div>

                              <h6>Miscellaneous Section</h6>

                              <div
                                className="form-group"
                                style={{ marginBottom: "20px" }}
                              >
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][8].EffectiveDtInd
                                      }
                                      onChange={(e) =>
                                        handleEffectiveDtIndChange(e, 8)
                                      }
                                      name="chkCtr"
                                    />
                                  }
                                  label="Effective Date Indicator"
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={paramstaticobj[0][8].ExcludeSupp}
                                      onChange={(e) =>
                                        handleExcludeSuppChange(e, 8)
                                      }
                                      name="chkCtr"
                                    />
                                  }
                                  label="Exclude Suppress Transactions"
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={paramstaticobj[0][8].Swpind}
                                      onChange={(e) =>
                                        handleExcludeSweepInd(e, 8)
                                      }
                                      name="chkCtr"
                                    />
                                  }
                                  label="Exclude Sweep Transactions"
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              </div>
                            </div>
                          )}

                          {option.id === 9 && (
                            <div>
                              <div className="pt-3">
                                <span>
                                  <h6>As of Date</h6>
                                </span>
                                <DatePicker
                                  id="dpFrm"
                                  value={paramstaticobj[0][9].AsOfDate}
                                  format="MM/dd/yyyy"
                                  // calendar={CustomCalendar}
                                  // min={minFrmDt}
                                  // max={maxFrmDt}
                                  // disabled={isDisabled}
                                  onChange={(e) => handlechnageAsOfDate(e, 9)}
                                />
                              </div>
                              <hr style={{ margin: "20px 0" }} />

                              {/* Pricing Options */}
                              <h6>Price Selection</h6>

                              <div style={{ marginBottom: "20px" }}>
                                {[1, 2, 3].map((option) => (
                                  <label
                                    key={option}
                                    style={{
                                      display: "block",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      value={option}
                                      checked={
                                        paramstaticobj[0][9].PriceFlag ===
                                        option
                                      }
                                      onChange={(e) => {
                                        handleRadioChangeAcPr(e, 9);
                                      }}
                                    />
                                    {option === 1
                                      ? " Use Current Price"
                                      : option === 2
                                      ? " Use End of Month Price"
                                      : " Use Non EOM Price"}
                                  </label>
                                ))}
                              </div>

                              {/* Conditional Price Date Field */}
                              {paramstaticobj[0][9].PriceFlag === 3 && (
                                <div style={{ marginBottom: "20px" }}>
                                  <label
                                    htmlFor="dpPriceDt"
                                    className="form-label"
                                    style={{
                                      display: "block",
                                      fontWeight: "bold",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    From Price Date
                                  </label>
                                  <DatePicker
                                    id="dpPriceDt"
                                    value={paramstaticobj[0][9].PriceDate}
                                    calendar={CustomCalendar}
                                    // min={new Date("1/1/1000")}
                                    // max={maxFrmDt}
                                    width={300}
                                    onChange={(e) =>
                                      handlechnagePriceDate(e, 9)
                                    }
                                    style={{ width: "100%" }}
                                  />
                                </div>
                              )}

                              <hr></hr>

                              <div>
                                <h6>
                                  Benchmark/ Model &nbsp;&nbsp;{" "}
                                  <BsFillInfoCircleFill
                                    title="Please select any model"
                                    color="#0384fc"
                                    size={15}
                                  />{" "}
                                </h6>

                                <ComboBox
                                  style={{
                                    width: "300px",
                                  }}
                                  data={modellist}
                                  textField="modelDesc"
                                  dataItemKey="modelId"
                                  filterable={true}
                                  value={paramstaticobj[0][9].BenchMarkId}
                                  onChange={(e) => modelChange(e, 9)}
                                />
                                {validationError && (
                                  <div
                                    style={{ color: "red", marginTop: "10px" }}
                                  >
                                    {validationError}
                                  </div>
                                )}
                              </div>

                              <hr></hr>
                              <h6>Miscellaneous Section</h6>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      paramstaticobj[0][9].ShowExcludeAsset
                                    }
                                    onChange={(e) => handleExcluddedAsset(e, 9)}
                                    name="chkCtr"
                                  />
                                }
                                label="Show Excluded Assets."
                                style={{
                                  marginBottom: "10px",
                                  display: "block",
                                }}
                              />

                              {AcctId == 0 && (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][9].RunInAggregate
                                      }
                                      onChange={(e) =>
                                        handlerunInAggregate(e, 9)
                                      }
                                      name="chkCtr"
                                    />
                                  }
                                  label="Run In Aggregate."
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              )}
                            </div>
                          )}

                          {option.id === 10 && (
                            <div>
                              <h6>As Of Date</h6>
                              {/* <div className="col-md-3 col-lg-3 col-sm-10"> */}
                              <span className="py-1">From </span>&nbsp;&nbsp;
                              <DatePicker
                                id="dpFrm"
                                value={paramstaticobj[0][10].AsOfStartDt}
                                format="MM/dd/yyyy"
                                calendar={CustomCalendar}
                                min={minFrmDt}
                                formatPlaceholder={{
                                  year: "yyyy",
                                  month: "mm",
                                  day: "dd",
                                }}
                                width={300}
                                //max={maxFrmDt}
                                disabled={isDisabled}
                                onChange={(e) =>
                                  handlechnageAsOfDateStartDate(e, 10)
                                }
                                // onChange={(e) => {
                                //   setDate(e.value);
                                // }}
                                // validationMessage={
                                //   frmDate == null
                                //     ? "Enter Valid Date"
                                //     : frmDate < new Date("1/1/1000")
                                //     ? "Enter date in correct format (mm/dd/yyyy)"
                                //     : frmDate > maxFrmDt
                                //     ? "From date cannot exceed one year from Processing date."
                                //     : frmDate > toDate
                                //     ? "From date cannot exceed to date."
                                //     : frmDate < minFrmDt
                                //     ? "From date cannot be less than Processing date."
                                //     : "Validation error"
                                // }
                              />
                              {/* </div> */}
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              {/* <div className="col-md-3 col-lg-3 col-sm-10"> */}
                              <span className="py-1">To</span>&nbsp;&nbsp;
                              <DatePicker
                                //disabled={true}
                                value={paramstaticobj[0][10].AsOfEndDt}
                                format="MM/dd/yyyy"
                                calendar={CustomCalendar}
                                formatPlaceholder={{
                                  year: "yyyy",
                                  month: "mm",
                                  day: "dd",
                                }}
                                width={300}
                                //min={minToDate}
                                //max={maxToDate}
                                onChange={(e) =>
                                  handlechnageAsOfDateEndDate(e, 10)
                                }
                                // onChange={(e) => {
                                //   setTDate(e.value);
                                // }}
                                // validationMessage={
                                //   toDate == null
                                //     ? "Enter Valid Date"
                                //     : toDate < new Date("1/1/1000")
                                //     ? "Enter date in correct format (mm/dd/yyyy)"
                                //     : toDate > maxToDate
                                //     ? "To date cannot exceed one year from Processing date."
                                //     : toDate < frmDate
                                //     ? "To date cannot be less than from date"
                                //     : toDate < minToDate
                                //     ? "To date cannot be less than Processing date."
                                //     : "Validation error"
                                // }
                              />
                              {/* </div> */}
                              <hr></hr>
                              {/* Pricing Options */}
                              <h6>Select Pricing Option</h6>
                              <span>From Date</span>
                              <div style={{ marginBottom: "20px" }}>
                                {[1, 2, 3].map((option) => (
                                  <label
                                    key={option}
                                    style={{
                                      display: "block",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      value={option}
                                      checked={
                                        paramstaticobj[0][10].mPriceFlag ===
                                        option
                                      }
                                      onChange={(e) => {
                                        fromPriceDtHandleRadioChange(e, 10);
                                      }}
                                    />
                                    {option === 1
                                      ? " Use Current Price"
                                      : option === 2
                                      ? " Use End of Month Price"
                                      : " Use Non EOM Price"}
                                  </label>
                                ))}
                              </div>
                              {/* Conditional Price Date Field */}
                              {paramstaticobj[0][10].mPriceFlag === 3 && (
                                <div style={{ marginBottom: "20px" }}>
                                  <label
                                    htmlFor="dpPriceDt"
                                    className="form-label"
                                    style={{
                                      display: "block",
                                      fontWeight: "bold",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    From Price Date
                                  </label>
                                  <DatePicker
                                    id="dpPriceDt"
                                    value={paramstaticobj[0][10].mPriceDt}
                                    calendar={CustomCalendar}
                                    //min={new Date("1/1/1000")}
                                    //max={maxFrmDt}
                                    width={300}
                                    onChange={(e) => {
                                      let tempparamstaticobj = [
                                        ...paramstaticobj,
                                      ];
                                      tempparamstaticobj[0][10].mPriceDt =
                                        e.value;

                                      setparamstaticobj(tempparamstaticobj);
                                    }}
                                    style={{ width: "100%" }}
                                    // validationMessage={
                                    //   fromPriceDt == null
                                    //     ? "Enter Valid Date"
                                    //     : fromPriceDt < new Date("1/1/1000")
                                    //     ? "Enter date in correct format (mm/dd/yyyy)"
                                    //     : fromPriceDt > maxFrmDt
                                    //     ? "From date cannot exceed one year from Processing date."
                                    //     : fromPriceDt > toDate
                                    //     ? "From date cannot exceed to date."
                                    //     : fromPriceDt < new Date("1/1/1000")
                                    //     ? "From date cannot be less than Processing date."
                                    //     : "Validation error"
                                    // }
                                  />
                                </div>
                              )}
                              <hr></hr>
                              <span>To Date</span>
                              <div style={{ marginBottom: "20px" }}>
                                {[1, 2, 3].map((option) => (
                                  <label
                                    key={option}
                                    style={{
                                      display: "block",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      value={option}
                                      checked={
                                        paramstaticobj[0][10].ePriceFlag ===
                                        option
                                      }
                                      onChange={(e) => {
                                        toPriceDtHandleRadioChange(e, 10);
                                      }}
                                    />
                                    {option === 1
                                      ? " Use Current Price"
                                      : option === 2
                                      ? " Use End of Month Price"
                                      : " Use Non EOM Price"}
                                  </label>
                                ))}
                              </div>
                              {/* Conditional Price Date Field */}
                              {paramstaticobj[0][10].ePriceFlag === 3 && (
                                <div style={{ marginBottom: "20px" }}>
                                  <label
                                    htmlFor="dpPriceDt"
                                    className="form-label"
                                    style={{
                                      display: "block",
                                      fontWeight: "bold",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    To Price Date
                                  </label>
                                  <DatePicker
                                    id="dpPriceDt"
                                    value={paramstaticobj[0][10].ePriceDt}
                                    width={300}
                                    calendar={CustomCalendar}
                                    min={new Date("1/1/1000")}
                                    //max={maxFrmDt}
                                    onChange={(e) => {
                                      let tempparamstaticobj = [
                                        ...paramstaticobj,
                                      ];
                                      tempparamstaticobj[0][10].ePriceDt =
                                        e.value;

                                      setparamstaticobj(tempparamstaticobj);
                                    }}
                                    //onChange={(e) => setToPriceDt(e.value)}
                                    style={{ width: "100%" }}
                                    // validationMessage={
                                    //   toDate == null
                                    //     ? "Enter Valid Date"
                                    //     : toDate < new Date("1/1/1000")
                                    //     ? "Enter date in correct format (mm/dd/yyyy)"
                                    //     : toDate > fromPriceDt
                                    //     ? "To date cannot exceed one year from Processing date."
                                    //     : toDate < fromPriceDt
                                    //     ? "To date cannot be less than from date"
                                    //     : toDate < new Date("1/1/1000")
                                    //     ? "To date cannot be less than Processing date."
                                    //     : "Validation error"
                                    // }
                                  />
                                </div>
                              )}
                              <hr></hr>
                              {/* Miscellaneous Section */}
                              <h6>Miscellaneous Section</h6>
                              <div style={{ marginBottom: "20px" }}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][10].ShowExcludeAsset
                                      }
                                      onChange={(e) =>
                                        handleExcluddedAsset(e, 10)
                                      }
                                      name="excludeAsset"
                                    />
                                  }
                                  label=" Show Excluded Assets"
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              </div>
                              {/* Actions */}
                              {AcctId == 0 && (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][10].RunInAggregate
                                      }
                                      onChange={(e) =>
                                        handlerunInAggregate(e, 10)
                                      }
                                      name="chkCtr"
                                    />
                                  }
                                  label="Run In Aggregate."
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              )}
                            </div>
                          )}

                          {option.id === 11 && (
                            <div>
                              <div style={{ marginBottom: "20px" }}>
                                <label
                                  htmlFor="dpAsOfDt"
                                  className="form-label"
                                  style={{
                                    display: "block",
                                    fontWeight: "bold",
                                    marginBottom: "5px",
                                  }}
                                >
                                  As Of Date
                                </label>
                                <DatePicker
                                  id="dpAsOfDt"
                                  value={paramstaticobj[0][11].AsOfDate}
                                  calendar={CustomCalendar}
                                  min={new Date("1/1/1000")}
                                  max={maxFrmDt}
                                  onChange={(e) => handlechnageAsOfDate(e, 11)}
                                  style={{ width: "100%" }}
                                  validationMessage={
                                    paramstaticobj[0][11].AsOfDate == null
                                      ? "Enter a valid date"
                                      : paramstaticobj[0][11].AsOfDate <
                                        new Date("1/1/1000")
                                      ? "Enter a date in the correct format (mm/dd/yyyy)"
                                      : paramstaticobj[0][11].AsOfDate >
                                        maxFrmDt
                                      ? "As Of Date cannot exceed the Processing Date"
                                      : ""
                                  }
                                />
                              </div>
                              <hr></hr>
                              <div className="col">
                                <div style={{ fontWeight: "600" }}>
                                  {" "}
                                  <BsFillInfoCircleFill
                                    title="Please select any model"
                                    color="#0384fc"
                                    size={15}
                                  />{" "}
                                  Model:{" "}
                                </div>

                                <ComboBox
                                  style={{
                                    width: "300px",
                                  }}
                                  data={portfolioBreakdownModel}
                                  textField="modelDesc"
                                  dataItemKey="modelId"
                                  filterable={true}
                                  value={paramstaticobj[0][11].Model}
                                  onChange={handleModelChange}
                                />
                              </div>
                              <div className="col">
                                <div style={{ fontWeight: "600" }}>
                                  <BsFillInfoCircleFill
                                    title="Please select any benchmark"
                                    color="#0384fc"
                                    size={15}
                                  />{" "}
                                  Benchmark:
                                </div>
                                <ComboBox
                                  style={{
                                    width: "300px",
                                  }}
                                  data={portfolioBreakdownBenchmark}
                                  textField="modelDesc"
                                  dataItemKey="modelId"
                                  filterable={true}
                                  value={paramstaticobj[0][11].BenchMark}
                                  onChange={bnchmrkChange}
                                />
                              </div>
                              {validationError && (
                                <div
                                  style={{ color: "red", marginTop: "10px" }}
                                >
                                  {validationError}
                                </div>
                              )}

                              {/* Pricing Options */}
                              <h6>Select Pricing Option</h6>
                              <div style={{ marginBottom: "20px" }}>
                                {[1, 2, 3].map((option) => (
                                  <label
                                    key={option}
                                    style={{
                                      display: "block",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      value={option}
                                      checked={
                                        paramstaticobj[0][11].PriceFlag ===
                                        option
                                      }
                                      onChange={(e) => {
                                        handleRadioChangeAcPr(e, 11);
                                      }}
                                    />
                                    {option === 1
                                      ? " Use Current Price"
                                      : option === 2
                                      ? " Use End of Month Price"
                                      : " Use Non EOM Price"}
                                  </label>
                                ))}
                              </div>

                              {/* Conditional Price Date Field */}
                              {paramstaticobj[0][11].PriceFlag === 3 && (
                                <div style={{ marginBottom: "20px" }}>
                                  <label
                                    htmlFor="dpPriceDt"
                                    className="form-label"
                                    style={{
                                      display: "block",
                                      fontWeight: "600",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    Price Date
                                  </label>
                                  <DatePicker
                                    id="dpPriceDt"
                                    value={paramstaticobj[0][11].PriceDate}
                                    calendar={CustomCalendar}
                                    min={new Date("1/1/1000")}
                                    max={maxFrmDt}
                                    onChange={(e) =>
                                      handlechnagePriceDate(e, 11)
                                    }
                                    style={{ width: "100%" }}
                                    validationMessage={
                                      paramstaticobj[0][11].PriceDate == null
                                        ? "Enter a valid date"
                                        : paramstaticobj[0][11].PriceDate <
                                          new Date("1/1/1000")
                                        ? "Enter a date in the correct format (mm/dd/yyyy)"
                                        : paramstaticobj[0][11].PriceDate >
                                          maxFrmDt
                                        ? "Price Date cannot exceed the Processing Date"
                                        : ""
                                    }
                                  />
                                </div>
                              )}

                              <hr></hr>

                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      paramstaticobj[0][11].ShowExcludeAsset
                                    }
                                    onChange={(e) =>
                                      handleExcluddedAsset(e, 11)
                                    }
                                    name="chkCtr"
                                  />
                                }
                                label="Show Excluded Assets"
                                style={{
                                  marginBottom: "10px",
                                  display: "block",
                                }}
                              />

                              {AcctId == 0 && (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][11].RunInAggregate
                                      }
                                      onChange={(e) =>
                                        handlerunInAggregate(e, 11)
                                      }
                                      name="chkCtr"
                                    />
                                  }
                                  label="Run In Aggregate."
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              )}
                            </div>
                          )}
                          {option.id === 12 && (
                            <div>
                              <h6>As Of Date</h6>
                              <DatePicker
                                id="dpFrm"
                                value={paramstaticobj[0][12].AsOfDate}
                                format="MM/dd/yyyy"
                                calendar={CustomCalendar}
                                // min={minFrmDt}
                                // formatPlaceholder={{
                                //   year: "yyyy",
                                //   month: "mm",
                                //   day: "dd",
                                // }}
                                width={300}
                                max={maxFrmDt}
                                //disabled={isDisabled}
                                onChange={(e) => handlechnageAsOfDate(e, 12)}
                              />

                              <hr></hr>
                              {/* Pricing Options */}
                              <h6>Price Selection</h6>
                              <div style={{ marginBottom: "20px" }}>
                                {[1, 2, 3].map((option) => (
                                  <label
                                    key={option}
                                    style={{
                                      display: "block",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      value={option}
                                      checked={
                                        paramstaticobj[0][12].PriceFlag ===
                                        option
                                      }
                                      onChange={(e) => {
                                        handleRadioChangeAcPr(e, 12);
                                      }}
                                    />
                                    {option === 1
                                      ? " Use Current Price"
                                      : option === 2
                                      ? " Use End of Month Price"
                                      : " Use Non EOM Price"}
                                  </label>
                                ))}
                              </div>
                              {/* Conditional Price Date Field */}
                              {paramstaticobj[0][12].PriceFlag === 3 && (
                                <div style={{ marginBottom: "20px" }}>
                                  <label
                                    htmlFor="dpPriceDt"
                                    className="form-label"
                                    style={{
                                      display: "block",
                                      fontWeight: "bold",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    From Price Date
                                  </label>
                                  <DatePicker
                                    id="dpPriceDt"
                                    value={paramstaticobj[0][12].PriceDate}
                                    // calendar={CustomCalendar}
                                    min={new Date("1/1/1000")}
                                    max={maxFrmDt}
                                    width={300}
                                    onChange={(e) =>
                                      handlechnagePriceDate(e, 12)
                                    }
                                    style={{ width: "100%" }}
                                  />
                                </div>
                              )}

                              <hr></hr>
                              <h6>Investment Model</h6>
                              <DropDownList
                                style={{
                                  width: "320px",
                                }}
                                data={assetAllocModel}
                                textField="modelDesc"
                                //valueField="modelId"
                                dataItemKey="modelId"
                                filterable={true}
                                disabled={paramstaticobj[0][12].InvMix}
                                //defaultItem={initialModelDropdown}
                                value={selChangeModel}
                                onChange={(e) => handleChangeAllocModel(e)}
                                skipDisabledItems={true}
                              />

                              {/* Miscellaneous Section */}
                              <h6>Miscellaneous Section</h6>
                              <div style={{ marginBottom: "20px" }}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][12].ShowExcludeAsset
                                      }
                                      onChange={(e) =>
                                        handleExcluddedAsset(e, 12)
                                      }
                                      name="excludeAsset"
                                    />
                                  }
                                  label=" Show Excluded Asset"
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              </div>
                              <div style={{ marginBottom: "20px" }}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={paramstaticobj[0][12].InvMix}
                                      onChange={(e) =>
                                        handleChangeInvmix(e, 12)
                                      }
                                    />
                                  }
                                  label=" Compare against Investment Target Mix"
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              </div>

                              {AcctId == 0 && (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][12].RunInAggregate
                                      }
                                      onChange={(e) =>
                                        handlerunInAggregate(e, 12)
                                      }
                                      name="chkCtr"
                                    />
                                  }
                                  label="Run In Aggregate."
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              )}
                            </div>
                          )}
                          {option.id === 13 && (
                            <div>
                              <h6>Trade Date</h6>
                              <div className="col">
                                <span className="py-1">Start Date</span>

                                <div className="text-start">
                                  <DatePicker
                                    id="dpFrm"
                                    value={paramstaticobj[0][13].StartDate}
                                    // Value={StartDt}
                                    format="MM/dd/yyyy"
                                    calendar={CustomCalendar}
                                    formatPlaceholder={{
                                      year: "yyyy",
                                      month: "mm",
                                      day: "dd",
                                    }}
                                    onChange={(e) => {
                                      handleChangeStartDate(e, 13);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="text-start">
                                  <span className="py-1">End Date</span>

                                  <DatePicker
                                    value={paramstaticobj[0][13].EndDate}
                                    // Value={EndDt}

                                    format="MM/dd/yyyy"
                                    calendar={CustomCalendar}
                                    formatPlaceholder={{
                                      year: "yyyy",

                                      month: "mm",

                                      day: "dd",
                                    }}
                                    // max={
                                    //   new Date(
                                    //     `${BusnsDt.getFullYear()},${(BusnsDt.getMonth() + 1)
                                    //       .toString()
                                    //       .padStart(2, "0")},${BusnsDt.getDate()
                                    //       .toString()
                                    //       .padStart(2, "0")}`
                                    //   )
                                    // }
                                    // min={minFrmDt}
                                    // max={maxFrmDt}
                                    onChange={(e) => {
                                      handleChangeEndDate(e, 13);
                                    }}
                                  />
                                </div>
                              </div>

                              <hr style={{ margin: "20px 0" }} />

                              <h6>Trade Type Selection</h6>

                              <div>
                                <label>
                                  <input
                                    type="checkbox"
                                    name="stagedTrades"
                                    checked={selectedOptions.stagedTrades}
                                    onChange={handleChangeIncludeOrders}
                                  />
                                  &nbsp;&nbsp;Staged Trades
                                </label>
                              </div>
                              <div>
                                <label>
                                  <input
                                    type="checkbox"
                                    name="pendingTrades"
                                    checked={selectedOptions.pendingTrades}
                                    onChange={handleChangeIncludeOrders}
                                  />
                                  &nbsp;&nbsp;Pending Trades
                                </label>
                              </div>
                              {paramstaticobj[0][13].IncludeOrders === 0 && (
                                <p style={{ color: "red" }}>
                                  Please select any trade type.
                                </p>
                              )}

                              <hr style={{ margin: "20px 0" }} />
                              <h6>Miscellaneous Section</h6>

                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      paramstaticobj[0][13].MutualFundInd
                                    }
                                    onChange={(e) =>
                                      handleChangeMutualFund(e, 13)
                                    }
                                    name="chkCtr"
                                  />
                                }
                                label="Include Mutual Fund Trades"
                                style={{
                                  marginBottom: "10px",
                                  display: "block",
                                }}
                              />

                              {AcctId == 0 && (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][13].RunInAggregate
                                      }
                                      onChange={(e) =>
                                        handlerunInAggregate(e, 13)
                                      }
                                      name="chkCtr"
                                    />
                                  }
                                  label="Run In Aggregate."
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              )}
                            </div>
                          )}
                          {option.id === 14 && (
                            <div>
                              <h6>Date Selection</h6>

                              <div className="col">
                                <span className="py-1">Current Date</span>
                                <div className="text-start">
                                  <DatePicker
                                    id="dpFrm"
                                    value={paramstaticobj[0][14].currDt}
                                    calendar={CustomCalendar}
                                    max={maxFrmDt}
                                    min={new Date("1/1/1000")}
                                    onChange={(e) => {
                                      handleChangeCurrDt(e, 14);
                                    }}
                                    // valid={CurrDt != null && CurrDt <= maxFrmDt}
                                    // validationMessage={
                                    //   CurrDt == null
                                    //     ? "Enter Valid Date"
                                    //     : CurrDt < new Date("1/1/1000")
                                    //     ? "Enter date in correct format (mm/dd/yyyy)"
                                    //     : CurrDt > maxFrmDt
                                    //     ? "Current Date cannot exceed the processing date"
                                    //     : ""
                                    // }
                                  />
                                </div>
                              </div>

                              <div className="col">
                                <div className="text-start">
                                  <span className="py-1">Snapshot Date</span>
                                  <DatePicker
                                    defaultValue={paramstaticobj[0][14].snapDt}
                                    calendar={CustomCalendar}
                                    max={maxFrmDt}
                                    onChange={(e) => {
                                      handleChangeSnapDt(e, 14);
                                    }}
                                    // valid={
                                    //   SnpDate != null && SnpDate <= maxFrmDt
                                    // }
                                    // validationMessage={
                                    //   SnpDate == null
                                    //     ? "Enter Valid Date"
                                    //     : SnpDate < new Date("1/1/1000")
                                    //     ? "Enter date in correct format (mm/dd/yyyy)"
                                    //     : SnpDate > maxFrmDt
                                    //     ? "Snapshot Date cannot exceed the processing date"
                                    //     : ""
                                    // }
                                  />
                                </div>
                              </div>

                              {/* Current Portfolio Price Options */}
                              <h6 className="pt-2">
                                Current Portfolio Price Selection
                              </h6>
                              <div style={{ marginBottom: "20px" }}>
                                {[1, 2, 3].map((option) => (
                                  <label
                                    key={option}
                                    style={{
                                      display: "block",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      value={option}
                                      checked={
                                        paramstaticobj[0][14]
                                          .PortfolioPriceFlag === option
                                      }
                                      onChange={(e) =>
                                        handleCurrentPortPriceFlag(e, 14)
                                      }
                                    />
                                    {option === 1
                                      ? " Use Current Price"
                                      : option === 2
                                      ? " Use End of Month Price"
                                      : " Use Non EOM Price"}
                                  </label>
                                ))}
                              </div>

                              {/* Conditional Price Date Field */}
                              {paramstaticobj[0][14].PortfolioPriceFlag ===
                                3 && (
                                <div style={{ marginBottom: "20px" }}>
                                  <label
                                    htmlFor="dpPriceDt"
                                    className="form-label"
                                    style={{
                                      display: "block",
                                      fontWeight: "bold",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    Price Date
                                  </label>
                                  <DatePicker
                                    id="dpPriceDt"
                                    value={
                                      paramstaticobj[0][14].PortfolioPriceDt
                                    }
                                    calendar={CustomCalendar}
                                    min={new Date("1/1/1000")}
                                    max={maxFrmDt}
                                    onChange={(e) =>
                                      handleCurrentPriceDate(e, 14)
                                    }
                                    style={{ width: "100%" }}
                                    // validationMessage={
                                    //   currPrice == null
                                    //     ? "Enter a valid date"
                                    //     : currPrice < new Date("1/1/1000")
                                    //     ? "Enter a date in the correct format (mm/dd/yyyy)"
                                    //     : currPrice > maxFrmDt
                                    //     ? "Price Date cannot exceed the Processing Date"
                                    //     : ""
                                    // }
                                  />
                                </div>
                              )}

                              <hr></hr>

                              {/* Snapshot Price Options */}
                              <h6 className="pt-2">Snapshot Price Selection</h6>
                              <div style={{ marginBottom: "20px" }}>
                                {[1, 2, 3].map((option) => (
                                  <label
                                    key={option}
                                    style={{
                                      display: "block",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      value={option}
                                      checked={
                                        paramstaticobj[0][14].SnapPriceFlag ===
                                        option
                                      }
                                      onChange={(e) =>
                                        handleSnapPriceFlag(e, 14)
                                      }
                                    />
                                    {option === 1
                                      ? " Use Current Price"
                                      : option === 2
                                      ? " Use End of Month Price"
                                      : " Use Non EOM Price"}
                                  </label>
                                ))}
                              </div>

                              {/* Conditional Price Date Field */}
                              {paramstaticobj[0][14].SnapPriceFlag === 3 && (
                                <div style={{ marginBottom: "20px" }}>
                                  <label
                                    htmlFor="dpPriceDt"
                                    className="form-label"
                                    style={{
                                      display: "block",
                                      fontWeight: "bold",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    Snapshot Price Date
                                  </label>
                                  <DatePicker
                                    id="dpPriceDt"
                                    value={paramstaticobj[0][14].SnapPriceDt}
                                    // calendar={CustomCalendar}
                                    min={new Date("1/1/1000")}
                                    max={maxFrmDt}
                                    onChange={(e) => handleSnapPriceDate(e, 14)}
                                    style={{ width: "100%" }}
                                    // validationMessage={
                                    //   snapPrice == null
                                    //     ? "Enter a valid date"
                                    //     : snapPrice < new Date("1/1/1000")
                                    //     ? "Enter a date in the correct format (mm/dd/yyyy)"
                                    //     : snapPrice > maxFrmDt
                                    //     ? "Price Date cannot exceed the Processing Date"
                                    //     : ""
                                    // }
                                  />
                                </div>
                              )}

                              <hr></hr>

                              <h6>Other Selections</h6>
                              <label>Display Selections : </label>
                              <br />
                              <br />
                              <div>
                                <FormControl fullWidth>
                                  <InputLabel>Asset Type</InputLabel>
                                  <Select
                                    value={
                                      paramstaticobj[0][14].GrpByMnrAst.value
                                    }
                                    label="Asset Type"
                                    onChange={(e) =>
                                      handleChangeGrpByMnrAsst(e, 14)
                                    }
                                  >
                                    {options.map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.text}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                              {/* Miscellaneous Section */}
                              <h6>Miscellaneous Section</h6>
                              <div style={{ marginBottom: "20px" }}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][14].ShowExcludeAsset
                                      }
                                      onChange={(e) =>
                                        handleExcluddedAsset(e, 14)
                                      }
                                      name="excludeAsset"
                                    />
                                  }
                                  label=" Show Excluded Assets"
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              </div>
                              {AcctId == 0 && (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][14].RunInAggregate
                                      }
                                      onChange={(e) =>
                                        handlerunInAggregate(e, 14)
                                      }
                                      name="chkCtr"
                                    />
                                  }
                                  label="Run In Aggregate."
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              )}
                            </div>
                          )}
                          {option.id === 15 && (
                            <div>
                              <div className="pt-3">
                                <span>
                                  <h6>From Date</h6>
                                </span>
                                <DatePicker
                                  id="dpFrm"
                                  value={paramstaticobj[0][15].StartDate}
                                  // calendar={CustomCalendar}
                                  // min={minFrmDt}
                                  // max={maxFrmDt}
                                  // disabled={isDisabled}
                                  style={{ width: "100%" }}
                                  // valid={
                                  //   frmDate >= minFrmDt &&
                                  //   frmDate <= maxFrmDt &&
                                  //   frmDate != null
                                  // }
                                  onChange={(e) => handleChangeStartDate(e, 15)}
                                  // validationMessage={
                                  //   frmDate == null
                                  //     ? "Enter Valid Date"
                                  //     : frmDate < new Date("1/1/1000")
                                  //     ? "Enter date in correct format (mm/dd/yyyy)"
                                  //     : frmDate > maxFrmDt
                                  //     ? "From date cannot exceed one year from Processing date."
                                  //     : frmDate > toDate
                                  //     ? "From date cannot exceed to date."
                                  //     : frmDate < minFrmDt
                                  //     ? "From date cannot be less than Processing date."
                                  //     : "Validation error"
                                  // }
                                />
                              </div>
                              <div className="pt-3">
                                <span>
                                  <h6>To Date</h6>
                                </span>
                                <DatePicker
                                  id="dpTo"
                                  value={paramstaticobj[0][15].EndDate}
                                  // format="MM/dd/yyyy"
                                  // calendar={CustomCalendar}
                                  // min={minFrmDt}
                                  // max={maxFrmDt}
                                  disabled={false}
                                  onChange={(e) => {
                                    handleChangeEndDate(e, 15);
                                  }}
                                  style={{ width: "100%" }}
                                  // valid={
                                  //   frmDate <= toDate &&
                                  //   toDate <= maxFrmDt &&
                                  //   toDate >= minFrmDt
                                  // }
                                  // validationMessage={
                                  //   toDate == null
                                  //     ? "Enter Valid Date"
                                  //     : toDate < new Date("1/1/1000")
                                  //     ? "Enter date in correct format (mm/dd/yyyy)"
                                  //     : toDate > maxFrmDt
                                  //     ? "To date cannot exceed one year from Processing date."
                                  //     : toDate < frmDate
                                  //     ? "To date cannot be less than from date"
                                  //     : toDate < minFrmDt
                                  //     ? "To date cannot be less than Processing date."
                                  //     : "Validation error"
                                  // }
                                />
                              </div>
                            </div>
                          )}
                          {option.id === 16 && (
                            <div>
                              <div style={{ marginBottom: "20px" }}>
                                <label
                                  htmlFor="dpFrm"
                                  className="form-label"
                                  style={{
                                    display: "block",
                                    fontWeight: "bold",
                                    marginBottom: "5px",
                                  }}
                                >
                                  From
                                </label>
                                <DatePicker
                                  id="dpFrm"
                                  value={paramstaticobj[0][16].StartDate}
                                  // calendar={CustomCalendar}
                                  // min={minFrmDt}
                                  // max={maxFrmDt}
                                  disabled={isDisabled}
                                  style={{ width: "100%" }}
                                  // valid={
                                  //   frmDate >= minFrmDt && frmDate <= maxFrmDt && frmDate != null
                                  // }
                                  onChange={(e) => handleChangeStartDate(e, 16)}
                                  // validationMessage={
                                  //   frmDate == null
                                  //     ? "Enter Valid Date"
                                  //     : frmDate < new Date("1/1/1000")
                                  //     ? "Enter date in correct format (mm/dd/yyyy)"
                                  //     : frmDate > maxFrmDt
                                  //     ? "From date cannot exceed one year from Processing date."
                                  //     : frmDate > toDate
                                  //     ? "From date cannot exceed to date."
                                  //     : frmDate < minFrmDt
                                  //     ? "From date cannot be less than Processing date."
                                  //     : "Validation error"
                                  // }
                                />
                              </div>

                              <div style={{ marginBottom: "20px" }}>
                                <label
                                  htmlFor="dpTo"
                                  className="form-label"
                                  style={{
                                    display: "block",
                                    fontWeight: "bold",
                                    marginBottom: "5px",
                                  }}
                                >
                                  To
                                </label>
                                <DatePicker
                                  id="dpTo"
                                  value={paramstaticobj[0][16].EndDate}
                                  // format="MM/dd/yyyy"
                                  // calendar={CustomCalendar}
                                  // min={minFrmDt}
                                  // max={maxFrmDt}
                                  disabled={false}
                                  onChange={(e) => {
                                    handleChangeEndDate(e, 16);
                                  }}
                                  style={{ width: "100%" }}
                                  // valid={
                                  //   frmDate <= toDate && toDate <= maxFrmDt && toDate >= minFrmDt
                                  // }
                                  // validationMessage={
                                  //   toDate == null
                                  //     ? "Enter Valid Date"
                                  //     : toDate < new Date("1/1/1000")
                                  //     ? "Enter date in correct format (mm/dd/yyyy)"
                                  //     : toDate > maxFrmDt
                                  //     ? "To date cannot exceed one year from Processing date."
                                  //     : toDate < frmDate
                                  //     ? "To date cannot be less than from date"
                                  //     : toDate < minFrmDt
                                  //     ? "To date cannot be less than Processing date."
                                  //     : "Validation error"
                                  // }
                                />
                              </div>

                              {AcctId == 0 && (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][16].RunInAggregate
                                      }
                                      onChange={(e) =>
                                        handlerunInAggregate(e, 16)
                                      }
                                      name="chkCtr"
                                    />
                                  }
                                  label="Run In Aggregate."
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              )}
                            </div>
                          )}
                          {option.id === 17 && (
                            <div>
                              {/* AsOf Date */}
                              <h6>As Of Date</h6>
                              <DatePicker
                                id="dpFrm"
                                defaultValue={paramstaticobj[0][17].AsOfDate}
                                format="MM/dd/yyyy"
                                calendar={CustomCalendar}
                                // min={minFrmDt}
                                formatPlaceholder={{
                                  year: "yyyy",
                                  month: "mm",
                                  day: "dd",
                                }}
                                width={300}
                                // max={maxFrmDt}
                                //disabled={isDisabled}
                                onChange={(e) => {
                                  handlechnageAsOfDate(e, 17);
                                }}
                              />
                              <hr></hr>
                              <DropDownList
                                style={{
                                  width: "300px",
                                }}
                                label="Display Results By:"
                                data={displaytype}
                                textField="typeNm"
                                valueField="typeId"
                                dataItemKey="typeId"
                                //filterable={false}
                                //disabled={invMixVal}
                                //defaultItem={initialModelDropdown}
                                value={paramstaticobj[0][17].ShwAssetType}
                                onChange={handleChangeAssetType}
                              />
                              <hr></hr>
                              {/* Pricing Options */}
                              <h6>Price Selection</h6>
                              <div style={{ marginBottom: "20px" }}>
                                {[1, 2, 3].map((option) => (
                                  <label
                                    key={option}
                                    style={{
                                      display: "block",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      value={option}
                                      checked={
                                        paramstaticobj[0][17].PriceFlag ===
                                        option
                                      }
                                      onChange={(e) =>
                                        handleRadioChangeAcPr(e, 17)
                                      }
                                    />
                                    {option === 1
                                      ? " Use Current Price"
                                      : option === 2
                                      ? " Use End of Month Price"
                                      : " Use Non EOM Price"}
                                  </label>
                                ))}
                              </div>
                              {/* Conditional Price Date Field */}
                              {paramstaticobj[0][17].PriceFlag === 3 && (
                                <div style={{ marginBottom: "20px" }}>
                                  <label
                                    htmlFor="dpPriceDt"
                                    className="form-label"
                                    style={{
                                      display: "block",
                                      fontWeight: "bold",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    From Price Date
                                  </label>
                                  <DatePicker
                                    id="dpPriceDt"
                                    value={paramstaticobj[0][17].PriceDate}
                                    calendar={CustomCalendar}
                                    min={new Date("1/1/1000")}
                                    max={maxFrmDt}
                                    width={300}
                                    onChange={(e) =>
                                      handlechnagePriceDate(e, 17)
                                    }
                                    style={{ width: "100%" }}
                                  />
                                </div>
                              )}
                              <hr></hr>
                              {/* Miscellaneous Section */}
                              <h6>Miscellaneous Selection</h6>
                              <Row>
                                <div>
                                  {[1, 2].map((option) => (
                                    <label
                                      key={option}
                                      style={{
                                        display: "block",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        value={option}
                                        checked={
                                          paramstaticobj[0][17].BandYearFlag ===
                                          option
                                        }
                                        onChange={(e) =>
                                          handlebandYrFlag(e, 17)
                                        }
                                      />
                                      {option === 1
                                        ? " Maturity Bands"
                                        : " Maturity Years"}
                                    </label>
                                  ))}
                                </div>
                                <div>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={
                                          paramstaticobj[0][17].ShowExcludeAsset
                                        }
                                        onChange={(e) =>
                                          handleExcluddedAsset(e, 17)
                                        }
                                        name="excludeAsset"
                                      />
                                    }
                                    label="Show Excluded Assets"
                                    style={{
                                      marginBottom: "10px",
                                      display: "block",
                                    }}
                                  />
                                </div>
                              </Row>
                              <div>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][17].ShwPrpsdTrds
                                      }
                                      onChange={(e) => handleshwPrpsdTrd(e, 17)}
                                      name="excludeAsset"
                                    />
                                  }
                                  label="Display Staged Trades"
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              </div>
                              <div>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][17].ShwBondByIndSct
                                      }
                                      onChange={(e) =>
                                        handleshwBondByIndSctr(e, 17)
                                      }
                                      name="excludeAsset"
                                    />
                                  }
                                  label="Display Bonds By Industry Sector"
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              </div>
                              {AcctId == 0 && (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][17].RunInAggregate
                                      }
                                      onChange={(e) =>
                                        handlerunInAggregate(e, 17)
                                      }
                                      name="chkCtr"
                                    />
                                  }
                                  label="Run In Aggregate."
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              )}
                            </div>
                          )}
                          {option.id === 18 && (
                            <div>
                              {/* As Of Date */}
                              <div style={{ marginBottom: "20px" }}>
                                <label
                                  htmlFor="dpAsOfDt"
                                  className="form-label"
                                  style={{
                                    display: "block",
                                    fontWeight: "bold",
                                    marginBottom: "5px",
                                  }}
                                >
                                  As Of Date
                                </label>
                                <DatePicker
                                  id="dpAsOfDt"
                                  value={paramstaticobj[0][18].AsOfDate}
                                  calendar={CustomCalendar}
                                  min={new Date("1/1/1000")}
                                  max={maxFrmDt}
                                  onChange={(e) => handlechnageAsOfDate(e, 18)}
                                  style={{ width: "100%" }}
                                  validationMessage={
                                    asOfDt == null
                                      ? "Enter a valid date"
                                      : asOfDt < new Date("1/1/1000")
                                      ? "Enter a date in the correct format (mm/dd/yyyy)"
                                      : asOfDt > maxFrmDt
                                      ? "As Of Date cannot exceed the Processing Date"
                                      : ""
                                  }
                                />
                              </div>

                              <hr style={{ margin: "20px 0" }} />

                              <label
                                htmlFor="dpAsOfDt"
                                className="form-label"
                                style={{
                                  display: "block",
                                  fontWeight: "bold",
                                  marginBottom: "5px",
                                }}
                              >
                                Maturity Date Selection
                              </label>

                              <div style={{ marginBottom: "20px" }}>
                                <label
                                  htmlFor="dpFrm"
                                  className="form-label"
                                  style={{
                                    display: "block",
                                    fontWeight: "bold",
                                    marginBottom: "5px",
                                  }}
                                >
                                  From
                                </label>
                                <DatePicker
                                  id="dpFrm"
                                  value={paramstaticobj[0][18].StartDate}
                                  calendar={CustomCalendar}
                                  min={minFrmDt}
                                  max={maxFrmDt}
                                  // disabled={isDisabled}
                                  style={{ width: "100%" }}
                                  // valid={
                                  //   frmDate >= minFrmDt && frmDate <= maxFrmDt && frmDate != null
                                  // }
                                  onChange={(e) => handleChangeStartDate(e, 18)}
                                  // validationMessage={
                                  //   frmDate == null
                                  //     ? "Enter Valid Date"
                                  //     : frmDate < new Date("1/1/1000")
                                  //     ? "Enter date in correct format (mm/dd/yyyy)"
                                  //     : frmDate > maxFrmDt
                                  //     ? "From date cannot exceed one year from Processing date."
                                  //     : frmDate > toDate
                                  //     ? "From date cannot exceed to date."
                                  //     : frmDate < minFrmDt
                                  //     ? "From date cannot be less than Processing date."
                                  //     : "Validation error"
                                  // }
                                />
                              </div>

                              <div style={{ marginBottom: "20px" }}>
                                <label
                                  htmlFor="dpTo"
                                  className="form-label"
                                  style={{
                                    display: "block",
                                    fontWeight: "bold",
                                    marginBottom: "5px",
                                  }}
                                >
                                  To
                                </label>
                                <DatePicker
                                  id="dpTo"
                                  value={paramstaticobj[0][18].EndDate}
                                  format="MM/dd/yyyy"
                                  calendar={CustomCalendar}
                                  min={minFrmDt}
                                  max={maxFrmDt}
                                  disabled={false}
                                  onChange={(e) => {
                                    handleChangeEndDate(e, 18);
                                  }}
                                  style={{ width: "100%" }}
                                  // valid={
                                  //   frmDate <= toDate && toDate <= maxFrmDt && toDate >= minFrmDt
                                  // }
                                  validationMessage={
                                    toDate == null
                                      ? "Enter Valid Date"
                                      : toDate < new Date("1/1/1000")
                                      ? "Enter date in correct format (mm/dd/yyyy)"
                                      : toDate > maxFrmDt
                                      ? "To date cannot exceed one year from Processing date."
                                      : toDate < frmDate
                                      ? "To date cannot be less than from date"
                                      : toDate < minFrmDt
                                      ? "To date cannot be less than Processing date."
                                      : "Validation error"
                                  }
                                />
                              </div>
                              <hr></hr>

                              {/* Pricing Options */}
                              <h6>Select Pricing Option</h6>
                              <div style={{ marginBottom: "20px" }}>
                                {[1, 2, 3].map((option) => (
                                  <label
                                    key={option}
                                    style={{
                                      display: "block",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      value={option}
                                      checked={
                                        paramstaticobj[0][18].PriceFlag ===
                                        option
                                      }
                                      onChange={(e) =>
                                        handleRadioChangeAcPr(e, 18)
                                      }
                                    />
                                    {option === 1
                                      ? " Use Current Price"
                                      : option === 2
                                      ? " Use End of Month Price"
                                      : " Use Non EOM Price"}
                                  </label>
                                ))}
                              </div>

                              {/* Conditional Price Date Field */}
                              {paramstaticobj[0][18].PriceFlag === 3 && (
                                <div style={{ marginBottom: "20px" }}>
                                  <label
                                    htmlFor="dpPriceDt"
                                    className="form-label"
                                    style={{
                                      display: "block",
                                      fontWeight: "bold",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    Price Date
                                  </label>
                                  <DatePicker
                                    id="dpPriceDt"
                                    value={paramstaticobj[0][18].PriceDate}
                                    calendar={CustomCalendar}
                                    min={new Date("1/1/1000")}
                                    max={maxFrmDt}
                                    onChange={(e) =>
                                      handlechnagePriceDate(e, 18)
                                    }
                                    style={{ width: "100%" }}
                                    validationMessage={
                                      priceDt == null
                                        ? "Enter a valid date"
                                        : priceDt < new Date("1/1/1000")
                                        ? "Enter a date in the correct format (mm/dd/yyyy)"
                                        : priceDt > maxFrmDt
                                        ? "Price Date cannot exceed the Processing Date"
                                        : ""
                                    }
                                  />
                                </div>
                              )}

                              <hr style={{ margin: "20px 0" }} />

                              {/* Miscellaneous Section */}
                              <h6>Miscellaneous Section</h6>

                              <div style={{ marginBottom: "20px" }}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][18].ShowExcludeAsset
                                      }
                                      onChange={(e) =>
                                        handleExcluddedAsset(e, 18)
                                      }
                                      name="excludeAsset"
                                    />
                                  }
                                  label=" Show Excluded Assets"
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              </div>
                              {AcctId == 0 && (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][18].RunInAggregate
                                      }
                                      onChange={(e) =>
                                        handlerunInAggregate(e, 18)
                                      }
                                      name="chkCtr"
                                    />
                                  }
                                  label="Run In Aggregate."
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              )}
                            </div>
                          )}
                          {option.id === 19 && (
                            <div>
                              <div className="pt-3">
                                <span>
                                  <h6>As Of Date</h6>
                                </span>

                                <DatePicker
                                  id="dpAsOfDate"
                                  value={paramstaticobj[0][19].AsOfDate}
                                  className="form-control form-control-sm"
                                  size={"small"}
                                  width={150}
                                  format="MM/dd/yyyy"
                                  // calendar={CustomCalendar}

                                  // min={minFrmDt}

                                  max={maxFrmDt}
                                  disabled={false}
                                  onChange={(e) => {
                                    handlechnageAsOfDate(e, 19);
                                  }}
                                />
                              </div>

                              <div>
                                <label>
                                  <input
                                    type="checkbox"
                                    name="chkCustomPeriod"
                                    checked={showCustomPeriod}
                                    onChange={handleCustomPeriod}
                                    //checked={selectedOptions.stagedTrades}
                                    // onChange={handleCheckboxChange}
                                  />
                                  &nbsp;&nbsp;Custom Period
                                </label>
                              </div>

                              <div className="pt-3">
                                <span>
                                  <h6>Start Date</h6>
                                </span>

                                <DatePicker
                                  id="dpFrm"
                                  value={paramstaticobj[0][19].StartDate}
                                  className="form-control form-control-sm"
                                  size={"small"}
                                  width={150}
                                  format="MM/dd/yyyy"
                                  // calendar={CustomCalendar}

                                  // min={minFrmDt}

                                  max={maxFrmDt}
                                  disabled={isDisabledCstPeriod}
                                  onChange={(e) => {
                                    handleChangeStartDate(e, 19);
                                  }}
                                  // validationMessage={
                                  //   frmDate == null
                                  //     ? "Enter Valid Date"
                                  //     : fromDate < new Date("1/1/1000")
                                  //     ? "Enter date in correct format (mm/dd/yyyy)"
                                  //     : fromDate > maxFrmDt
                                  //     ? "From date cannot exceed one year from Processing date."
                                  //     : fromDate > toDate
                                  //     ? "From date cannot exceed to date."
                                  //     : fromDate < minFrmDt
                                  //     ? "From date cannot be less than Processing date."
                                  //     : "Validation error"
                                  // }
                                />
                              </div>

                              <div className="pt-3">
                                <span>
                                  <h6>End Date</h6>
                                </span>

                                <DatePicker
                                  id="dpTo"
                                  value={paramstaticobj[0][19].EndDate}
                                  className="form-control form-control-sm"
                                  size={"small"}
                                  width={150}
                                  format="MM/dd/yyyy"
                                  // calendar={CustomCalendar}

                                  // min={minFrmDt}

                                  max={maxFrmDt}
                                  disabled={isDisabledCstPeriod}
                                  onChange={(e) => {
                                    handleChangeEndDate(e, 19);
                                  }}
                                  // valid={
                                  //   frmDate <= toDate &&
                                  //   toDate <= maxFrmDt &&
                                  //   toDate >= minFrmDt
                                  // }
                                  // validationMessage={
                                  //   toDate == null
                                  //     ? "Enter Valid Date"
                                  //     : toDate < new Date("1/1/1000")
                                  //     ? "Enter date in correct format (mm/dd/yyyy)"
                                  //     : toDate > maxFrmDt
                                  //     ? "To date cannot exceed one year from Processing date."
                                  //     : toDate < fromDate
                                  //     ? "To date cannot be less than from date"
                                  //     : toDate < minFrmDt
                                  //     ? "To date cannot be less than Processing date."
                                  //     : "Validation error"
                                  // }
                                />
                              </div>

                              {/* <hr></hr> */}
                              {AcctId == 0 && (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][19].RunInAggregate
                                      }
                                      onChange={(e) =>
                                        handlerunInAggregate(e, 19)
                                      }
                                      name="chkCtr"
                                    />
                                  }
                                  label="Run In Aggregate."
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              )}
                            </div>
                          )}
                          {option.id === 20 && (
                            <div>
                              {/* Miscellaneous Section */}
                              <h6>Data Display Option</h6>
                              <div style={{ marginBottom: "20px" }}>
                                <div style={{ padding: "20px" }}>
                                  <div style={{ marginBottom: "20px" }}>
                                    <label>
                                      <input
                                        type="number"
                                        value={
                                          paramstaticobj[0][20].HistYearCount
                                        }
                                        onChange={(e) =>
                                          handleNumberOfYearsChange(e)
                                        }
                                        // onBlur={validateNumberOfYears}
                                        min="1" // Enforces that the minimum value cannot be less than 1
                                      />{" "}
                                      Number of Years to Report
                                    </label>
                                  </div>
                                  {/* {validationMessage && (
                                      <div style={{ color: 'red', marginTop: '5px' }}>{validationMessage}</div>
                                    )} */}

                                  <div style={{ marginBottom: "20px" }}>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={
                                            paramstaticobj[0][20].OvrdIncptn
                                          }
                                          onChange={(e) =>
                                            handleNextBomInd(e, 20)
                                          }
                                          name="nextBom"
                                        />
                                      }
                                      label="Next BOM for Intra-Month Inception Dates"
                                      style={{
                                        marginBottom: "10px",
                                        display: "block",
                                      }}
                                    />

                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={
                                            paramstaticobj[0][20].NetFeeInd
                                          }
                                          onChange={(e) =>
                                            handleNetFeeInd(e, 20)
                                          }
                                          name="showNetOfFees"
                                        />
                                      }
                                      label="Show Net of Fees"
                                      style={{
                                        marginBottom: "10px",
                                        display: "block",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <hr style={{ margin: "20px 0" }} />

                              {/* As Of Date */}
                              <h6>Date Selection</h6>
                              <div style={{ marginBottom: "20px" }}>
                                <label
                                  htmlFor="dpAsOfDt"
                                  className="form-label"
                                  style={{
                                    display: "block",
                                    fontWeight: "bold",
                                    marginBottom: "5px",
                                  }}
                                >
                                  As Of Date
                                </label>
                                <DatePicker
                                  id="dpAsOfDt"
                                  value={paramstaticobj[0][20].AsOfDate}
                                  // calendar={CustomCalendar}
                                  min={new Date("1/1/1000")}
                                  max={maxFrmDt}
                                  // onChange={(e) => setasOfDt(e.value)}
                                  onChange={(e) => handlechnageAsOfDate(e, 20)}
                                  style={{ width: "100%" }}
                                  // valid={date <= maxFrmDt && date >= new Date("1/1/1000")}
                                  // validationMessage={
                                  //   asOfDt == null
                                  //     ? "Enter a valid date"
                                  //     : asOfDt < new Date("1/1/1000")
                                  //     ? "Enter a date in the correct format (mm/dd/yyyy)"
                                  //     : asOfDt > maxFrmDt
                                  //     ? "As Of Date cannot exceed the Processing Date"
                                  //     : ""
                                  // }
                                />
                              </div>
                              {AcctId == 0 && (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][20].RunInAggregate
                                      }
                                      onChange={(e) =>
                                        handlerunInAggregate(e, 20)
                                      }
                                      name="chkCtr"
                                    />
                                  }
                                  label="Run In Aggregate."
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              )}
                            </div>
                          )}
                          {option.id === 21 && (
                            <div>
                              {/* As Of Date */}
                              <div style={{ marginBottom: "20px" }}>
                                <label
                                  htmlFor="dpAsOfDt"
                                  className="form-label"
                                  style={{
                                    display: "block",
                                    fontWeight: "bold",
                                    marginBottom: "5px",
                                  }}
                                >
                                  As Of Date
                                </label>
                                <DatePicker
                                  id="dpAsOfDt"
                                  value={paramstaticobj[0][21].AsOfDate}
                                  calendar={CustomCalendar}
                                  min={new Date("1/1/1000")}
                                  max={maxFrmDt}
                                  onChange={(e) => handlechnageAsOfDate(e, 21)}
                                  style={{ width: "100%" }}
                                  // validationMessage={
                                  //   asOfDt == null
                                  //     ? "Enter a valid date"
                                  //     : asOfDt < new Date("1/1/1000")
                                  //     ? "Enter a date in the correct format (mm/dd/yyyy)"
                                  //     : asOfDt > maxFrmDt
                                  //     ? "As Of Date cannot exceed the Processing Date"
                                  //     : ""
                                  // }
                                />
                              </div>

                              {/* Report Display Option */}
                              <hr style={{ margin: "20px 0" }} />
                              <h6>Report Display Option</h6>
                              <div style={{ marginBottom: "20px" }}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={paramstaticobj[0][21].NetFeeInd}
                                      onChange={(e) => handleNetFeeInd(e, 21)}
                                      name="NetofFees"
                                    />
                                  }
                                  label="Show Net of Fees"
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              </div>
                              <div style={{ marginBottom: "20px" }}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={paramstaticobj[0][21].OvrdIncptn}
                                      onChange={(e) => handleNextBomInd(e, 21)}
                                      name="reportBOMInceptionDates"
                                    />
                                  }
                                  label="Use Next BOM for Intra-Month Inception Dates"
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              </div>
                              {AcctId == 0 && (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][21].RunInAggregate
                                      }
                                      onChange={(e) =>
                                        handlerunInAggregate(e, 21)
                                      }
                                      name="chkCtr"
                                    />
                                  }
                                  label="Run In Aggregate."
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              )}
                            </div>
                          )}
                          {option.id === 22 && (
                            <div>
                              <div style={{ marginBottom: "20px" }}>
                                <label
                                  htmlFor="dpFrm"
                                  className="form-label"
                                  style={{
                                    display: "block",
                                    fontWeight: "bold",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Start Date:
                                </label>
                                <DatePicker
                                  id="dpFrm"
                                  value={paramstaticobj[0][22].StartDate}
                                  // calendar={CustomCalendar}
                                  //min={minFrmDt}
                                  // max={endDt}
                                  disabled={isDisabled}
                                  style={{ width: "100%" }}
                                  valid={
                                    // 1. Check if start and end dates are the end of the month
                                    isEndOfMonth(
                                      paramstaticobj[0][22].StartDate
                                    ) &
                                    // 2. Start date cannot be greater than end date
                                    (paramstaticobj[0][22].StartDate <=
                                      paramstaticobj[0][22].EndDate) &
                                    // 3. Start date cannot be equal to end date
                                    (paramstaticobj[0][22].StartDate.getTime() !==
                                      paramstaticobj[0][22].EndDate.getTime())
                                  }
                                  onChange={(e) => handleChangeStartDate(e, 22)}
                                  validationMessage={
                                    !paramstaticobj[0][22].StartDate ||
                                    !paramstaticobj[0][22].EndDate
                                      ? "Both Start Date and End Date are required."
                                      : !isEndOfMonth(
                                          paramstaticobj[0][22].StartDate
                                        ) ||
                                        !isEndOfMonth(
                                          paramstaticobj[0][22].EndDate
                                        )
                                      ? "Start Date and End Date must be the end of the month."
                                      : paramstaticobj[0][22].StartDate >
                                        paramstaticobj[0][22].EndDate
                                      ? "Start Date cannot be greater than End Date."
                                      : paramstaticobj[0][22].EndDate >
                                        maxEndDate
                                      ? "End Date cannot be greater than the end of month of current (Processing) date."
                                      : paramstaticobj[0][22].StartDate.getTime() ===
                                        paramstaticobj[0][22].EndDate.getTime()
                                      ? "Start Date cannot be the same as End Date."
                                      : "Validation error"
                                  }
                                />
                              </div>

                              <div style={{ marginBottom: "20px" }}>
                                <label
                                  htmlFor="dpTo"
                                  className="form-label"
                                  style={{
                                    display: "block",
                                    fontWeight: "bold",
                                    marginBottom: "5px",
                                  }}
                                >
                                  End Date:
                                </label>
                                <DatePicker
                                  id="dpTo"
                                  value={paramstaticobj[0][22].EndDate}
                                  // format="MM/dd/yyyy"
                                  // calendar={CustomCalendar}
                                  //min={minFrmDt}
                                  max={maxEndDate}
                                  disabled={false}
                                  onChange={(e) => {
                                    handleChangeEndDate(e, 22);
                                  }}
                                  style={{ width: "100%" }}
                                  valid={
                                    // 1. Check if start and end dates are the end of the month
                                    isEndOfMonth(
                                      paramstaticobj[0][22].EndDate
                                    ) &
                                      // 2. Start date cannot be greater than end date
                                      (paramstaticobj[0][22].StartDate <=
                                        paramstaticobj[0][22].EndDate) &
                                      // 3. End date cannot be greater than processing date
                                      (paramstaticobj[0][22].EndDate <=
                                        maxEndDate) &&
                                    // 4. Start date cannot be equal to end date
                                    paramstaticobj[0][22].StartDate.getTime() !==
                                      paramstaticobj[0][22].EndDate.getTime()
                                  }
                                  validationMessage={
                                    !paramstaticobj[0][22].StartDate ||
                                    !paramstaticobj[0][22].EndDate
                                      ? "Both Start Date and End Date are required."
                                      : !isEndOfMonth(
                                          paramstaticobj[0][22].StartDate
                                        ) ||
                                        !isEndOfMonth(
                                          paramstaticobj[0][22].EndDate
                                        )
                                      ? "Start Date and End Date must be the end of the month."
                                      : paramstaticobj[0][22].StartDate >
                                        paramstaticobj[0][22].EndDate
                                      ? "Start Date cannot be greater than End Date."
                                      : paramstaticobj[0][22].EndDate >
                                        maxEndDate
                                      ? "End Date cannot be greater than the end of month of current (Processing) date."
                                      : paramstaticobj[0][22].StartDate.getTime() ===
                                        paramstaticobj[0][22].EndDate.getTime()
                                      ? "Start Date cannot be the same as End Date."
                                      : "Validation error"
                                  }
                                />
                              </div>
                              <hr></hr>
                              <h6>Display Option</h6>

                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      paramstaticobj[0][22].showAccountPortfolio
                                    }
                                    onChange={(e) =>
                                      handleChangeShowPortfolio(e, 22)
                                    }
                                    // disabled={true}
                                  />
                                }
                                label=" Show Total Portfolio"
                                style={{
                                  marginBottom: "10px",
                                  display: "block",
                                }}
                              />
                            </div>
                          )}
                          {option.id === 23 && (
                            <div>
                              <div style={{ marginBottom: "20px" }}>
                                <label
                                  htmlFor="dpAsOfDt"
                                  className="form-label"
                                  style={{
                                    display: "block",
                                    fontWeight: 600,
                                    marginBottom: "5px",
                                  }}
                                >
                                  From
                                </label>
                                <DatePicker
                                  id="dpFrm"
                                  value={paramstaticobj[0][23].StartDate}
                                  // format="MM/dd/yyyy"
                                  // calendar={CustomCalendar}
                                  // formatPlaceholder={{
                                  //   year: "yyyy",
                                  //   month: "mm",
                                  //   day: "dd",
                                  // }}
                                  //min={minFrmDt}
                                  // valid={frmDate <= date}
                                  // max={date}
                                  disabled={isDisabled}
                                  onChange={(e) => {
                                    handleChangeStartDate(e, 23);
                                  }}
                                  // validationMessage={
                                  //   frmDate == null
                                  //     ? "Enter Valid Date"
                                  //     : frmDate < new Date("1/1/1000")
                                  //     ? "Enter date in correct format (mm/dd/yyyy)"
                                  //     : frmDate > date
                                  //     ? "From date cannot exceed Processing date."
                                  //     : frmDate > toDate
                                  //     ? "From date cannot exceed to date."
                                  //     : frmDate < new Date("1/2/1000")
                                  //     ? "From date is not valid."
                                  //     : "Validation error"
                                  // }
                                />
                              </div>

                              <div style={{ marginBottom: "20px" }}>
                                <label
                                  htmlFor="dpAsOfDt"
                                  className="form-label"
                                  style={{
                                    display: "block",
                                    fontWeight: 600,
                                    marginBottom: "5px",
                                  }}
                                >
                                  To
                                </label>
                                <DatePicker
                                  //disabled={false}
                                  value={paramstaticobj[0][23].EndDate}
                                  max={date}
                                  // formatPlaceholder={{
                                  //   year: "yyyy",
                                  //   month: "mm",
                                  //   day: "dd",
                                  // }}
                                  // format="MM/dd/yyyy"
                                  // valid={frmDate <= toDate && toDate <= date && toDate != null}
                                  onChange={(e) => {
                                    handleChangeEndDate(e, 23);
                                  }}
                                  // calendar={CustomCalendar}
                                  // validationMessage={
                                  //   toDate == null
                                  //     ? "Enter Valid Date"
                                  //     : toDate < new Date("1/1/1000")
                                  //     ? "Enter date in correct format (mm/dd/yyyy)"
                                  //     : toDate > date
                                  //     ? "To date cannot exceed Processing date."
                                  //     : toDate < frmDate
                                  //     ? "To date cannot be less than from date"
                                  //     : toDate < new Date("1/2/1000")
                                  //     ? "To date is not valid."
                                  //     : "Validation error"
                                  // }
                                />
                              </div>
                              <hr></hr>
                              <h6>Report Display Selection</h6>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={paramstaticobj[0][23].NetOfFeesInd}
                                    onClick={(e) =>
                                      handleChangeNetOfFeeInd(e, 23)
                                    }
                                    name="chkCtr"
                                  />
                                }
                                label="Net Fees in the ROR Calculation."
                                style={{
                                  marginBottom: "10px",
                                  display: "block",
                                }}
                              />

                              <hr></hr>
                              <h6>Benchmark Selection</h6>
                              <div>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][23].DsplyBnchmrks
                                      }
                                      onChange={(e) =>
                                        handleBenchmarkDisplay(e, 23)
                                      }
                                      name="chkCtr"
                                    />
                                  }
                                  label="Show Benchmarks"
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                                <label>
                                  <input
                                    type="radio"
                                    value={1}
                                    checked={
                                      paramstaticobj[0][23].BlndBnchmrk == 1
                                    }
                                    onChange={(e) =>
                                      handleChangeShowBenchmark(e, 23)
                                    }
                                  />
                                  &nbsp;Show Blended Benchmarks
                                </label>
                                <br />
                                <label>
                                  <input
                                    type="radio"
                                    value={0}
                                    checked={
                                      paramstaticobj[0][23].BlndBnchmrk == 0
                                    }
                                    onChange={(e) =>
                                      handleChangeShowBenchmark(e, 23)
                                    }
                                  />
                                  &nbsp;Show Associated Benchmarks
                                </label>
                              </div>
                              {AcctId == 0 && (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][23].RunInAggregate
                                      }
                                      onChange={(e) =>
                                        handlerunInAggregate(e, 23)
                                      }
                                      name="chkCtr"
                                    />
                                  }
                                  label="Run In Aggregate."
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              )}
                            </div>
                          )}
                          {option.id === 24 && (
                            <div>
                              <div style={{ marginBottom: "20px" }}>
                                <label
                                  htmlFor="dpFrm"
                                  className="form-label"
                                  style={{
                                    display: "block",
                                    fontWeight: "bold",
                                    marginBottom: "5px",
                                  }}
                                >
                                  As Of Date
                                </label>
                                {/* <label className="form-check-label px-2">Date</label> */}
                                <DatePicker
                                  id="dpFrm"
                                  value={paramstaticobj[0][24].AsOfDate}
                                  className="form-control form-control-sm"
                                  size={"small"}
                                  width={150}
                                  format="MM/dd/yyyy"
                                  // max={maxFrmDt}
                                  // disabled={isDisabled}
                                  onChange={(e) => {
                                    debugger;
                                    handlechnageAsOfDate(e, 24);
                                  }}
                                  style={{ width: "100%" }}
                                  // valid={
                                  //   frmDate <= toDate && toDate <= maxFrmDt && toDate >= minFrmDt
                                  // }
                                  // validationMessage={
                                  //   asOfDate == null
                                  //     ? "Enter Valid Date"
                                  //     : asOfDate < new Date("1/1/1000")
                                  //     ? "Enter date in correct format (mm/dd/yyyy)"
                                  //     : asOfDate > maxFrmDt
                                  //     ? "To date cannot exceed one year from Processing date."
                                  //     : asOfDate < frmDate
                                  //     ? "To date cannot be less than from date"
                                  //     : asOfDate < minFrmDt
                                  //     ? "To date cannot be less than Processing date."
                                  //     : "Validation error"
                                  // }
                                />
                              </div>

                              <hr></hr>

                              <div style={{ marginBottom: "20px" }}>
                                <label
                                  htmlFor="dpFrm"
                                  className="form-label"
                                  style={{
                                    display: "block",
                                    fontWeight: "bold",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Benchmark List &nbsp;&nbsp;{" "}
                                  <BsFillInfoCircleFill
                                    title="Please select atleast one benchmark"
                                    color="#0384fc"
                                    size={15}
                                  />
                                </label>

                                <MultiSelect
                                  style={{
                                    //  zIndex: 99999,
                                    width: "400px",
                                  }}
                                  data={countries}
                                  itemRender={itemRender}
                                  autoClose={false}
                                  value={value}
                                  onChange={handleChangeBenchmark}
                                  tags={
                                    selected > 0
                                      ? [
                                          {
                                            text: `${selected} items selected`,
                                            data: [...value],
                                          },
                                        ]
                                      : []
                                  }
                                  suffix={() => (
                                    <>
                                      <InputSeparator orientation="vertical" />

                                      <InputSuffix orientation="horizontal">
                                        <span className="k-input-suffix-text">
                                          .com
                                        </span>
                                      </InputSuffix>
                                    </>
                                  )}
                                />
                              </div>

                              {AcctId == 0 && (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        paramstaticobj[0][24].RunInAggregate
                                      }
                                      onChange={(e) =>
                                        handlerunInAggregate(e, 24)
                                      }
                                      name="chkCtr"
                                    />
                                  }
                                  label="Run In Aggregate."
                                  style={{
                                    marginBottom: "10px",
                                    display: "block",
                                  }}
                                />
                              )}
                            </div>
                          )}
                          {option.id === 25 && (
                            <div>
                              <div className="pt-3">
                                <span>
                                  <h6>As of Date</h6>
                                </span>
                                <DatePicker
                                  id="dpFrm"
                                  value={paramstaticobj[0][25].AsOfDate}
                                  className="form-control form-control-sm"
                                  size={"small"}
                                  width={150}
                                  format="MM/dd/yyyy"
                                  // calendar={CustomCalendar}
                                  // min={minFrmDt}
                                  // max={maxFrmDt}
                                  // disabled={isDisabled}
                                  onChange={(e) => {
                                    handlechnageAsOfDate(e, 25);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      ),
                    }))}
                  />
                  <div className="d-flex justify-content-end mt-1 sticky-bottom bg-white p-1">
                    {newTemp ? (
                      <button
                        type="button"
                        onClick={handleBack}
                        className="btn btn-outline-primary me-3"
                      >
                        Back
                      </button>
                    ) : (
                      <Button
                        variant="outlined"
                        onClick={onClose}
                        className="btn btn-outline-primary me-3"
                      >
                        Cancel
                      </Button>
                    )}
                    <button
                      id="my-form"
                      type="submit"
                      onClick={formSubmit}
                      className="btn btn-primary"
                    >
                      {!newTemp ? "Open Template" : "Save Template"}
                    </button>
                  </div>
                </div>
              ) : (
                <Typography align="center" sx={{ mt: 4 }}>
                  Review and Print step content goes here.
                </Typography>
              )}
            </Box>
          ) : (
            <Loading />
          )}
        </Modal>
      )}
      {themes === "dark" ? (
        <style>
          {`
      .k-input-sm .k-input-inner, .k-picker-sm .k-input-inner {
  background-color: #292929;
  color: white;
}
  .k-input-solid {
   
    background-color: #414141 !important;
    color: white !important;
}
.k-input-sm .k-input-button, .k-input-sm .k-spinner-increase, .k-input-sm .k-spinner-decrease, .k-picker-sm .k-input-button, .k-picker-sm .k-spinner-increase, .k-picker-sm .k-spinner-decrease {
  
  background-color: #686868 !important;
  color: white  !important;
  
}
  .k-grid-footer {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-footer td, .k-grid-footer .k-table-td {
  color:white !important;
  background-color: #292929 !important;
}
.k-grid-toolbar {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-pager{
  background-color: #292929 !important;
  color: white !important;
}
      .k-calendar-td:hover{
color: rgb(34, 34, 34);
}
.k-calendar-infinite .k-calendar-header {
  
  background-color: #1d1d1d !important;
 color: rgb(255, 255, 255);
}

.k-calendar-infinite .k-calendar-view {
  
  background-color: rgb(46, 46, 46);
  
}
.k-calendar .k-calendar-caption, .k-calendar .k-calendar-view th, .k-calendar .k-calendar-view .k-calendar-th, .k-calendar .k-meta-header, .k-calendar .k-month-header {
  
  color: rgb(184, 184, 184);
}
.k-calendar-infinite .k-calendar-view .k-content.k-scrollable {
  
  background-color: 1d1d1d;
  color: rgb(255, 255, 255);
}
  .k-calendar   .k-focus
{

  background-color: #78e4ffa8 !important;

}
  .k-calendar   .k-focus
{
  background: radial-gradient(#ffffff, #000000e0) !important;
  
}
.k-calendar   .k-focus:hover
{
  background: #5665b9b9 !important;
  
}
.k-calendar .k-content.k-scrollable, .k-calendar .k-calendar-content.k-scrollable {
  background-color: rgba(0, 0, 0, 0);
  .k-grid-toolbar {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-pager{
  background-color: #292929 !important;
  color: white !important;
}
  .k-input-md .k-input-inner, .k-picker-md .k-input-inner {
    
    background-color: #4d4d4d !important;
}
    .k-input .k-input-button, .k-input .k-input-spinner .k-spinner-increase, .k-input .k-input-spinner .k-spinner-decrease, .k-picker .k-input-button, .k-picker .k-input-spinner .k-spinner-increase, .k-picker .k-input-spinner .k-spinner-decrease {
    
    background-color: #2b2b2b !important;
}
    .k-input-md .k-input-inner, .k-picker-md .k-input-inner {
   
    
    background-color: #3d3d3d !important;
    color: white !important;
}
    .k-list-filter {
   color: white !important;
    background-color: #292929 !important;
}
}`}
        </style>
      ) : (
        <></>
      )}
    </>
  );
};

export default CustomReportModal;
