
import './App.css';
import Login from './components/login';
import { ThemeProvider, createTheme } from '@mui/material';
//import "@progress/kendo-theme-fluent/dist/all.css";
import { Provider, useSelector } from 'react-redux';
import { selectTheme } from './features/theme/themeSlice';
import AdvisorSideBar from './advisorDb/advisorSidebar';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { Button, ConfigProvider, Input, Space, theme } from 'antd';

// function App() {
//   return (
//     <div className="App">
     
//    <AdvisorSideBar></AdvisorSideBar> 
  
//     </div>
//   );
// }
const getDesignTokens = (mode) => ({
  palette: {
    primary: {
      main:'#f5f5f5'
    },
    secondary: {
      main: '#1089e6',
    },
    error:{
      main:'#ed1c05'
    }
  },
});
const kendothemes = {
  dark: `../public/mmpdark/dist/css/mmpdark.css`,
  
};
const lightModeTheme = createTheme(getDesignTokens('light'));
const App = () => {
  
  const themes=useSelector(selectTheme);
  // const themes = {
  //   light: '@progress/mmplight/dist/css/mmplight.css',
  //   dark: '@progress/mmpdark/dist/css/mmpdark.css',
  // };
 
  
  
  const darkTheme = createTheme({
    
    palette: {
      mode: 'dark',
    },
  });
  const lightTheme = createTheme({
    overrides: {
      MuiCssBaseline: {
        "@global": {
          body: {
            scrollbarColor: "#6b6b6b #2b2b2b",
            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
              backgroundColor: "#2b2b2b",
            },
            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
              borderRadius: 8,
              backgroundColor: "#6b6b6b",
              minHeight: 24,
              border: "3px solid #2b2b2b",
            },
            "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
              backgroundColor: "red",
            },
            "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
              backgroundColor: "red",
            },
            "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#959595",
            },
            "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
              backgroundColor: "#2b2b2b",
            },
          },
        },
      },
    },
    palette: {
      mode: 'light',
    },
  });
  return (
    <>
    <ConfigProvider
    theme={themes==="light"?{
      // 1. Use dark algorithm
      algorithm: theme.defaultAlgorithm,
      
      // 2. Combine dark algorithm and compact algorithm
      // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
    }:{algorithm: theme.darkAlgorithm}}
  >
    <ThemeProvider theme={themes==="light"?lightModeTheme:darkTheme}>
    
       <Login />
      
     
       </ThemeProvider>
       </ConfigProvider>
     </>
  )
}

export default App;
