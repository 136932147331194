import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { weekInYear, addDays, addMonths } from "@progress/kendo-date-math";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import axios from "axios";
import React from "react";
import { Modal } from "antd";
import { Modal as BootModal } from "react-bootstrap";
import { formatNumber, formatDate } from "@telerik/kendo-intl";
import { useState, useEffect, useRef, useMemo } from "react";
import { ResponsiveContainer } from "recharts";
import { CustomColumnMenuNoGrp } from "./customColumnMenuNoGrp";
import { process } from "@progress/kendo-data-query";
import Loading from "./loading";
import { CustomCalendar } from "./customCalendar";
import { CustomColumnMenuNoGrpChkBox } from "./customColumnMenuNoGrpChkBox";
import {
  ExcelExport,
  ExcelExportColumnGroup,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { Button } from "@progress/kendo-react-buttons";
import { Button as BtnAntd, Dropdown, Menu, Space } from "antd";
import { FaFileExcel, FaFilePdf, FaChevronDown, FaCog } from "react-icons/fa";
import { useSelector } from "react-redux";
import { selectTheme } from "../features/theme/themeSlice";
import { Viewer } from "@grapecity/activereports-react";

import { ColorRing } from "react-loader-spinner";
import { MdFilterAltOff } from "react-icons/md";

const TransferGrid = ({ dashboardData }) => {
  const _export = useRef(null);
  const viewerRef = React.useRef();

  const [transferData, setTransferData] = useState(
    dashboardData.lstSchdlTransfer
  );
  const [showModal, setShowModal] = React.useState(false);

  const handleClose = () => setShowModal(false);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const themes = useSelector(selectTheme);
  const [data, setData] = useState(dashboardData.lstSchdlTransfer);
  const [DialogMsg, setDialogMsg] = useState("");
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [loading, setLoading] = useState(false);
  const [toDate, setToDate] = React.useState(
    addMonths(new Date(localStorage.getItem("processingDate")), 1)
  );
  const [triggerColSave, setTriggerColSave] = useState(0);
  const [clearing, setClearing] = useState(false);
  const [triggerClearfilter, setTriggerClearfilter] = useState(0);

  // const [toDate, setToDate] = React.useState(() => {
  //   const storedToDate = localStorage.getItem("toDate");
  //   if (storedToDate) {
  //     return new Date(storedToDate);
  //   } else {
  //     const processingDate = new Date(localStorage.getItem("processingDate"));
  //     return addMonths(processingDate, 1);
  //   }
  // });

  var date = new Date(localStorage.getItem("processingDate"));

  //date.setMonth(date.getMonth() - 1);
  const [frmDate, setfrmDate] = React.useState(date);

  // const [frmDate, setfrmDate] = useState(()=> {
  //   const storedFromDate = localStorage.getItem("fromDate");
  //   if(storedFromDate) {
  //     return new Date(storedFromDate);
  //   } else {
  //     const processingDate = new Date(localStorage.getItem("processingDate"));
  //     return processingDate;
  //   }
  // })

  const maxDt = addDays(new Date(localStorage.getItem("processingDate")), 365);
  const minDt = date;
  const [visible, setVisible] = React.useState(false);
  const [columnLatest, setColumnLatest] = React.useState(null);

  const toggleDialog = () => {
    setVisible(!visible);
  };
  const divRef = useRef(null);

  useEffect(() => {
    divRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const totalSum = (props) => {
    const field = props.field || "";
    const total = data
      .reduce((acc, current) => acc + current[field], 0)
      .toFixed(2);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, "##,#.00")}
      </td>
    );
  };

  const menuWithExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrpChkBox
          {...props}
          columns={stateColumns}
          data={
            JSON.parse(localStorage.getItem("TransferData")) != null
              ? JSON.parse(localStorage.getItem("TransferData"))
              : data
          }
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };

  const menuWithoutExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrp
          {...props}
          columns={stateColumns}
          data={
            JSON.parse(localStorage.getItem("TransferData")) != null
              ? JSON.parse(localStorage.getItem("TransferData"))
              : data
          }
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };

  const daterangeCheck = (e) => {
    e.preventDefault();
    console.log("Submit button press");
    if (frmDate == null || toDate == null) {
      setDialogMsg("Enter Valid Date");
      toggleDialog();

      setfrmDate(new Date(localStorage.getItem("processingDate")));
      setToDate(addMonths(new Date(localStorage.getItem("processingDate")), 1));
    } else {
      setStateColumns(defaultColumns);

      const validationRules = [
        {
          condition: frmDate > toDate,
          message: "From date cannot exceed to date.",
        },
        {
          condition: toDate > maxDt,
          message: "To date cannot exceed one year from Processing date.",
        },
        {
          condition: toDate < minDt,
          message: "To date cannot be less than Processing date.",
        },
        {
          condition: frmDate > maxDt,
          message: "From date cannot exceed one year from Processing date.",
        },
        {
          condition: frmDate < minDt,
          message: "From date cannot be less than Processing date.",
        },
      ];

      const messages = validationRules
        .filter((rule) => rule.condition)
        .map((rule) => rule.message);

      if (messages.length > 0) {
        setDialogMsg(messages.join("\n"));
        toggleDialog();

        // const prevFromDate = frmDate;
        // const prevToDate = toDate;

        // setfrmDate(prevFromDate);
        // setToDate(prevToDate);

        setfrmDate(new Date(localStorage.getItem("processingDate")));
        setToDate(
          addMonths(new Date(localStorage.getItem("processingDate")), 1)
        );
      }

      if (
        frmDate <= toDate &&
        toDate <= maxDt &&
        toDate >= minDt &&
        frmDate <= maxDt &&
        frmDate >= minDt
      )
        transacData();
    }
  };

  useEffect(() => {
    transacData();
  }, []);

  function setDate(val) {
    if (val == null) {
      setfrmDate(frmDate);
      // var newToDate = new Date(frmDate);
      // newToDate.setMonth(val.getMonth() + 12);
      // if (newToDate >= new Date(localStorage.getItem('processingDate'))) {
      //   setToDate(new Date(localStorage.getItem('processingDate')));
      // }
      // else {
      //   setToDate(newToDate);
      // }
    } else {
      setfrmDate(val);

      const validationRules = [
        {
          condition: frmDate > toDate,
          message: "From date cannot exceed to date.",
        },
        {
          condition: toDate > maxDt,
          message: "To date cannot exceed one year from Processing date.",
        },
        {
          condition: toDate < minDt,
          message: "To date cannot be less than Processing date.",
        },
        {
          condition: frmDate > maxDt,
          message: "From date cannot exceed one year from Processing date.",
        },
        {
          condition: frmDate < minDt,
          message: "From date cannot be less than Processing date.",
        },
      ];

      const messages = validationRules
        .filter((rule) => rule.condition)
        .map((rule) => rule.message);

      // if (!(messages.length > 0)) {
      //   localStorage.setItem("fromDate", val);
      // }

      // let dateFormat = "DD/MM/YYYY";
      // if((val, dateFormat, true).isValid) {
      // localStorage.setItem("fromDate", val)
      // }

      //var newToDate = new Date(val);
      //newToDate.setMonth(val.getMonth() + 12);
      // if (newToDate >= new Date(localStorage.getItem('processingDate'))) {
      //   setToDate(new Date(localStorage.getItem('processingDate')));
      // }
      // else {
      //setToDate(newToDate);
    }
  }
  function setTDate(val) {
    if (val == null) {
      setToDate(toDate);
      // var newStartDate = new Date(toDate);
      // newStartDate.setMonth(val.getMonth() - 12);
      // setfrmDate(newStartDate);
    } else {
      setToDate(val);

      const validationRules = [
        {
          condition: frmDate > toDate,
          message: "From date cannot exceed to date.",
        },
        {
          condition: toDate > maxDt,
          message: "To date cannot exceed one year from Processing date.",
        },
        {
          condition: toDate < minDt,
          message: "To date cannot be less than Processing date.",
        },
        {
          condition: frmDate > maxDt,
          message: "From date cannot exceed one year from Processing date.",
        },
        {
          condition: frmDate < minDt,
          message: "From date cannot be less than Processing date.",
        },
      ];

      const messages = validationRules
        .filter((rule) => rule.condition)
        .map((rule) => rule.message);

      // if (!(messages.length > 0)) {
      //   localStorage.setItem("toDate", val);
      // }
      // let dateFormat = "DD/MM/YYYY";
      // if ((val, dateFormat, true).isValid) {
      // localStorage.setItem("toDate", val);
      // }

      // var newStartDate = new Date(val);
      // newStartDate.setMonth(val.getMonth() - 12);
      // setfrmDate(newStartDate);
    }
  }
  const transacData = async () => {
    setLoading(true);
    let pMode =
      localStorage.getItem("pMode") === null
        ? 0
        : localStorage.getItem("pMode");
    let pConsId =
      localStorage.getItem("pConsId") === null
        ? 0
        : localStorage.getItem("pConsId");
    let pAcctId =
      localStorage.getItem("SelAcctId") === null
        ? 0
        : localStorage.getItem("SelAcctId");
    let token = tempToken;
    let UserId = JSON.parse(localStorage.getItem("userId")); // 78034;
    let RoleTypeId = localStorage.getItem("UserRoleData"); //2;
    let Mode;
    let CnsldtnId = pConsId;
    let AcctId = pAcctId;
    let FrmDt = formatDate(frmDate, "MM/dd/yyyy");
    let ToDt = formatDate(toDate, "MM/dd/yyyy");

    if (pMode == "Account") Mode = 1;
    else Mode = 0;
    let RltnshpCd =
      localStorage.getItem("pRltncd") == null ||
      localStorage.getItem("pRltncd") == undefined
        ? ""
        : localStorage.getItem("pRltncd");
    const postData = {
      UserId,
      RoleTypeId,
      CnsldtnId,
      AcctId,
      Mode,
      FrmDt,
      ToDt,
      RltnshpCd,
    };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    //setLoading(true);
    await axios
      .post("/AdvSchdlTransferGetByDt", postData, config)
      .then((response) => {
        //setLoadingView(false);
        setLoading(false);

        const rowData = response.data;

        //setRelationData(rowData.lstCons);

        // if (initialCall) {
        //     setSelRelationship(rowData.lstCons[0]);
        //     if(rowData.lstCons.length>0)
        //     setConsId(rowData.lstCons[0].cnsldtnId);
        //     else
        //     setConsId(0);
        //     var ConsAcct = Enumerable.from(rowData.lstConsolidationAcct).where(w => w.cnsldtnId === rowData.lstCons[0].cnsldtnId)
        //         .toArray()
        //     setAcctDataConsAll(ConsAcct);
        //     setAcctDataConsAllBasic(ConsAcct);
        //    // setInitialCall(false);
        // }
        // else {
        //setAcctDataConsAll(rowData.lstAcctAll);
        //setAcctDataConsAllBasic(rowData.lstAcctAll);
        // }
        const createNewDataState = (dataState) => {
          return {
            result: process(rowData.lstSchdlTransfer, dataState),
            dataState: dataState,
          };
        };
        let initialNewState = createNewDataState({
          filter: undefined,
          take: Number.MAX_VALUE,
          skip: 0,
        });
        setResult(initialNewState.result);
        setDataState(initialNewState.dataState);
        // setResult(response.data.lstSchdlTransfer);
        localStorage.setItem(
          "TransferData",
          JSON.stringify(response.data.lstSchdlTransfer)
        );
        //let procDt = new Date(rowData.procDate);
        //let pdt = (procDt.getMonth() + 1).toString().padStart(2, '0') + "/" + (procDt.getDate().toString().padStart(2, '0')) + "/" + (procDt.getFullYear());
        //localStorage.setItem('processingDate', pdt);
        //setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken(pMode, pConsId, pAcctId);
        }
        return error;
      });
  };

  const refreshToken = async (pMode, pConsId, pAcctId) => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        var Mode;
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));
        if (pMode == "Account") Mode = 1;
        else Mode = 0;
        transacData(Mode, pConsId, pAcctId);
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          //  setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

  let defaultColumns = [
    {
      title: "Payee Account",
      field: "extrnlAcctId",
      minWidth: "320",
      show: true,
      filter: "text",
      locked: false,
      orderIndex: -1,
      //columnMenu:menuWithExcelCheck
    },

    {
      title: "Disbursement Portfolio",
      field: "disbPort",
      minWidth: "auto",
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 0,
      //columnMenu:menuWithExcelCheck
    },
    {
      title: "Frequency",
      field: "frequency",
      minWidth: "auto",
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 1,
      //columnMenu:menuWithExcelCheck
    },

    {
      title: "Next Date",
      field: "nextTransfer",
      minWidth: "auto",
      show: true,
      filter: "date",
      locked: false,
      orderIndex: 2,
      //columnMenu:menuWithExcelCheck
    },
    {
      title: "Receiving Account",
      field: "rcvgAccount",
      minWidth: "auto",
      show: true,

      filter: "text",
      locked: false,
      orderIndex: 3,
      //columnMenu:menuWithExcelCheck
    },
    {
      title: "Receiving Portfolio",
      field: "rcvPort",
      minWidth: "auto",
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 4,
      //columnMenu:menuWithExcelCheck
    },
    {
      title: "Total Amount",
      field: "amount",
      minWidth: "auto",
      headerCellOptions: { textAlign: "right" },
      show: true,
      filter: "string",
      locked: false,
      headerClassName: "rightHeader",
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      orderIndex: 5,
      //columnMenu:menuWithExcelCheck
    },
  ];

  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
  });

  const [result, setResult] = useState(initialState.result);

  const bankName = process.env.REACT_APP_BANK;
  // const bankName = "Demo";

  console.log("bankName", bankName);

  async function loadReport() {
    debugger;
    // load report definition from the file

    const reportResponse = await fetch("Transfer.rdlx-json");

    const report = await reportResponse.json();
    return report;
  }
  async function openReport() {
    debugger;
    const report = await loadReport();
    // for(let i=0;i<reportData.t1.length;i++){
    //   reportData.t1[i].mdlWegh = parseNumber(formatNumber( reportData.t1[i].mdlWegh,"n2"));
    // }

    report.ReportSections[0].PageFooter.ReportItems[0].Value =
      bankName + ".png";

    console.log("JSON", result);
    report.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify(result?.data);

    /////////////////////
    // let parameters = [{ Name: "pInceptionDate", Value: [InceptnDt] }];

    /////////////////////

    let parameters = [
      {
        // Name: "pConsName",
        // Value: [
        //   localStorage.getItem("SelAcctNm") == ""
        //     ? 0
        //     : localStorage.getItem("SelAcctNm"),
        // ],
        Name: "pConsName",
        Value: [
          localStorage.getItem("pConsName") == "" ||
          localStorage.getItem("pConsName") == null
            ? 0
            : localStorage.getItem("pConsName"),
        ],
      },
      {
        Name: "pAccName",
        Value: [
          localStorage.getItem("SelAcctNm") == "" ||
          localStorage.getItem("SelAcctNm") == null
            ? 0
            : localStorage.getItem("SelAcctNm"),
        ],
        // Name: "pConsName",
        // Value: [
        //   localStorage.getItem("pConsName") == ""
        //     ? 0
        //     : localStorage.getItem("pConsName"),
        // ],
      },
      {
        Name: "pShow",
        Value: [
          localStorage.getItem("SelAcctId") !== "0" &&
          localStorage.getItem("SelAcctId") !== undefined &&
          localStorage.getItem("SelAcctId") !== null
            ? "1"
            : localStorage.getItem("pConsId") !== "0" &&
              localStorage.getItem("pConsId") !== undefined &&
              localStorage.getItem("pConsId") !== null
            ? "2"
            : "0",

          // localStorage.getItem("pConsId") == "0"
          //   ? 0
          //   : localStorage.getItem("SelAcctNm"),
        ],
        // Name: "pConsName",
        // Value: [
        //   localStorage.getItem("pConsName") == ""
        //     ? 0
        //     : localStorage.getItem("pConsName"),
        // ],
      },
    ];

    viewerRef.current.Viewer.open(report, { ReportParams: parameters });
  }

  const handlePdf = () => {
    setShowModal(true);
    openReport();
  };

  const handleclearfilter = (event) => {
    setTriggerClearfilter((triggerClearfilter) => triggerClearfilter + 1);
    console.log("clear filter", triggerColSave);
    setClearing(true);
    setTimeout(() => {
      setClearing(false);
    }, 1400);
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("TransferData")) != null) {
      setData(JSON.parse(localStorage.getItem("TransferData")));
    }
  }, [result]);

  const [dataState, setDataState] = useState(initialState.dataState);
  const [stateColumns, setStateColumns] = useState(defaultColumns);

  const addHiddenColumns = (columns) => {
    let newColumnsState = defaultColumns.map((col) => {
      let _col = columns.filter((c) => c.field == col.field);
      if (_col.length > 0) {
        return {
          ...col,
          orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
          width: _col[0].width ? _col[0].width : "",
        };
      } else {
        return { ...col, show: false };
      }
    });
    for (var i = 0; i < newColumnsState.length; i++) {
      for (var j = 0; j < columns.length; j++) {
        if (newColumnsState[i].field === columns[j].field)
          newColumnsState[i].headerClassName = columns[j].headerClassName;
      }
    }

    //newColumnsState[6].footerCell = totalSum;
    //newColumnsState[0].show = localStorage.getItem("SelAcctId") > 0 ? false : true;
    var tempcolumn = [];

    var j = 0;
    for (var i = 0; i < newColumnsState.length; i++) {
      tempcolumn[j] = newColumnsState[i];

      j++;
    }

    return tempcolumn;
    const [, ...restnew] = newColumnsState;
    //newColumnsState[0].show = localStorage.getItem("SelAcctId") > 0 ? false : true;
    if (
      localStorage.getItem("SelAcctId") > 0 &&
      newColumnsState[0].field === "accountName"
    ) {
      return restnew;
    } else {
      return newColumnsState;
    }

    // newColumnsState[0].columnMenu = menuWithExcelCheck;
    // newColumnsState[1].columnMenu = menuWithExcelCheck;
    // newColumnsState[2].columnMenu = menuWithExcelCheck;
    // newColumnsState[3].columnMenu = menuWithExcelCheck;
    // newColumnsState[4].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[5].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[6].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[7].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[8].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[9].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[10].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[11].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[12].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[13].columnMenu = menuWithExcelCheck;
    // newColumnsState[14].columnMenu = menuWithExcelCheck;
    // newColumnsState[15].columnMenu = menuWithExcelCheck;
    // newColumnsState[16].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[17].columnMenu = menuWithExcelCheck;
    // newColumnsState[18].columnMenu = menuWithExcelCheck;
    // newColumnsState[19].columnMenu = menuWithExcelCheck;
    // newColumnsState[20].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[21].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[22].columnMenu = menuWithExcelCheck;
    // newColumnsState[23].columnMenu = menuWithExcelCheck;
    // newColumnsState[24].columnMenu = menuWithExcelCheck;
  };
  const columnReorder = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
    setColumnLatest(props.target._columns);
  };
  const dataStateChange = (event) => {
    if (
      event.dataState.filter !== undefined &&
      event.dataState.filter !== null
    ) {
      if (event.dataState.filter.filters.length > 0) {
        for (var i = 0; i < stateColumns.length; i++) {
          for (var j = 0; j < event.dataState.filter.filters.length; j++) {
            if (
              stateColumns[i].field ==
              event.dataState.filter.filters[j].filters[0].field
            ) {
              stateColumns[i].headerClassName = "active";
              break;
            } else {
              stateColumns[i].headerClassName = "";
            }
          }
        }
      } else {
        for (var i = 0; i < stateColumns.length; i++) {
          stateColumns[i].headerClassName = "";
        }
      }
    } else {
      for (var i = 0; i < stateColumns.length; i++) {
        stateColumns[i].headerClassName = "";
      }
    }

    for (var i = 0; i < stateColumns.length; i++) {
      for (var j = 0; j < defaultColumns.length; j++) {
        if (stateColumns[i].field === defaultColumns[j].field)
          stateColumns[i].headerClassName =
            defaultColumns[j].headerClassName +
            " " +
            stateColumns[i].headerClassName;
      }
    }
    let updatedState = createDataState(event.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);

    if (event.dataState.sort) {
      // event.dataState.sort = event.dataState.sort.map((sort) => {
      debugger;
      if (
        event.dataState.sort.length > 0 &&
        event.dataState.sort[0].field == "nextTransfer"
      ) {
        debugger;
        if (event.dataState.sort[0].dir === "asc") {
          updatedState.result.data.sort(
            (a, b) => new Date(a.nextTransfer) - new Date(b.nextTransfer)
          );
        } else if (event.dataState.sort[0].dir === "desc") {
          updatedState.result.data.sort(
            (a, b) => new Date(b.nextTransfer) - new Date(a.nextTransfer)
          );
        }
      }
    }
  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };
  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResult({ ...result });
  };
  const getCells = (columns, cellProps) => {
    let cells = [];
    columns.forEach((column) => {
      if (column.aggregate) {
        cells.push(
          <td style={{ textAlign: "right" }}>
            {formatNumber(
              cellProps.dataItem.aggregates[column.field][column.aggregate],
              "##,#.00"
            )}
          </td>
        );
      } else {
        cells.push(<td>&nbsp;</td>);
      }
    });
    return cells;
  };

  useMemo(() => {
    if (triggerClearfilter) {
      debugger;
      let updatedState = createDataState({ ...dataState, filter: null }); //here the filter object is set to null
      setResult(updatedState.result);
      setDataState(updatedState.dataState);
      for (var i = 0; i < stateColumns.length; i++) {
        if (stateColumns[i].headerClassName != undefined)
          stateColumns[i].headerClassName = stateColumns[
            i
          ].headerClassName.replaceAll("active", "");
      }
      console.log(dataState);
    }
  }, [triggerClearfilter]);

  const cellRender = (tdElement, cellProps) => {
    if (
      cellProps.rowType === "groupHeader" &&
      tdElement &&
      tdElement.props.role != "presentation"
    ) {
      const columns = defaultColumns;
      return (
        <>
          <td
            {...tdElement.props}
            colSpan={tdElement.props.colSpan - columns.length}
          ></td>
          {getCells(columns, cellProps)}
        </>
      );
    }
    // if (cellProps.rowType === 'groupFooter') {

    //   if (cellProps.field === 'totMarket') {
    //     return (
    //       <td aria-colindex={cellProps.columnIndex} role={'gridcell'}>
    //         {cellProps.dataItem.aggregates.totMarket.sum}
    //       </td>
    //     );
    //   }

    // }
    if (cellProps.rowType === "data") {
      if (cellProps.field === "amount") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "currPrc") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "marketCap") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
    }
    return tdElement;
  };
  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };
  if (loading) {
    return <Loading></Loading>;
  }
  var excelTitle =
    localStorage.getItem("pConsId") == 0 &&
    localStorage.getItem("SelAcctId") == 0
      ? ""
      : localStorage.getItem("SelAcctId") == 0
      ? "Consolidation:" + localStorage.getItem("pConsName")
      : "Account Number:" + localStorage.getItem("SelAcctNm");

  return (
    <div ref={divRef}>
      <BootModal
        show={showModal}
        onHide={handleClose}
        fullscreen
        size="xl"
        style={{ zIndex: 100000 }}
      >
        <BootModal.Body>
          <div id="viewer-host">
            <Viewer ref={viewerRef} />
          </div>
        </BootModal.Body>
        <BootModal.Footer>
          <button className="btn btn-primary btn-sm" onClick={handleClose}>
            Close
          </button>
        </BootModal.Footer>
      </BootModal>
      <div className="row d-flex justify-content-start align-items-center mx-2 px-1">
        {/* {visible && (
          <Dialog title={"Invalid Date Range"} onClose={toggleDialog}>
            <p
              style={{
                margin: "25px",
                textAlign: "center",
              }}
            >
              {DialogMsg}
              
            </p>
            
            <DialogActionsBar>
              <button
                className=" btn btn-primary btn-sm"
                
                onClick={toggleDialog}
              >
                OK
              </button>
            </DialogActionsBar>
          </Dialog>
        )} */}
        <Modal
          title="Validation"
          open={visible}
          onOk={(e) => {
            setVisible(false);
          }}
          onCancel={(e) => {
            setVisible(false);
          }}
          footer={[
            <Button
              key="Ok"
              onClick={(e) => {
                setVisible(false);
              }}
            >
              Ok
            </Button>,
          ]}
        >
          <div style={{ whiteSpace: "pre-line" }}>{DialogMsg}</div>
        </Modal>
        <table className="table table-borderless w-100">
          <tbody>
            <tr className="align-middle">
              {/* Table Options Dropdown */}
              <td className="align-middle" style={{ width: "20%" }}>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item onClick={handleclearfilter}>
                        <MdFilterAltOff size={17} /> Clear Filters
                      </Menu.Item>
                      <Menu.Item onClick={excelExport}>
                        <FaFileExcel /> Export to Excel
                      </Menu.Item>
                      <Menu.Item onClick={handlePdf}>
                        <FaFilePdf /> Export to PDF
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <button className="btn btn-outline-secondary btn-sm">
                    <FaCog /> Table Options <FaChevronDown />
                  </button>
                </Dropdown>
              </td>

              {/* Date Picker for From and To Dates (Form Section) */}
              <td className="align-middle" style={{ width: "50%" }}>
                <form className="d-flex gap-2" onSubmit={daterangeCheck}>
                  <fieldset className="d-flex gap-2 w-100">
                    <div className="col-3 d-flex align-items-center">
                      <span
                        className="me-2"
                        style={{
                          color: themes === "dark" ? "white" : "#454545",
                        }}
                      >
                        From:{" "}
                      </span>
                      <DatePicker
                        id="dpFrm"
                        value={frmDate}
                        size={"small"}
                        width={"100%"}
                        min={minDt}
                        max={maxDt}
                        valid={
                          frmDate >= minDt &&
                          frmDate <= maxDt &&
                          frmDate != null
                        }
                        onChange={(e) => setfrmDate(e.value)}
                        validationMessage={
                          frmDate == null
                            ? "Enter Valid Date"
                            : frmDate < new Date("1/1/1000")
                            ? "Enter date in correct format (mm/dd/yyyy)"
                            : frmDate > maxDt
                            ? "From date cannot exceed one year from Processing date."
                            : frmDate > toDate
                            ? "From date cannot exceed to date."
                            : frmDate < minDt
                            ? "From date cannot be less than Processing date."
                            : "Validation error"
                        }
                      />
                    </div>

                    <div className="col-3 d-flex align-items-center">
                      <span
                        className="me-2"
                        style={{
                          color: themes === "dark" ? "white" : "#454545",
                        }}
                      >
                        To:{" "}
                      </span>
                      <DatePicker
                        value={toDate}
                        size={"small"}
                        width={"100%"}
                        min={minDt}
                        max={maxDt}
                        valid={
                          frmDate <= toDate &&
                          toDate <= maxDt &&
                          toDate >= minDt &&
                          frmDate != null &&
                          toDate > new Date("1/1/1000")
                        }
                        onChange={(e) => setToDate(e.value)}
                        validationMessage={
                          toDate == null
                            ? "Enter Valid Date"
                            : toDate < new Date("1/1/1000")
                            ? "Enter date in correct format (mm/dd/yyyy)"
                            : toDate > maxDt
                            ? "To date cannot exceed one year from Processing date."
                            : toDate < frmDate
                            ? "To date cannot be less than from date"
                            : toDate < minDt
                            ? "To date cannot be less than Processing date."
                            : "Validation error"
                        }
                      />
                    </div>
                  </fieldset>
                  <button className="btn btn-sm btn-primary">Submit</button>
                </form>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <ExcelExport data={result.data} ref={_export} fileName={"Transfer.xlsx"}>
        <ExcelExportColumnGroup
          title={
            excelTitle +
            "  Processing Date: " +
            localStorage.getItem("processingDate")
          }
          headerCellOptions={{
            textAlign: "left",
          }}
        >
          {stateColumns
            .sort(function (a, b) {
              return a.orderIndex - b.orderIndex;
            })
            .map((w) => {
              return (
                <ExcelExportColumn
                  field={w.field}
                  hidden={!w.show}
                  title={w.title}
                  headerCellOptions={w.headerCellOptions}
                  cellOptions={w.headerCellOptions}
                />
              );
            })}
          {/* <ExcelExportColumn field="extrnlAcctId" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Shares" />
  <ExcelExportColumn field="cost" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Cost($)" />
  <ExcelExportColumn field="market" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Market($)" />
  <ExcelExportColumn field="unrGainLoss" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Unr Gain Loss($)" />
  <ExcelExportColumn field="estAnnInc" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Est Ann Income($)" />
  <ExcelExportColumn field="yield" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Yield(%)" /> */}
        </ExcelExportColumnGroup>
      </ExcelExport>
      <Grid
        //id='tileGrid'
        style={{ height: "450px" }}
        data={result}
        {...dataState}
        onDataStateChange={dataStateChange}
        onColumnReorder={columnReorder}
        //expandField="expanded"
        //onExpandChange={expandChange}

        cellRender={cellRender}
        sortable={true}
        resizable={false}
        pageable={true}
        reorderable={true}
        scrollable="scrollable"
        //pageSize={10}
      >
        {stateColumns.map(
          (column, idx) =>
            column.show && (
              <Column
                width={setWidth(column.minWidth)}
                //  locked={column.locked}
                key={idx}
                field={column.field}
                title={column.title}
                filter={column.filter}
                footerCell={column.footerCell}
                headerClassName={column.headerClassName}
                {...column}
                //cell={NumberCell}
                //columnMenu={column.columnMenu}
                // columnMenu={(props) => (
                //   <CustomColumnMenuNoGrp
                //     {...props}
                //     columns={stateColumns}
                //     onColumnsSubmit={onColumnsSubmit}
                //   />
                // )}
                columnMenu={(props) => (
                  <>
                    {column.filter === "text" ? (
                      <CustomColumnMenuNoGrpChkBox
                        {...props}
                        columns={stateColumns}
                        data={data}
                        onColumnsSubmit={onColumnsSubmit}
                      />
                    ) : column.filter === "numeric" ? (
                      <CustomColumnMenuNoGrp
                        {...props}
                        columns={stateColumns}
                        onColumnsSubmit={onColumnsSubmit}
                      />
                    ) : (
                      <CustomColumnMenuNoGrpChkBox
                        {...props}
                        columns={stateColumns}
                        data={data}
                        onColumnsSubmit={onColumnsSubmit}
                      />
                    )}
                  </>
                )}
              />
            )
        )}
      </Grid>
      {themes === "dark" ? (
        <style>
          {`
      .k-input-sm .k-input-inner, .k-picker-sm .k-input-inner {
  background-color: #292929;
  color: white;
}
.k-input-sm .k-input-button, .k-input-sm .k-spinner-increase, .k-input-sm .k-spinner-decrease, .k-picker-sm .k-input-button, .k-picker-sm .k-spinner-increase, .k-picker-sm .k-spinner-decrease {
  
  background-color: #686868 !important;
  color: white  !important;
  
}
.k-grid-toolbar {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-pager{
  background-color: #292929 !important;
  color: white !important;
}
      .k-calendar-td:hover{
color: rgb(34, 34, 34);
}
.k-calendar-infinite .k-calendar-header {
  
  background-color: #1d1d1d !important;
 color: rgb(255, 255, 255);
}

.k-calendar-infinite .k-calendar-view {
  
  background-color: rgb(46, 46, 46);
  
}
.k-calendar .k-calendar-caption, .k-calendar .k-calendar-view th, .k-calendar .k-calendar-view .k-calendar-th, .k-calendar .k-meta-header, .k-calendar .k-month-header {
  
  color: rgb(184, 184, 184);
}
.k-calendar-infinite .k-calendar-view .k-content.k-scrollable {
  
  background-color: 1d1d1d;
  color: rgb(255, 255, 255);
}
.k-pager-numbers-wrap{
  color: rgb(135, 137, 252);
}
.k-pager-numbers .k-selected {
 
  color: rgb(236, 238, 255);
  
}
.k-calendar   .k-focus
{
  background: radial-gradient(#ffffff, #000000e0) !important;
  
}
.k-calendar   .k-focus:hover
{
  background: #5665b9b9 !important;
  
}
.k-calendar .k-content.k-scrollable, .k-calendar .k-calendar-content.k-scrollable {
  background-color: rgba(0, 0, 0, 0);
  .k-grid-toolbar {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-pager{
  background-color: #292929 !important;
  color: white !important;
}
}`}
        </style>
      ) : (
        <></>
      )}
    </div>
  );
};
export default TransferGrid;
