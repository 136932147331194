import React, { useEffect, useMemo, useState } from "react";
import { RiArrowDownSLine, RiListSettingsFill } from "react-icons/ri";
import { FaRegCopy, FaCheck, FaTimes } from "react-icons/fa";
import axios from "axios";
import Loading from "./loading";
import { FcExpired } from "react-icons/fc";

import { Dialog } from "@progress/kendo-react-dialogs";
import Modal from "react-bootstrap/Modal";
import { RiDeleteBin6Line } from "react-icons/ri";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Card, Checkbox, FormControlLabel, Switch } from "@mui/material";
import Enumerable from "linq";
import { useLocation, useNavigate } from "react-router-dom";
import { FaBookOpen, FaEye, FaPlus, FaBars } from "react-icons/fa";
import { FiEdit, FiSettings } from "react-icons/fi";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { ColumnMenu } from "./columnMenu";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { formatNumber, formatDate } from "@telerik/kendo-intl";
import { process } from "@progress/kendo-data-query";
import EditCstmRprtForm from "./editCstmDshBrdRecrd";
import { height } from "@mui/system";
//import {HiViewColumns} from 'react-icons/Hi';
import { AiOutlineFundView } from "react-icons/ai";
import { BsFillInfoCircleFill, BsThreeDots } from "react-icons/bs";
import CustomReportModal from "./customReportModal";

import { Dropdown, Space } from "antd";
import { IoIosArrowDown } from "react-icons/io";
import CustomReportMain from "./cstmRptMain";
import { AiOutlineFolderOpen } from "react-icons/ai";
import { useSelector } from "react-redux";
import { selectTheme } from "../features/theme/themeSlice";
import { ColorRing } from "react-loader-spinner";
import { MdFilterAltOff } from "react-icons/md";

const EditCommandCell = (props) => {
  let disabled =
    props.dataItem["isPrivate"] &&
    props.dataItem["crtUserId"] !== JSON.parse(localStorage.getItem("userId"))
      ? true
      : false;
  return (
    <td style={{ textAlign: "center" }}>
      <button
        disabled={disabled}
        size={35}
        className="btn btn-sm btn-outline-primary"
        title="Attributes"
        onClick={() => props.enterEdit(props.dataItem)}
      >
        <RiListSettingsFill></RiListSettingsFill>
      </button>
    </td>
  );
};

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M5 21C5 17.134 8.13401 14 12 14C15.866 14 19 17.134 19 21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",

      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M3 19H1V18C1 16.1362 2.27477 14.5701 4 14.126M6 10.8293C4.83481 10.4175 4 9.30621 4 7.99999C4 6.69378 4.83481 5.58254 6 5.1707M21 19H23V18C23 16.1362 21.7252 14.5701 20 14.126M18 5.1707C19.1652 5.58254 20 6.69378 20 7.99999C20 9.30621 19.1652 10.4175 18 10.8293M10 14H14C16.2091 14 18 15.7909 18 18V19H6V18C6 15.7909 7.79086 14 10 14ZM15 8C15 9.65685 13.6569 11 12 11C10.3431 11 9 9.65685 9 8C9 6.34315 10.3431 5 12 5C13.6569 5 15 6.34315 15 8Z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

const CustomDashboardGrd = ({
  tmplRecords,
  rptListQual,
  onDeleteTmplt,
  menuStateData,
}) => {
  const [loading, setLoading] = React.useState(false);
  const TmpltNmRegex = new RegExp(/[~`!#$%\^&*+=\-\[\]\\;,/{}|\\":<>\?]/g);
  const navigate = useNavigate();
  const themes = useSelector(selectTheme);
  const [valdMsg, setvaldMsg] = React.useState("");
  const [valdMsg2, setvaldMsg2] = React.useState("");
  const [rptCountMsg, setrptCountMsg] = React.useState("");
  const [tmpltDetails, SetTmpltDetails] = React.useState(tmplRecords);
  const [show, setShow] = React.useState(false);
  const [rptIds, setRptIds] = React.useState([]);
  const [tnm, SetTemplateNm] = React.useState("");
  const [privatetemp, setPrivate] = React.useState(false);
  const [tdsc, SetTemplateDesc] = React.useState("");
  const [btnStatus, setBtnStatus] = React.useState(true);
  const [btnStatus2, setBtnStatus2] = React.useState(true);
  const [btnStatus3, setBtnStatus3] = React.useState(true);
  const [rptList, setRptList] = React.useState(rptListQual);
  const [openForm, setOpenForm] = React.useState(false);
  const [session, setSession] = React.useState("");
  const [DelForm, setDelForm] = React.useState(false);
  const [editItem, setEditItem] = React.useState({
    templateId: 0,
  });

  const [openReportModal, setOpenReportModal] = useState(false);
  const [openCustomReport, setOpenCustomReport] = useState(false);
  const [disableEdit, SetDisableEdit] = React.useState(false);
  const [disableDelete, SetDisableDelete] = React.useState(false);
  var tempToken = JSON.parse(localStorage.getItem("token"));

  const [editOpenReportModal, SetEditOpenReportModal] = useState(false);

  const [existTemplateName, setExistTemplateName] = useState("");
  const [existTemplateDescription, setExistTemplateDescription] = useState("");

  const [reportList, setReportList] = useState([]);

  const [templateId, setTemplateId] = useState([]);
  const [vieweditdisable, setvieweditdisable] = useState(false);
  const initialDataState = {};
  const [dataState, setDataState] = React.useState();
  const [result, setResult] = React.useState(
    process(tmpltDetails, initialDataState)
  );
  const initialSort = [
    {
      field: "crtDt",
      dir: "desc",
    },
  ];

  const [crtdUserNm, setcrtdUserNm] = React.useState("");
  const [crtDt, settcrtDt] = React.useState("");

  const [modUserNm, setmodUserNm] = React.useState("");
  const [modDt, setmodDt] = React.useState("");
  const [templateNm, settemplateNm] = React.useState("");
  const [templateDesc, settemplateDesc] = React.useState("");
  const [clearing, setClearing] = useState(false);
  const [triggerClearfilter, setTriggerClearfilter] = useState(0);

  var disbStatus = true;
  const DeleteCommandCell = (props) => {
    let disabled =
      props.dataItem["crtUserId"] !== JSON.parse(localStorage.getItem("userId"))
        ? true
        : false;

    return (
      <td style={{ textAlign: "center" }}>
        <button
          disabled={disabled}
          className="btn btn-sm btn-outline-danger"
          title="Delete"
          onClick={(e) => {
            setDelForm(true);
            localStorage.setItem("DelTmpltId", props.dataItem.templateId);
          }}
        >
          <RiDeleteBin6Line></RiDeleteBin6Line>
        </button>
      </td>
    );
  };

  const IsPrivateCommandCell = (props) => {
    let Isprivate = props.dataItem["isPrivate"];

    return Isprivate ? (
      <td style={{ textAlign: "center" }} align="middle">
        <FaCheck color="red"></FaCheck>
      </td>
    ) : (
      <td style={{ textAlign: "center" }}>
        <FaTimes color="green"></FaTimes>
      </td>
    );
  };

  const CopyCommandCell = (props) => {
    let disabled =
      props.dataItem["isPrivate"] &&
      props.dataItem["crtUserId"] !== JSON.parse(localStorage.getItem("userId"))
        ? true
        : false;

    return (
      <td style={{ textAlign: "center" }}>
        <button
          disabled={false}
          className="btn btn-sm btn-outline-primary"
          title="Copy"
          onClick={(e) => {
            CopyTmplt(props.dataItem.templateId);
            localStorage.setItem("CopyTmpltId", props.dataItem.templateId);
          }}
        >
          <FaRegCopy></FaRegCopy>
        </button>
      </td>
    );
  };
  const handleClick = () => {};

  const ActionCommandCell = (props) => {
    let disabled =
      props.dataItem["isPrivate"] &&
      props.dataItem["crtUserId"] !== JSON.parse(localStorage.getItem("userId"))
        ? true
        : false;
    let disabledDel =
      props.dataItem["crtUserId"] !== JSON.parse(localStorage.getItem("userId"))
        ? true
        : false;

    return (
      <td style={{ textAlign: "center" }}>
        <Dropdown
          menu={{
            items,
          }}
          // trigger={['click']}
        >
          <a
            onMouseEnter={(e) => {
              //e.preventDefault()
              localStorage.setItem("CopyTmpltId", props.dataItem.templateId);
              localStorage.setItem("DelTmpltId", props.dataItem.templateId);
              localStorage.setItem("TmpltId", props.dataItem.templateId);
              SetDisableEdit(disabled);
              SetDisableDelete(disabledDel);
            }}
          >
            <button
              //disabled={false}
              className="btn btn-sm btn-outline-primary"
              title="Action"
              onClick={(e) => {
                //CopyTmplt(props.dataItem.templateId);
              }}
            >
              <BsThreeDots></BsThreeDots>
            </button>
          </a>
        </Dropdown>
      </td>
    );
  };

  const items = [
    {
      key: "1",
      label: (
        <a
          onClick={(e) => {
            debugger;
            console.log(e);
            var tmpId = parseInt(localStorage.getItem("DelTmpltId"));
            var filteredData = Enumerable.from(tmplRecords)
              .where((w) => w.templateId == tmpId)
              .toArray();
            setvieweditdisable(false);
            // let disabled=filteredData[0].isPrivate && filteredData[0].crtUserId!==JSON.parse(localStorage.getItem('userId'))?true:false;
            console.log("Clicked 1");
            // SetEditOpenReportModal(true);
            // OpenCstmRptPage(tmpId, filteredData[0].templateNm, 1);
            setExistTemplateName(filteredData[0].templateNm);
            setExistTemplateDescription(filteredData[0].templateDesc);
            OpenCustomReportModalEdit(tmpId);
            setTemplateId(tmpId);
          }}
        >
          Edit
        </a>
      ),
      icon: <FiEdit />,
      disabled: disableEdit,
    },
    {
      type: "divider",
    },
    // {
    //   key: "2",
    //   label: (
    //     <a
    //       onClick={() => {
    //         setOpenForm(true);

    //         var tmpId = localStorage.getItem("DelTmpltId");
    //         var filteredData = Enumerable.from(tmplRecords)
    //           .where((w) => w.templateId == tmpId)
    //           .toArray();
    //         // tmplRecords
    //         setEditItem(filteredData[0]);
    //       }}
    //     >
    //       Attributes
    //     </a>
    //   ),
    //   icon: <RiListSettingsFill />,
    //   disabled: disableEdit,
    // },
    // {
    //   type: "divider",
    // },
    {
      key: "3",
      label: (
        <a
          onClick={(e) => {
            CopyTmplt(localStorage.getItem("CopyTmpltId"));
            //localStorage.setItem("CopyTmpltId",props.dataItem.templateId);
          }}
        >
          Copy
        </a>
      ),
      icon: <FaRegCopy />,
    },
    {
      type: "divider",
    },
    {
      key: "4",
      label: (
        <a
          onClick={(e) => {
            setDelForm(true);

            //localStorage.setItem("DelTmpltId",1)
          }}
        >
          Delete
        </a>
      ),
      icon: <RiDeleteBin6Line />,
      danger: true,
      disabled: disableDelete,
    },
  ];

  const OpenCstmRptPage = (tid, tnm, editable) => {
    debugger;
    localStorage.setItem("tmpltIdFrmDshBrd", JSON.stringify(tid));
    console.log(tnm);
    setShow(true);
    localStorage.setItem("Editable", editable);
    localStorage.setItem("tmpltNm", tnm);
    console.log("Clicked 2");
    SetEditOpenReportModal(true);
    // navigate("/cstmRptMain");
  };

  const OpenCustomReportModalEdit = async (tmpId) => {
    debugger;
    let token = tempToken;

    let templateId = tmpId;

    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    const postData = { templateId };

    await axios
      .post("RTGetTemplateReport", postData, config)
      .then((response) => {
        console.log("response", response);
        console.log("Data ReportID", response.data.lstRTGetTemplateReport);
        setReportList(response.data.lstRTGetTemplateReport);

        SetEditOpenReportModal(true);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshTokenForDel();
        }
        console.log("my error is " + error);
      });
  };

  // const onDataStateChangeCustom = React.useCallback((e) => {
  //   setDataState(e.dataState);
  //   setResult(process(tmplRecords, e.dataState));
  // }, []);

  const onDataStateChangeCustom = React.useCallback((e) => {
    debugger;
    var templateNmInd,
      templateDescInd,
      crtdUserNmInd,
      crtDtInd,
      modUserNmInd,
      modDtInd;

    if (e.dataState.filter !== undefined && e.dataState.filter !== null) {
      if (e.dataState.filter.filters.length > 0) {
        templateNmInd = 0;
        templateDescInd = 0;
        crtdUserNmInd = 0;
        crtDtInd = 0;
        modUserNmInd = 0;
        modDtInd = 0;
        // for (var i = 0; i < e.stateColumns.length; i++) {
        for (var i = 0; i < e.target.columns.length; i++) {
          for (var j = 0; j < e.dataState.filter.filters.length; j++) {
            // a= e.target.columns[i].field;
            if (
              e.target.columns[i].field ==
              e.dataState.filter.filters[j].filters[0].field
            ) {
              // a.headerClassName="active";
              //Grid.columns[i].headerClassName =="active";
              if (
                e.dataState.filter.filters[j].filters[0].field == "templateNm"
              ) {
                settemplateNm("active");
                templateNmInd = 1;
              }
              if (
                e.dataState.filter.filters[j].filters[0].field == "templateDesc"
              ) {
                settemplateDesc("active");
                templateDescInd = 1;
              }
              if (
                e.dataState.filter.filters[j].filters[0].field == "crtdUserNm"
              ) {
                setcrtdUserNm("active");
                crtdUserNmInd = 1;
              }
              if (e.dataState.filter.filters[j].filters[0].field == "crtDt") {
                settcrtDt("active");
                crtDtInd = 1;
              }
              if (
                e.dataState.filter.filters[j].filters[0].field == "modUserNm"
              ) {
                setmodUserNm("active");
                modUserNmInd = 1;
              }
              if (e.dataState.filter.filters[j].filters[0].field == "modDt") {
                setmodDt("active");
                modDtInd = 1;
              }

              //dataState.columns[i].headerClassName = "";
              break;
            } else {
              if (templateNmInd != 1) {
                settemplateNm("");
              }
              if (templateDescInd != 1) {
                settemplateDesc("");
              }
              if (crtdUserNmInd != 1) {
                setcrtdUserNm("");
              }
              if (crtDtInd != 1) {
                settcrtDt("");
              }
              if (modUserNmInd != 1) {
                setmodUserNm("");
              }
              if (modDtInd != 1) {
                setmodDt("");
              }
              //e.target.columns[i].headerClassName="";
              //Grid.columns ="active";
            }
          }
        }
      } else {
        for (var i = 0; i < e.target.columns.length; i++) {
          // e.target.columns[i].headerClassName="";
          settemplateNm("");
          settemplateDesc("");
          setcrtdUserNm("");
          settcrtDt("");
          setmodUserNm("");
          setmodDt("");
        }
      }
    }

    if (e.dataState.filter == undefined) {
      settemplateNm("");
      settemplateDesc("");
      setcrtdUserNm("");
      settcrtDt("");
      setmodUserNm("");
      setmodDt("");
    }

    setDataState(e.dataState);
    setResult(process(tmplRecords, e.dataState));
  }, []);

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };

  // const cellRender = (tdElement, cellProps) => {
  //   if (cellProps.rowType === "data") {
  //     if (cellProps.field === "") {
  //       let tID = cellProps.dataItem["templateId"];
  //       let tNm = cellProps.dataItem["templateNm"];
  //       let disabled =
  //         cellProps.dataItem["isPrivate"] &&
  //         cellProps.dataItem["crtUserId"] !==
  //           JSON.parse(localStorage.getItem("userId"))
  //           ? true
  //           : false;

  //       return (
  //         <td
  //           style={{ textAlign: "center" }}
  //           aria-colindex={cellProps.columnIndex}
  //           role={"gridcell"}
  //         >
  //           <button
  //             disabled={disabled}
  //             className="btn btn-sm btn-outline-primary"
  //             title="Edit"
  //             onClick={() => OpenCstmRptPage(tID, tNm, 1)}
  //           >
  //             <FiEdit></FiEdit>{" "}
  //           </button>
  //         </td>
  //       );
  //     }
  //     if (cellProps.field === "isPrivate") {
  //       if (cellProps.dataItem.isPrivate) {
  //         return (
  //           <td
  //             style={{ textAlign: "center" }}
  //             aria-colindex={cellProps.columnIndex}
  //             role={"gridcell"}
  //           >
  //             <a style={{ color: "red" }} title="Private">
  //               True{" "}
  //             </a>
  //           </td>
  //         );
  //       } else {
  //         return (
  //           <td
  //             style={{ textAlign: "center" }}
  //             aria-colindex={cellProps.columnIndex}
  //             role={"gridcell"}
  //           >
  //             <a style={{ color: "green" }} title="Public">
  //               False{" "}
  //             </a>
  //           </td>
  //         );
  //       }
  //     }
  //     if (cellProps.field === "templateNm") {
  //       let tID = cellProps.dataItem["templateId"];
  //       let tNm = cellProps.dataItem["templateNm"];

  //       // debugger;
  //       let isprivate = cellProps.dataItem["isPrivate"];
  //       return (
  //         <td
  //           style={{
  //             cursor: "pointer",
  //             color: isprivate ? "#E44C4C" : "#2d7af7",
  //           }}
  //           aria-colindex={cellProps.columnIndex}
  //           role={"gridcell"}
  //         >
  //           <a
  //             title="View"
  //             onClick={(e) => {
  //               // debugger;
  //               console.log(tNm);
  //               var tmpId = parseInt(localStorage.getItem("DelTmpltId"));
  //               var filteredData = Enumerable.from(tmplRecords)
  //                 .where((w) => w.templateId == tID)
  //                 .toArray();
  //               setvieweditdisable(true);
  //               // let disabled=filteredData[0].isPrivate && filteredData[0].crtUserId!==JSON.parse(localStorage.getItem('userId'))?true:false;
  //               console.log("Clicked 1");
  //               // SetEditOpenReportModal(true);
  //               // OpenCstmRptPage(tmpId, filteredData[0].templateNm, 1);
  //               setExistTemplateName(filteredData[0].templateNm);
  //               localStorage.setItem(
  //                 "tmpltIdFrmDshBrd",
  //                 JSON.stringify(filteredData[0].templateId)
  //               );
  //               setExistTemplateDescription(filteredData[0].templateDesc);
  //               OpenCustomReportModalEdit(tID);
  //               setTemplateId(tID);
  //             }}
  //             // onClick={() => OpenCstmRptPage(tID, tNm, 0)}
  //           >
  //             <u>{tNm}</u>{" "}
  //           </a>
  //         </td>
  //       );
  //     }
  //   }
  //   return tdElement;
  // };

  const createDataState = (dataState) => {
    return {
      result: process(tmpltDetails, dataState),
      dataState: dataState,
    };
  };

  useMemo(() => {
    if (triggerClearfilter) {
      debugger;
      let updatedState = createDataState({ ...dataState, filter: null }); //here the filter object is set to null
      setResult(updatedState.result);
      setDataState(updatedState.dataState);
      settemplateNm("");
      settemplateDesc("");
      setcrtdUserNm("");
      settcrtDt("");
      setmodUserNm("");
      setmodDt("");
      console.log(dataState);
    }
  }, [triggerClearfilter]);

  const cellRender = (tdElement, cellProps) => {
    // debugger;
    console.log("Test");
    if (cellProps.rowType === "data") {
      if (cellProps.field === "") {
        let tID = cellProps.dataItem["templateId"];
        let tNm = cellProps.dataItem["templateNm"];
        return (
          <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            <button
              className="btn btn-sm btn-outline-secondary"
              //disabled={disbStatus}
              title="View"
              // onClick={() => OpenCstmRptPage(tID, tNm, 0)}
              onClick={(e) => {
                // debugger;
                menuStateData(true);
                console.log(tNm);
                var tmpId = parseInt(localStorage.getItem("DelTmpltId"));
                var filteredData = Enumerable.from(tmplRecords)
                  .where((w) => w.templateId == tID)
                  .toArray();
                setvieweditdisable(true);
                // let disabled=filteredData[0].isPrivate && filteredData[0].crtUserId!==JSON.parse(localStorage.getItem('userId'))?true:false;
                console.log("Clicked 1");
                // SetEditOpenReportModal(true);
                // OpenCstmRptPage(tmpId, filteredData[0].templateNm, 1);
                setExistTemplateName(filteredData[0].templateNm);
                localStorage.setItem(
                  "tmpltIdFrmDshBrd",
                  JSON.stringify(filteredData[0].templateId)
                );
                setExistTemplateDescription(filteredData[0].templateDesc);
                OpenCustomReportModalEdit(tID);
                setTemplateId(tID);
              }}
              // onClick={(e) => {
              //   debugger;
              //   console.log(tNm);
              //   var tmpId = parseInt(localStorage.getItem("DelTmpltId"));
              //   var filteredData = Enumerable.from(tmplRecords)
              //     .where((w) => w.templateId == tID)
              //     .toArray();
              //   setvieweditdisable(true);
              //   // let disabled=filteredData[0].isPrivate && filteredData[0].crtUserId!==JSON.parse(localStorage.getItem('userId'))?true:false;
              //   console.log("Clicked 1");
              //   // SetEditOpenReportModal(true);
              //   // OpenCstmRptPage(tmpId, filteredData[0].templateNm, 1);
              //   setExistTemplateName(filteredData[0].templateNm);
              //   setExistTemplateDescription(filteredData[0].templateDesc);
              //   OpenCustomReportModalEdit(tID);
              //   setTemplateId(tID);
              // }}
            >
              <AiOutlineFolderOpen size={20} />{" "}
            </button>
          </td>
        );
      }
    }
    return tdElement;
  };

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setvaldMsg("");
  };
  const getRptIDs = (e) => {
    const { value, checked } = e.target;
    if (e.target.checked) {
      setRptIds([...rptIds, value]);
    } else {
      setRptIds(rptIds.filter((e) => e !== value));
    }
    console.log(`${rptIds} is ${checked}`);

    const elements = document.getElementsByName("checkbox");
    let checkedCount = 0;
    elements.forEach((element) => {
      if (element.checked) {
        checkedCount++;
      }
    });
    if (checkedCount >= 1 && checkedCount <= 5) {
      setrptCountMsg("");
      setBtnStatus(false);
    } else {
      setrptCountMsg(
        checkedCount < 1
          ? "Please select atleast one report."
          : "Please select maximum five reports at a time."
      );
      setBtnStatus(true);
    }
  };
  const handleChangeTnm = (e) => {
    SetTemplateNm(e.target.value);
    let isValid = !TmpltNmRegex.test(e.target.value);

    if (!isValid) {
      setBtnStatus2(true);
      setvaldMsg("Special characters are not Allowed.");
    }

    if (e.target.value === "") {
      setBtnStatus2(true);
      setvaldMsg("Please Enter Template Name.");
    }

    var flag = false;
    for (var i = 0; i < tmplRecords.length; i++) {
      if (tmplRecords[i].templateNm == e.target.value) {
        flag = true;
        break;
      }
    }
    if (flag) {
      setBtnStatus2(true);
      setvaldMsg("Template name already exists.");
    }

    if (isValid && e.target.value !== "" && !flag) {
      setBtnStatus2(false);
      setvaldMsg("");
    }
  };
  const handleChangeTdesc = (e) => {
    SetTemplateDesc(e.target.value);
    if (e.target.value === "") {
      setBtnStatus3(true);
      setvaldMsg("Please Enter Template Description.");
    }
    if (e.target.value !== "") {
      setBtnStatus3(false);
    }

    // let isValid = !TmpltNmRegex.test(e.target.value);

    // if (!isValid) {
    //   setBtnStatus3(true);
    //   setvaldMsg2("Special characters are not Allowed.");
    // }

    // if (e.target.value === "") {
    //   setBtnStatus3(true);
    //   setvaldMsg2("Please Enter Template Description.");
    // }
    // if (isValid && e.target.value !== "") {
    //   setBtnStatus3(false);
    //   setvaldMsg2("");
    // }
  };
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        saveNewTemplate();
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });
  };

  const DeleteTmplt = async () => {
    // debugger;
    let token = tempToken;
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let TemplateId = localStorage.getItem("DelTmpltId");
    let TemplateNm = localStorage.getItem("tNmForUpdt");
    let TemplateDesc = localStorage.getItem("tDescForUpdt");
    let IsPermanentDelete = 1;
    let UserId = JSON.parse(localStorage.getItem("userId"));
    const postData = { TemplateId, UserId, IsPermanentDelete };
    setLoading(true);
    await axios
      .post("RtDeleteTemplate", postData, config)
      .then((response) => {
        const rowData = response.data;
        var success = rowData.successFlag;
        // setTimeout(() => {
        setLoading(false);
        // }, timeout);
        // const templateData = rowData.ocTmpltDetailsT;
        // const lstRpts = rowData.ocQualRptLstT;
        // SetTmpltDetails(rowData.ocTmpltDetailsT);
        // setRptList(Enumerable.from(rowData.ocQualRptLstT).toArray());
        tmplRecords = rowData.ocTmpltDetailsT;
        setResult(rowData.ocTmpltDetailsT, initialDataState);
        // // setRptList(rowData.oCQualRptLstT);
        setDelForm(false);
        onDeleteTmplt();
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshTokenForDel();
        }
        console.log("my error is " + error);
      });
  };
  const CopyTmplt = async (tmpltId) => {
    let token = tempToken;
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    let TemplateId = tmpltId;
    let TemplateNm = localStorage.getItem("tNmForUpdt");
    let TemplateDesc = localStorage.getItem("tDescForUpdt");

    let UserId = JSON.parse(localStorage.getItem("userId"));
    const postData = { TemplateId, UserId };
    setLoading(true);
    await axios
      .post("TemplateProfile/CopyTemplate", postData, config)
      .then((response) => {
        const rowData = response.data;
        var success = rowData.successFlag;
        // setTimeout(() => {
        setLoading(false);
        // }, timeout);
        tmplRecords = rowData.ocTmpltDetailsT;
        // const templateData = rowData.ocTmpltDetailsT;
        // const lstRpts = rowData.ocQualRptLstT;
        // SetTmpltDetails(rowData.ocTmpltDetailsT);
        // setRptList(Enumerable.from(rowData.ocQualRptLstT).toArray());
        setResult(rowData.ocTmpltDetailsT, initialDataState);
        // // setRptList(rowData.oCQualRptLstT);
        setDelForm(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshTokenForDel();
        }
        console.log("my error is " + error);
      });
  };
  const saveNewTemplate = async () => {
    // debugger;
    let idXml = "<Root>";
    idXml =
      idXml +
      rptIds.map(function (val) {
        return val !== ","
          ? "<Records><ReportId>" + val + "</ReportId></Records>"
          : "";
      });
    idXml = idXml + "</Root>";
    let RptIds = idXml;

    let TemplateNm = tnm;
    let TemplateDesc = tdsc;
    localStorage.setItem("tmpltNm", tnm);
    let UserId = JSON.parse(localStorage.getItem("userId"));
    //let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    let IsPrivate = privatetemp;
    const data = { UserId, TemplateNm, TemplateDesc, RptIds, IsPrivate };
    console.log("CstmDashboard Oncreation Template PostData: ", data);
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    await axios
      .post("TemplateProfile/TemplateProfile", data, config)
      .then((response) => {
        console.log("Template After Creation: ", response);

        debugger;

        if (response.statusText === "OK" || response.statusText === "") {
          localStorage.setItem("tmpltNm", tnm);
          setRptIds([]);
          SetTmpltDetails(response.data.ocTemplateProfile);

          if (response.data.saveTemplateId !== 0) {
            localStorage.setItem(
              "tmpltIdFrmDshBrd",
              JSON.stringify(response.data.saveTemplateId)
            );
            navigate("/cstmRptMain");
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        console.log("my error is " + error);
      });

    handleClose();
  };
  const UpdateTmplt = async () => {
    let token = tempToken;
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    let TemplateId = localStorage.getItem("tIdForUpdt");
    let TemplateNm = localStorage.getItem("tNmForUpdt");
    let TemplateDesc = localStorage.getItem("tDescForUpdt");
    let InsrtUpdtInd = 1;
    let UserId = JSON.parse(localStorage.getItem("userId"));
    const postData = {
      TemplateId,
      TemplateNm,
      TemplateDesc,
      UserId,
      InsrtUpdtInd,
    };
    setLoading(true);
    await axios
      .post("TemplateProfile/UpdateTemplate", postData, config)
      .then((response) => {
        setLoading(false);
        const rowData = response.data;
        console.log("rowData", rowData);
        const templateData = rowData.ocTmpltDetailsT;
        tmplRecords = rowData.ocTmpltDetailsT;
        const lstRpts = rowData.ocQualRptLstT;
        SetTmpltDetails(rowData.ocTmpltDetailsT);
        setRptList(Enumerable.from(rowData.ocQualRptLstT).toArray());
        setResult(rowData.ocTmpltDetailsT, initialDataState);
        // setRptList(rowData.oCQualRptLstT);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshTokenForUpdt();
        }
        console.log("my error is " + error);
      });
  };
  const refreshTokenForDel = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        DeleteTmplt();
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });
  };
  const refreshTokenForUpdt = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        UpdateTmplt();
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });
  };
  const handlePrivateChange = (e) => {
    setPrivate(e.target.checked);
  };
  const DateCell = (props) => {
    if (props.field === "crtDt") {
      let cdt = new Date(props.dataItem["crtDt"]);
      return (
        <td style={{ textAlign: "left" }}>
          {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
          {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
        </td>
      );
    }
    if (props.field === "modDt") {
      let cdt = new Date(props.dataItem["modDt"]);
      return (
        <td style={{ textAlign: "left" }}>
          {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
          {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
        </td>
      );
    }
  };
  const MyEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />
  );

  const IsPrivateCell = (props) => <IsPrivateCommandCell {...props} />;

  const MyDltCommandCell = (props) => (
    <DeleteCommandCell {...props} enterEdit={enterDelete} />
  );
  const MyCopyCommandCell = (props) => (
    <CopyCommandCell {...props} enterEdit={enterDelete} />
  );

  const MyActionCommandCell = (props) => (
    <ActionCommandCell {...props} enterEdit={enterAction} />
  );

  const enterAction = (item) => {
    // setDelForm(true);
    // setEditItem(item);
  };

  const enterDelete = (item) => {
    setDelForm(true);
    setEditItem(item);
  };
  const enterEdit = (item) => {
    setOpenForm(true);
    setEditItem(item);
  };
  const handleSubmit = (event) => {
    localStorage.setItem("tIdForUpdt", event.templateId);
    localStorage.setItem("tNmForUpdt", event.templateNm);
    localStorage.setItem("tDescForUpdt", event.templateDesc);
    UpdateTmplt();
    // window.location.reload(false)
    console.log("template updated");
    setOpenForm(false);
  };
  const handleOpenCustomReport = () => {
    setOpenCustomReport(true);
  };
  const handleCancelEdit = () => {
    console.log("template update cancelled");
    setOpenForm(false);
  };

  // Function to close the CustomReportModal and then open the next modal
  const handleCloseCustomReportModal = () => {
    setOpenReportModal(false); // Close the CustomReportModal
    setTimeout(() => {
      setOpenCustomReport(true); // Open the second modal after the first closes
    }, 300); // Adjust delay based on your modal's close animation duration
  };

  const handleclearfilter = (event) => {
    setTriggerClearfilter((triggerClearfilter) => triggerClearfilter + 1);
    setClearing(true);
    setTimeout(() => {
      setClearing(false);
    }, 1400);
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <Loading />
        )}
      </>
    );
  }
  return (
    <div>
      <CustomReportModal
        menuStateData={menuStateData}
        open={openReportModal}
        existTemplateName={null}
        existTemplateDescription={null}
        onClose={() => {
          menuStateData(false);
          setOpenReportModal(false);
        }}
        onCstmRptModal={() => setOpenCustomReport(false)}
        reportList={[]}
        disableFlag={false}
        vieweditdisable={false}
        tmpId={templateId}
      />
      <Modal show={openCustomReport}>
        <CustomReportMain
          setOpenCustomReport={setOpenCustomReport}
          menuStateData={menuStateData}
        ></CustomReportMain>
      </Modal>
      {!openReportModal && (
        <>
          <div className="row d-flex justify-content-start align-items-center card-header tableheader py-2 mb-2 h6">
            <div className="text-start">
              Custom Report &nbsp;&nbsp;
              <BsFillInfoCircleFill
                title="Private templates are denoted by red color"
                color="#0384fc"
                size={15}
              />
            </div>
          </div>
          <button
            className="btn btn-outline-secondary align-items-center mx-2 btn-sm"
            onClick={handleclearfilter}
          >
            {clearing ? (
              <ColorRing
                visible={true}
                height="19"
                width="19"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={["#4db8ff", "#4db8ff", "#4db8ff", "#4db8ff", "#4db8ff"]}
              />
            ) : (
              <MdFilterAltOff size={17}></MdFilterAltOff>
            )}{" "}
            clear filters
          </button>
          <div className="row my-2">
            <div className="col col-md-12 col-sm-12 py-2">
              {/* <div className="card-header tableheader py-1 mb-2">Custom Report</div> */}
              <div className="mx-1 px-1">
                <Grid
                  style={{ height: "450px" }}
                  //data={resultState.slice(page.skip, page.skip + page.take)}
                  // groupable={{
                  //   footer: "visible",
                  // }}
                  data={result}
                  resizable={true}
                  reorderable={true}
                  sortable={true}
                  sort={initialSort}
                  onDataStateChange={onDataStateChangeCustom}
                  {...dataState}
                  cellRender={cellRender}
                >
                  <Column
                    field="templateNm"
                    menu={true}
                    title="Template Name"
                    headerClassName={templateNm}
                    width="248px"
                    columnMenu={ColumnMenu}
                  />
                  <Column
                    field="templateDesc"
                    menu={true}
                    title="Description"
                    headerClassName={templateDesc}
                    width="auto"
                    columnMenu={ColumnMenu}
                  />
                  <Column
                    field="crtdUserNm"
                    menu={true}
                    title="Created By"
                    width="auto"
                    headerClassName={crtdUserNm}
                    columnMenu={ColumnMenu}
                  />
                  <Column
                    field="crtDt"
                    menu={true}
                    // filter="date"
                    title="Created Date"
                    cell={DateCell}
                    headerClassName={crtDt}
                    width="180px"
                    columnMenu={ColumnMenu}
                  />
                  <Column
                    field="modUserNm"
                    menu={true}
                    title="Modified By"
                    headerClassName={modUserNm}
                    width="auto"
                    columnMenu={ColumnMenu}
                  />
                  <Column
                    field="modDt"
                    menu={true}
                    title="Modified Date"
                    cell={DateCell}
                    headerClassName={modDt}
                    width="180px"
                    columnMenu={ColumnMenu}
                  />
                  {/* <Column field='isPrivate' menu={true} title="Is Private?"  cell={IsPrivateCell}  width="75px"  />
              <Column field='' title="Edit" menu={true}  width="58px" />
              <Column title="Attributes" cell={MyEditCommandCell}  width="76px" />
              <Column title="Delete" cell={MyDltCommandCell}  width="66px" />
              <Column title="Copy" cell={MyCopyCommandCell}  width="65px" /> */}
                  <Column
                    field=""
                    title="View"
                    // cell={MyActionCommandCell}
                    menu={true}
                    width="100px"
                  />
                </Grid>
                {/* {openForm && (
                  <EditCstmRprtForm
                    cancelEdit={handleCancelEdit}
                    onSubmit={handleSubmit}
                    item={editItem}
                  />
                  
                )} */}

                {editOpenReportModal && (
                  <CustomReportModal
                    menuStateData={menuStateData}
                    open={editOpenReportModal}
                    existTemplateName={existTemplateName}
                    existTemplateDescription={existTemplateDescription}
                    onClose={() => {
                      menuStateData(false);
                      SetEditOpenReportModal(false);
                    }}
                    reportList={reportList}
                    disableFlag={true}
                    vieweditdisable={vieweditdisable}
                    tmpId={templateId}
                    onCstmRptModal={() => setOpenCustomReport(true)}
                  />
                )}
                {DelForm ? (
                  <Dialog
                    title={`Delete Template`}
                    onClose={(e) => {
                      setDelForm(false);
                    }}
                  >
                    <>Do you want to delete this template permanently?</>

                    <div className="k-form-buttons">
                      <button
                        type={"submit"}
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        title="Template will be deleted permanently"
                        onClick={DeleteTmplt}
                        //disabled={!ErrStatTNm  && !ErrStatTDesc ? false : true }
                        //? !formRenderProps.allowSubmit : formRenderProps.allowSubmit}
                      >
                        Yes
                      </button>
                      {/* <button
                type={"submit"}
                title='Template will be removed to'
                onClick={DeleteTmplt}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                //disabled={!ErrStatTNm  && !ErrStatTDesc ? false : true }
                  //? !formRenderProps.allowSubmit : formRenderProps.allowSubmit}
              >
                No
              </button> */}
                      <button
                        type={"submit"}
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        onClick={(e) => {
                          setDelForm(false);
                        }}
                      >
                        No
                      </button>
                    </div>
                  </Dialog>
                ) : (
                  <></>
                )}
              </div>
              <div className="card-footer mx-1 my-2">
                <div className="col col-md-4 col-sm-4 py-1"></div>
              </div>
              <div className="row mx-1 my-2">
                <div className="form">
                  <div className="input-container">
                    {show ? (
                      <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>
                            {" "}
                            <div className="card-header tableheader">
                              New Template
                            </div>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="input-group pb-2 pt-2">
                            <TextField
                              required
                              className="form-control"
                              name="tname"
                              variant="outlined"
                              label="Template Name"
                              size="small"
                              onChange={handleChangeTnm}
                            ></TextField>
                          </div>
                          <div style={{ color: "red" }}>{valdMsg}</div>
                          <div className="input-group pb-2 pt-2">
                            <TextField
                              required
                              className="form-control"
                              name="tdesc"
                              variant="outlined"
                              label="Template Desc"
                              size="small"
                              onChange={handleChangeTdesc}
                            ></TextField>
                          </div>
                          <div style={{ color: "red" }}>{valdMsg2}</div>
                          <div className="card-header tableheader">
                            Select Reports
                          </div>
                          <ul
                            className="toppings-list"
                            style={{ overflowY: "scroll", height: "350px" }}
                          >
                            {rptList.map(({ reportId, reportNm }, index) => {
                              return (
                                <li key={index}>
                                  <div className="toppings-list-item">
                                    <div className="left-section">
                                      <input
                                        type="checkbox"
                                        id={`custom-checkbox-${index}`}
                                        //name={reportNm}
                                        name="checkbox"
                                        value={reportId}
                                        onClick={getRptIDs}
                                      />
                                      <label
                                        htmlFor={`custom-checkbox-${index}`}
                                      >
                                        {reportNm}
                                      </label>
                                    </div>
                                  </div>
                                  {/* {reportId <= 10 ?  
                              <div>
                                {reportId === 1 ?
                                    <div style={{ marginTop: ".5rem", paddingTop: ".5rem" }}>Accounts<hr/></div>
                                    : ""}

                                <div className="toppings-list-item">
                               
                                  <div className="left-section">
                                    <input
                                      type="checkbox"
                                      id={`custom-checkbox-${index}`}
                                      //name={reportNm}
                                      name="checkbox"
                                      value={reportId}
                                      onClick={getRptIDs}
                                    />
                                    <label htmlFor={`custom-checkbox-${index}`}>{reportNm}</label>
                                  </div>
                                </div>

                              </div>

                              : reportId > 10 && reportId <= 15 ?
                                <div>
                                  {reportId === 11 ?
                                    <div style={{ borderTop: "1px solid #ccc", marginTop: "1rem", paddingTop: "1rem" }}>Performence <hr/></div>
                                    : ""}

                                  <div className="toppings-list-item ">
                                    <div className="left-section" >

                                      <input
                                        type="checkbox"
                                        id={`custom-checkbox-${index}`}
                                        //name={reportNm}
                                        name="checkbox"
                                        value={reportId}
                                        onClick={getRptIDs}
                                      />
                                      <label htmlFor={`custom-checkbox-${index}`}>{reportNm}</label>
                                    </div>
                                  </div>
                                </div>

                                : reportId > 15 && reportId <=20 ?
                                  <div>
                                    {reportId === 16 ?
                                      <div style={{ borderTop: "1px solid #ccc", marginTop: "1rem", paddingTop: "1rem" }}>Fixed income<hr/></div>
                                      : ""}

                                    <div className="toppings-list-item ">
                                      <div className="left-section" >

                                        <input
                                          type="checkbox"
                                          id={`custom-checkbox-${index}`}
                                          //name={reportNm}
                                          name="checkbox"
                                          value={reportId}
                                          onClick={getRptIDs}
                                        />
                                        <label htmlFor={`custom-checkbox-${index}`}>{reportNm}</label>
                                      </div>
                                    </div>
                                  </div>

                                  : ""
                            } */}
                                </li>
                              );
                            })}
                          </ul>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography>Public</Typography>
                            <MaterialUISwitch
                              sx={{ m: 1 }}
                              checked={privatetemp}
                              onChange={handlePrivateChange}
                              defaultChecked
                            />
                            <Typography>Private</Typography>
                          </Stack>{" "}
                        </Modal.Body>
                        <Modal.Footer>
                          <p>
                            {btnStatus ? (
                              <span style={{ color: "#ff1744" }}>
                                {rptCountMsg}
                              </span>
                            ) : (
                              <></>
                            )}
                          </p>
                          &nbsp;
                          {/* <p>{btnStatus2? <span style={{ color: '#ff1744' }}>{valdMsg}</span> : <></>}</p>
                    <p>{btnStatus3? <span style={{ color: '#ff1744' }}>{valdMsg2}</span> : <></>}</p> */}
                          <button
                            className="btn btn-primary  btn-sm"
                            disabled={btnStatus || btnStatus2 || btnStatus3}
                            onClick={saveNewTemplate}
                          >
                            Save Changes
                          </button>
                          <button
                            className="btn btn-primary  btn-sm"
                            onClick={handleClose}
                          >
                            Close
                          </button>
                        </Modal.Footer>
                      </Modal>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {themes === "dark" ? (
        <style>
          {`
      .k-input-sm .k-input-inner, .k-picker-sm .k-input-inner {
  background-color: #292929;
  color: white;
}
.k-input-sm .k-input-button, .k-input-sm .k-spinner-increase, .k-input-sm .k-spinner-decrease, .k-picker-sm .k-input-button, .k-picker-sm .k-spinner-increase, .k-picker-sm .k-spinner-decrease {
  
  background-color: #686868 !important;
  color: white  !important;
  
}
  .k-grid-footer {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-footer td, .k-grid-footer .k-table-td {
  color:white !important;
  background-color: #292929 !important;
}
.k-grid-toolbar {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-pager{
  background-color: #292929 !important;
  color: white !important;
}
      .k-calendar-td:hover{
color: rgb(34, 34, 34);
}
.k-calendar-infinite .k-calendar-header {
  
  background-color: #1d1d1d !important;
 color: rgb(255, 255, 255);
}

.k-calendar-infinite .k-calendar-view {
  
  background-color: rgb(46, 46, 46);
  
}
.k-calendar .k-calendar-caption, .k-calendar .k-calendar-view th, .k-calendar .k-calendar-view .k-calendar-th, .k-calendar .k-meta-header, .k-calendar .k-month-header {
  
  color: rgb(184, 184, 184);
}
.k-calendar-infinite .k-calendar-view .k-content.k-scrollable {
  
  background-color: 1d1d1d;
  color: rgb(255, 255, 255);
}
  .k-calendar   .k-focus
{

  background-color: #78e4ffa8 !important;

}
  .k-calendar   .k-focus
{
  background: radial-gradient(#ffffff, #000000e0) !important;
  
}
.k-calendar   .k-focus:hover
{
  background: #5665b9b9 !important;
  
}
.k-calendar .k-content.k-scrollable, .k-calendar .k-calendar-content.k-scrollable {
  background-color: rgba(0, 0, 0, 0);
  .k-grid-toolbar {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-pager{
  background-color: #292929 !important;
  color: white !important;
}
  .k-input-md .k-input-inner, .k-picker-md .k-input-inner {
    
    background-color: #4d4d4d !important;
}
    .k-input .k-input-button, .k-input .k-input-spinner .k-spinner-increase, .k-input .k-input-spinner .k-spinner-decrease, .k-picker .k-input-button, .k-picker .k-input-spinner .k-spinner-increase, .k-picker .k-input-spinner .k-spinner-decrease {
    
    background-color: #2b2b2b !important;
}

.k-input-solid {
   
    background-color: #414141 !important;
    color: white !important;
}
}`}
        </style>
      ) : (
        <></>
      )}
    </div>
  );
};
export default CustomDashboardGrd;
