import React from "react";
import { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import { Card, Checkbox, FormControlLabel, Switch } from "@mui/material";
import { filterBy, orderBy } from "@progress/kendo-data-query";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomCalendar } from "./customCalendar";
import ModalBtstrp from "react-bootstrap/Modal";
import {
  FaFileExcel,
  FaPlusSquare,
  FaMinusSquare,
  FaSave,
  FaCog,
  FaChevronDown,
} from "react-icons/fa";
import { Button as BtnAntd, Menu, Space, Dropdown as AntDropdown } from "antd";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { TbPlayerTrackPrev, TbPlayerTrackNext } from "react-icons/tb";
import { MultiSelect } from "primereact/multiselect";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import { useLocation, useNavigate } from "react-router-dom";

import { FcExpired, FcSettings } from "react-icons/fc";
import Loading from "./loading";

import AcctTransactionGrid from "./acctTransactionGrid";
import { formatDate } from "@telerik/kendo-intl";
import { Button } from "react-bootstrap";
import Enumerable from "linq";
import { distinct } from "@progress/kendo-data-query";
import { DropDownButton } from "@progress/kendo-react-all";
import { AiFillSetting } from "react-icons/ai";
import { IoSettings } from "react-icons/io5";
import { smaller } from "mathjs";
import { durationInMonths } from "@progress/kendo-date-math";
import { useSelector } from "react-redux";
import { selectTheme } from "../features/theme/themeSlice";
import { blue } from "@mui/material/colors";
import { alpha, styled } from "@mui/material/styles";
// import "@progress/kendo-theme-material/dist/all.css";
//import "@progress/kendo-theme-default/dist/all.css";

import { Viewer } from "@grapecity/activereports-react";
import { Modal } from "antd";
import { FaFilePdf } from "react-icons/fa";
import { Button as Button1 } from "@progress/kendo-react-buttons";
import { ColorRing } from "react-loader-spinner";
import { MdFilterAltOff } from "react-icons/md";

const BlueSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: blue[600],
    "&:hover": {
      backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: blue[600],
  },
}));
const AcctTransactionRpt = ({ acctId, selAccount }) => {
  var acctIdtemp = acctId;
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [filtercheckbox, setfiltercheckbox] = useState(null);
  const [AcctTransactionRptData, populateAcctTransactionRptData] = useState([]);
  const [AcctTransactionRptDataOrg, populateAcctTransactionRptDataOrg] =
    useState([]);
  const [loading, setLoading] = useState(true);
  const [modalDatecheck, setmodalDatecheck] = useState(false);
  const [datevalidationmessage, setdatevalidationmessage] = useState("");
  // const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
  // const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')).slice());
  const [session, setSession] = useState("");
  var date = new Date(localStorage.getItem("processingDate"));
  const navigate = useNavigate();
  date.setMonth(date.getMonth() - 1);
  const maxdaterange =
    localStorage.getItem("SelAcctId") == 0
      ? localStorage.getItem("pRltncd") == null ||
        localStorage.getItem("pRltncd") == undefined ||
        localStorage.getItem("pRltncd") == ""
        ? 12
        : 12
      : 12;
  const [frmDate, setfrmDate] = React.useState(date);
  const [pageno, setpageno] = React.useState(1);
  const [isClicked, setIsClicked] = useState(
    localStorage.getItem("isSearchClicked")
  );
  const [searchData, setSearchData] = useState(
    JSON.parse(localStorage.getItem("searchedData"))
  );
  const [firstFlag, setFirstFlag] = useState(0);
  const [isDisabled, setisDisabled] = React.useState(false);
  const [toDate, setToDate] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  );
  const [flag, setFlag] = React.useState(false);
  const [expandedState, setExpandedState] = React.useState(false);
  const [triggerExport, setTriggerExport] = useState(0);
  const [triggerColapse, setTriggerColapse] = useState(0);
  const [triggerExpand, setTriggerExpand] = useState(0);
  const [triggerColSave, setTriggerColSave] = useState(0);
  const [isColumnSave, setIsColumnSave] = useState(false);
  const [state, setState] = React.useState({ value: [], allSelected: true });
  const [effectivecDtInd, seteffectiveDtInd] = useState(true);
  const [excludeSuppress, setexcludeSuppress] = useState(true);
  const [sweepIndicator, setsweepIndicator] = useState(true);
  const [ddrTransTypeList, setDdrTransTypeList] = React.useState([]);
  const [ddrTransTypeVal, setDdrTransTypeVal] = useState([]);
  const [reportData, setReportData] = React.useState();

  const [pdfResultData, setPdfResultData] = useState();

  const handlePdfResultData = (pdfData) => {
    debugger;
    var reqData = new Object();
    reqData.administrator = reportData.administrator;
    reqData.accountType = reportData.accountType;
    reqData.maxPage = reportData.maxPage;
    reqData.endingBal = reportData.endingBal;
    reqData.ocAcctTransaction = pdfData;
    setPdfResultData(reqData);
  };

  const themes = useSelector(selectTheme);
  const countries = [
    { name: "Effective Date Indicator", code: "RM" },
    { name: "Exclude Suppress", code: "LDN" },
  ];
  var minDate = new Date(localStorage.getItem("processingDate"));
  minDate.setMonth(minDate.getMonth() - 60);
  var maxDate = new Date(localStorage.getItem("processingDate"));
  //maxDate.setMonth(maxDate.getMonth());
  const [minFrmDt, setminFrmDt] = React.useState(minDate);
  const [maxFrmDt, setmaxFrmDt] = React.useState(maxDate);
  const [selectedOption, setSelectedOption] = useState(null);

  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [clearing, setClearing] = useState(false);
  const [triggerClearfilter, setTriggerClearfilter] = useState(0);

  const viewerRef = React.useRef();

  const handleClose = () => {
    setShowModal(false);
  };

  const bankName = process.env.REACT_APP_BANK;
  // const bankName = "Demo";

  console.log("bankName", bankName);

  async function loadReport() {
    // load report definition from the file

    debugger;
    const reportResponse = await fetch("Transaction.rdlx-json");

    const report = await reportResponse.json();
    debugger;
    return report;
  }
  async function openReport() {
    debugger;
    const report = await loadReport();

    report.ReportSections[0].PageFooter.ReportItems[0].Value =
      bankName + ".png";

    console.log("report", report);
    console.log("viewerRef.current", viewerRef.current);

    console.log("JSON", AcctTransactionRptData);
    report.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify(pdfResultData);

    let parameters = [
      {
        // Name: "pConsName",
        // Value: [
        //   localStorage.getItem("SelAcctNm") == ""
        //     ? 0
        //     : localStorage.getItem("SelAcctNm"),
        // ],
        Name: "pConsName",
        Value: [
          localStorage.getItem("pConsName") == "" ||
          localStorage.getItem("pConsName") == null
            ? 0
            : localStorage.getItem("pConsName"),
        ],
      },
      {
        Name: "pAccName",
        Value: [
          localStorage.getItem("SelAcctNm") == "" ||
          localStorage.getItem("SelAcctNm") == null
            ? 0
            : localStorage.getItem("SelAcctNm"),
        ],
        // Name: "pConsName",
        // Value: [
        //   localStorage.getItem("pConsName") == ""
        //     ? 0
        //     : localStorage.getItem("pConsName"),
        // ],
      },
      {
        Name: "pShow",
        Value: [
          localStorage.getItem("SelAcctId") == "0" ? 1 : 0,

          // localStorage.getItem("pConsId") == "0"
          //   ? 0
          //   : localStorage.getItem("SelAcctNm"),
        ],
        // Name: "pConsName",
        // Value: [
        //   localStorage.getItem("pConsName") == ""
        //     ? 0
        //     : localStorage.getItem("pConsName"),
        // ],
      },
    ];

    /////////////////////
    // viewerRef.current.Viewer.open(report);
    viewerRef.current.Viewer.open(report, { ReportParams: parameters });
  }
  // const minFrmDt = new Date(2021,8,13);
  // const maxFrmDt = new Date(2022,8,14);
  let userId = JSON.parse(localStorage.getItem("userId"));
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        //let data = location.state;

        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;
        setDdrTransTypeVal(defaultItem);
        //setEmail(email);
        GetAcctTransactionData();
        getColumnStateDb();
        //  console.log(data);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, [userId]);
  function setDate(val) {
    if (val == null) {
      setfrmDate(frmDate);
      var newToDate = new Date(frmDate);
      newToDate.setMonth(frmDate.getMonth() + maxdaterange);
      if (newToDate >= new Date(localStorage.getItem("processingDate"))) {
        //setToDate(new Date(localStorage.getItem('processingDate')));
      } else {
        //setToDate(newToDate);
      }
    } else {
      setfrmDate(val);
      // var newToDate = new Date(val);
      // newToDate.setMonth(val.getMonth() + 6);
      // if (newToDate >= new Date(localStorage.getItem('processingDate'))) {
      //   setToDate(new Date(localStorage.getItem('processingDate')));
      // }
      // else {
      //   setToDate(toDate);
      //   if(toDate>newToDate)    //Added
      //   setToDate(newToDate);
      // }
    }
  }
  function setTDate(val) {
    if (val == null) {
      setToDate(toDate);
      // var newStartDate = new Date(toDate);
      // newStartDate.setMonth(toDate.getMonth() - 6);
      // setfrmDate(newStartDate);
    } else {
      setToDate(val);
      // var newStartDate = new Date(val);
      // newStartDate.setMonth(val.getMonth() - 6);
      // setfrmDate(frmDate);
      // if(frmDate<newStartDate)      //Added
      // setfrmDate(newStartDate);
    }
  }
  const getColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 35;

    const postData = { UserId, GridId };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTGetGridColumn/Index", postData, config)
      .then((response) => {
        // console.log('save in db');
        // console.log(response);
        const rowData = response.data;

        if (rowData !== "") {
          setIsColumnSave(true);
          localStorage.setItem("gridColumns", rowData);
        } else {
          setIsColumnSave(false);
        }
      })
      .catch((error) => {
        console.log("error in save db " + error);

        return error;
      });
  };
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetAcctTransactionData();
      })
      .catch((error) => {
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }

        console.log("my error is " + error);
      });
  };
  const formSubmit = (event) => {
    event.preventDefault();
    // console.log("Button Clicked")
    // console.log("Submit button press")

    if (frmDate == null || toDate == null) {
      setdatevalidationmessage("Enter date in correct format mm/dd/yyyy");
      setmodalDatecheck(true);
    } else {
      var start = frmDate;
      var end = toDate;
      var datesub = end.getDate() - start.getDate();

      const duration = durationInMonths(start, end);
      const datefrmmincheck = durationInMonths(minDate, end);
      const datetomincheck = durationInMonths(minDate, start);
      const datefrmmaxcheck = durationInMonths(end, maxDate);
      const datetomaxcheck = durationInMonths(start, maxDate);

      const validationRules = [
        {
          condition:
            duration > maxdaterange ||
            (duration === maxdaterange && datesub > 0),
          message:
            maxdaterange === 1
              ? "Date range should be within " + maxdaterange + " month"
              : "Date range should be within " + maxdaterange + " months",
        },
        {
          condition: frmDate > toDate,
          message: "From date cannot exceed to date.",
        },
        {
          condition: toDate > maxFrmDt,
          message: "To date cannot exceed from Processing date.",
        },
        {
          condition: toDate < minFrmDt,
          message: "To date cannot be less than 5 years from Processing date.",
        },
        {
          condition: frmDate > maxFrmDt,
          message: "From date cannot exceed Processing date.",
        },
        {
          condition: frmDate < minFrmDt,
          message:
            "From date cannot be less than 5 years from Processing date.",
        },
      ];

      const messages = validationRules
        .filter((rule) => rule.condition)
        .map((rule) => rule.message);

      if (messages.length > 0) {
        setdatevalidationmessage(messages.join("\n"));
        setmodalDatecheck(true);
      } else {
        setTriggerColSave(0);
        setDdrTransTypeVal(defaultItem);
        localStorage.setItem("SubmitClicked", 1);
        GetAcctTransactionData(1);
        setpageno(1);
        getColumnStateDb();
        setExpandedState(false);
      }
    }
  };
  const handleChangeCheckbox = (event) => {
    setfiltercheckbox(event.value);
  };
  const itemRender = (li, itemProps) => {
    const itemChildren = (
      <span>
        <input
          type="checkbox"
          name={itemProps.dataItem}
          checked={itemProps.selected}
          onChange={(e) => itemProps.onClick(itemProps.index, e)}
        />
        &nbsp;{li.props.children}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const GetAcctTransactionData = async (pageno = 1) => {
    setLoading(true);

    //let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    let userId = JSON.parse(localStorage.getItem("userId"));
    let acctId = localStorage.getItem("SelAcctId");
    //let startDate = "09/13/2019";
    //let startDate= (localStorage.getItem('processingDate'));
    let startDate = formatDate(frmDate, "MM/dd/yyyy");
    let endDate = formatDate(toDate, "MM/dd/yyyy");
    //let endDate = "09/13/2021";
    let EffectiveDtInd = effectivecDtInd;
    let ExcludeSupp = excludeSuppress;
    let SwpInd = sweepIndicator;

    // if(n==1){
    //   for(var i=0;i<filtercheckbox.length;i++){
    //     if(filtercheckbox[i].name==="Effective Date Indicator"){
    //       EffectiveDtInd=true;
    //     }
    //     else if(filtercheckbox[i].name==="Exclude Suppress"){
    //       ExcludeSupp=true;
    //     }
    //   }
    // }

    let RltnshpCd =
      localStorage.getItem("pRltncd") == null ||
      localStorage.getItem("pRltncd") == undefined
        ? ""
        : localStorage.getItem("pRltncd");
    let mConsolidationId = localStorage.getItem("pConsId");
    let PageNo =
      process.env.REACT_APP_TRANSACTION_PAGE_ENABLE !== "0" ? pageno : 0;
    const postData = {
      userId,
      startDate,
      endDate,
      acctId,
      mConsolidationId,
      EffectiveDtInd,
      ExcludeSupp,
      SwpInd,
      RltnshpCd,
      PageNo,
    };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/AcctTransactDateRange", postData, config)
      .then((response) => {
        //  console.log(response);

        const rowData = response.data;
        setReportData(rowData);
        setPdfResultData(rowData);
        if (rowData.ocAcctTransaction == null) {
          rowData.ocAcctTransaction = [];
          rowData.accountType = "";
          rowData.administrator = "";
          rowData.endingBal = 0;
        }
        localStorage.setItem("EndingBal", rowData.endingBal);
        localStorage.setItem("acctType", rowData.accountType);
        localStorage.setItem("Administrator", rowData.administrator);
        handleclearfilter();
        debugger;
        localStorage.setItem("MaxPage", rowData.maxPage);
        populateAcctTransactionRptDataOrg(rowData.ocAcctTransaction);
        if (
          ddrTransTypeVal.tranTypId != undefined &&
          ddrTransTypeVal.tranTypId != "-1" &&
          localStorage.getItem("SubmitClicked") === "0"
        ) {
          var resultByTransType = Enumerable.from(rowData.ocAcctTransaction)
            .where((w) => w.tranTypNm === ddrTransTypeVal.tranTypNm)
            .toArray();

          populateAcctTransactionRptData(resultByTransType);
        } else populateAcctTransactionRptData(rowData.ocAcctTransaction);
        var resultObjectDistinct = orderBy(
          distinct(rowData.ocAcctTransaction, "tranTypId"),
          [{ field: "tranTypNm", dir: "asc" }]
        );

        if (resultObjectDistinct.length == 0) setDdrTransTypeVal(defaultItem);
        setDdrTransTypeList(resultObjectDistinct);

        setFirstFlag(1);
        setFlag(true);
        setLoading(false);

        for (var i = 0; i < rowData.ocAcctTransaction.length; i++) {
          rowData.ocAcctTransaction[i].prcsDt = formatDate(
            new Date(rowData.ocAcctTransaction[i].prcsDt),
            "MM/dd/yyyy"
          );
          rowData.ocAcctTransaction[i].effectiveDate = formatDate(
            new Date(rowData.ocAcctTransaction[i].effectiveDate),
            "MM/dd/yyyy"
          );
          rowData.ocAcctTransaction[i].entryDate = formatDate(
            new Date(rowData.ocAcctTransaction[i].entryDate),
            "MM/dd/yyyy"
          );
          rowData.ocAcctTransaction[i].tradeDate = formatDate(
            new Date(rowData.ocAcctTransaction[i].tradeDate),
            "MM/dd/yyyy"
          );
        }
        localStorage.setItem(
          "filterData",
          JSON.stringify(rowData.ocAcctTransaction)
        );

        getColumnStateDb();
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        //return error;
      });
  };
  const filterChange = (event) => {};
  const filterData1 = (filter) => {
    // const dataAcct = selAcctData.slice();
    return filterBy(
      JSON.parse(localStorage.getItem("acctData")).slice(),
      filter
    );
  };
  const handleChange = (event) => {
    if (event.target.value === null) {
    } else {
      GetAcctTransactionData();
      //console.log(selAcct);

      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };

  const handleChangeTransType = (event) => {
    localStorage.setItem("SubmitClicked", 0);
    setDdrTransTypeVal(event.target.value);
    setFlag(true);
    if (event.target.value.tranTypId != -1) {
      var resultTransType = Enumerable.from(AcctTransactionRptDataOrg)
        .where((w) => w.tranTypNm === event.target.value.tranTypNm)
        .toArray();
      populateAcctTransactionRptData(resultTransType);
    } else {
      populateAcctTransactionRptData(AcctTransactionRptDataOrg);
    }
    setExpandedState(true);
    // handleTriggerColapse();
  };
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };
  const handleEffectiveDtInd = (e) => {
    seteffectiveDtInd(e.target.checked);
  };
  const handleExcludeSuppress = (e) => {
    setexcludeSuppress(e.target.checked);
  };
  const handleSweepIndicator = (e) => {
    setsweepIndicator(e.target.checked);
  };
  const handleExcelExport = (event) => {
    setTriggerExport((triggerExport) => triggerExport + 1);
  };

  const handleTriggerColapse = (event) => {
    setTriggerColapse((triggerColapse) => triggerColapse + 1);
    setExpandedState(false);
  };

  const handleTriggerExpand = (event) => {
    setTriggerExpand((triggerExpand) => triggerExpand + 1);
    setExpandedState(true);
  };
  const handleTriggerColSave = (event) => {
    setTriggerColSave((triggerColSave) => triggerColSave + 1);
  };
  const defaultItem = {
    tranTypNm: "All",
    tranTypId: "-1",
  };

  const handleclearfilter = (event) => {
    setTriggerClearfilter((triggerClearfilter) => triggerClearfilter + 1);
    console.log("clear filter", triggerColSave);
    setClearing(true);
    setTimeout(() => {
      setClearing(false);
    }, 1400);
  };

  const excelExport = () => {
    setShowModal(true);
    openReport();
  };

  if (loading) {
    return (
      <>
        <div className="mb-1 row d-flex  p-1 justify-content-between align-items-center">
          <Loading />
        </div>
      </>
    );
  }
  const value = state.value;
  const selected = value.length;
  return (
    <div>
      <ModalBtstrp
        style={{ zIndex: 1000000 }}
        show={showModal}
        onHide={handleClose}
        fullscreen={true}
        size="xl"
      >
        <ModalBtstrp.Body>
          <div id="viewer-host">
            <Viewer ref={viewerRef} />
          </div>
        </ModalBtstrp.Body>
        <ModalBtstrp.Footer>
          <button className="btn btn-primary  btn-sm" onClick={handleClose}>
            Close
          </button>
        </ModalBtstrp.Footer>
      </ModalBtstrp>
      <Modal
        title="Validation"
        open={modalDatecheck}
        onOk={(e) => {
          setmodalDatecheck(false);
        }}
        onCancel={(e) => {
          setmodalDatecheck(false);
        }}
        footer={[
          <Button
            key="Ok"
            onClick={(e) => {
              setmodalDatecheck(false);
            }}
          >
            Ok
          </Button>,
        ]}
      >
        {/* {datevalidationmessage} */}
        <div style={{ whiteSpace: "pre-line" }}>{datevalidationmessage}</div>
      </Modal>
      <div className="container-fluid rounded my-0 py-0">
        <>
          <table className="table table-borderless border border-bottom border-gray my-0 py-0">
            <tbody>
              <tr className="align-items-center">
                <td className="align-bottom">
                  <AntDropdown
                    overlay={
                      <Menu>
                        <Menu.Item onClick={handleTriggerColSave}>
                          <FaSave /> Save Columns
                        </Menu.Item>
                        <Menu.Item onClick={handleclearfilter}>
                          <MdFilterAltOff /> Clear Filters
                        </Menu.Item>
                        <Menu.Item onClick={handleExcelExport}>
                          <FaFileExcel /> Export to Excel
                        </Menu.Item>
                        <Menu.Item onClick={excelExport}>
                          <FaFilePdf /> Export to PDF
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <button className="btn btn-outline-secondary btn-sm ">
                      <FaCog /> Table Options <FaChevronDown />
                    </button>
                  </AntDropdown>
                </td>

                <td className="align-bottom">
                  <form
                    className="k-form d-flex gap-2 w-100"
                    onSubmit={formSubmit}
                  >
                    <div className="d-flex align-items-center gap-2">
                      <span
                        className="py-1"
                        style={{
                          color: themes === "dark" ? "white" : "#454545",
                        }}
                      >
                        From:
                      </span>
                      <DatePicker
                        className="form-control form-control-sm"
                        size={"small"}
                        value={frmDate}
                        width={150}
                        min={minFrmDt}
                        max={maxFrmDt}
                        valid={
                          frmDate >= minFrmDt &&
                          frmDate <= maxFrmDt &&
                          frmDate != null
                        }
                        disabled={isDisabled}
                        onChange={(e) => setfrmDate(e.value)}
                        validationMessage={
                          frmDate == null
                            ? "Enter Valid Date"
                            : frmDate < new Date("1/1/1000")
                            ? "Enter date in correct format (mm/dd/yyyy)"
                            : frmDate > maxFrmDt
                            ? "From date cannot exceed Processing date."
                            : frmDate > toDate
                            ? "From date cannot exceed to date."
                            : frmDate < minFrmDt
                            ? "From date cannot be less than Processing date."
                            : "Validation error"
                        }
                      />
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <span
                        className="py-1"
                        style={{
                          color: themes === "dark" ? "white" : "#454545",
                        }}
                      >
                        To:
                      </span>
                      <DatePicker
                        className="form-control form-control-sm"
                        size={"small"}
                        value={toDate}
                        width={150}
                        min={minFrmDt}
                        max={maxFrmDt}
                        valid={
                          frmDate <= toDate &&
                          toDate <= maxFrmDt &&
                          toDate >= minFrmDt
                        }
                        disabled={false}
                        onChange={(e) => setToDate(e.value)}
                        validationMessage={
                          toDate == null
                            ? "Enter Valid Date"
                            : toDate < new Date("1/1/1000")
                            ? "Enter date in correct format (mm/dd/yyyy)"
                            : toDate > maxFrmDt
                            ? "To date cannot exceed Processing date."
                            : toDate < frmDate
                            ? "To date cannot be less than from date"
                            : toDate < minFrmDt
                            ? "To date cannot be less than Processing date."
                            : "Validation error"
                        }
                      />
                    </div>
                    <button className="btn btn-primary btn-sm">Submit</button>
                  </form>
                </td>

                <td className="align-bottom">
                  <Dropdown autoClose="outside" id="ddrMenu">
                    <Dropdown.Toggle
                      variant="outline-none"
                      size="sm"
                      id="dropdown-basic"
                    >
                      <IoSettings
                        style={{
                          color: themes === "dark" ? "white" : "#454545",
                        }}
                        size={20}
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <FormControlLabel
                        control={
                          <BlueSwitch
                            checked={effectivecDtInd}
                            onClick={handleEffectiveDtInd}
                            name="chkCtr"
                          />
                        }
                        label="Effective Date"
                      />
                      <hr className="mt-1 mb-0 pb-0" />
                      <FormControlLabel
                        control={
                          <BlueSwitch
                            checked={excludeSuppress}
                            name="chkCtr"
                            onClick={handleExcludeSuppress}
                          />
                        }
                        label="Exclude Suppress Transaction"
                      />
                      <hr className="mt-1 mb-0 pb-0" />
                      <FormControlLabel
                        control={
                          <BlueSwitch
                            checked={sweepIndicator}
                            name="chkCtr"
                            onClick={handleSweepIndicator}
                          />
                        }
                        label="Exclude Sweep Transaction"
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </>
        {/* <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Transactions Report
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div className="accordion-body">

                <form onSubmit={formSubmit}  >

                  <div className='container-fluid'>

                    <div>
                      <div>

                        <div className='row d-flex  mx-2 px-1'>

                          <div className='col-md-3 col-lg-3 col-sm-10'>
                            <span className='py-1'>From</span>
                            <DatePicker id="dpFrm"
                              value={frmDate}
                              format="MM/dd/yyyy"
                              calendar={CustomCalendar}
                              min={minFrmDt}
                              max={maxFrmDt}
                              disabled={isDisabled}
                              onChange={(e) => {
                                setDate(e.value);

                              }}

                            />

                          </div>

                          <div className='col-md-3 col-lg-3 col-sm-10'>
                            <span className='py-1'>To</span>
                            <DatePicker
                              //defaultValue={toDate}
                              value={toDate}
                              calendar={CustomCalendar}
                              format="MM/dd/yyyy"
                              min={minFrmDt}
                              max={maxFrmDt}
                              disabled={false}
                              onChange={(e) => {
                                setTDate(e.value);

                              }}
                            />

                          </div>

                          <div className='col-md-2 col-lg-2 col-sm-10'>

                            <input type="submit" className='btn btn-primary btn-sm' value="Submit" style={{ marginTop: '20px' }} />

                          </div>

                        </div>
                      </div></div></div>
                </form>
              </div>
            </div>
          </div>
        </div> */}

        <div>
          {flag ? (
            <div
              className="row d-flex justify-content-between"
              style={{ border: "none" }}
            >
              <AcctTransactionGrid
                data={AcctTransactionRptData}
                flag={isColumnSave}
                triggerExport={triggerExport}
                triggerColapse={triggerColapse}
                triggerExpand={triggerExpand}
                triggerColSave={triggerColSave}
                triggerClearfilter={triggerClearfilter}
                resultPdfData={handlePdfResultData}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default AcctTransactionRpt;
