import React from "react";
import { formatNumber, formatDate } from "@telerik/kendo-intl";
import { Button, Card } from "@mui/material";
import AcctHoldingRpt from "./acctHoldingRpt";
import { Tabs } from "antd";
import Tooltip from "@mui/material/Tooltip";
import { Layout } from "antd/es";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import { ResponsiveContainer } from "recharts";
import DisbursementGrid from "./disbursementGrid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TransferGrid from "./transferGrid";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import WFGrid from "./workflowGrid";
import DailyPerformanceRpt from "./dailyPerformanceRpt";
import ConsolidationRpt from "./consolidationRpt";
//import "@progress/kendo-theme-bootstrap/dist/all.css";

import { groupBy } from "@progress/kendo-data-query";
import { SiHandshake } from "react-icons/si";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Enumerable from "linq";
import {
  Sparkline,
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartSeriesDefaults,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartSeriesLabels,
  ChartLegend,
  LegendItemClickEvent,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import { useState } from "react";
import CustomReportMain from "./cstmRptMain";
import DepositGrid from "./depositGrid";
import MemoTickler from "./memoTickler";
import NetOverdraftChart from "./netOverdraftChart";
import DashboardTab from "./dashboardTab";
import AcctTransactionRpt from "./acctTransactionRpt";
import CustomDashboardRpt from "./cstmDashBoardRpt";
import AcctPerfRpt from "./AcctPerfRpt";
import TimeWtdRtnRpt from "./TimeWtdRtnRpt";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
  Outlet,
  useParams,
  NavLink,
  useNavigate,
  useLocation,
} from "react-router-dom";
import StatementRpt from "./statementRpt";
import { Header } from "antd/es/layout/layout";
import AddressBook from "./addressBook";
import TbRpt from "./tbRpt";
import StickyBox from "react-sticky-box";
import { selectTheme } from "../features/theme/themeSlice";
import { useSelector } from "react-redux";

var DefaultTab = process.env.REACT_APP_DEFAULT_TAB;
const DashboardContent = ({
  dashboardData,
  acctId,
  selAccount,
  menuStateData,
}) => {
  const TabPane = Tabs.TabPane;
  const [activeTab, setactiveTab] = useState(DefaultTab);

  const [perfTabtoggle, setPerfTabtoggle] = useState(
    process.env.REACT_APP_PERFORMANCE
  );
  var disbStatus = true;
  var rltncd =
    localStorage.getItem("pRltncd") === null ||
    localStorage.getItem("pRltncd") === undefined ||
    localStorage.getItem("pRltncd") === "";

  if (acctId > 0 || localStorage.getItem("pConsId") > 0 || !rltncd)
    disbStatus = false;
  var roletypeId = 1; //JSON.parse(localStorage.getItem("UserRoleData"));
  console.log("roleID das ", roletypeId);
  const [disableTab, setDisableTab] = React.useState(disbStatus);

  var disbStatusTB = true;
  if (
    acctId == 0 &&
    localStorage.getItem("pConsId") > 0 &&
    localStorage.getItem("SelConsTypeVal") == 14
  )
    disbStatusTB = false;

  const [disableTB, setDisableTB] = React.useState(disbStatusTB);

  if (
    localStorage.getItem("pConsId") == 0 &&
    acctId === 0 &&
    (localStorage.getItem("rltnshpCd") == "" ||
      localStorage.getItem("rltnshpCd") == null)
  ) {
    if (localStorage.getItem("activeTab") != "8")
      localStorage.setItem("activeTab", DefaultTab);
  }

  const handleTabClick = (event) => {
    localStorage.setItem("activeTab", event);
  };

  const themes = useSelector(selectTheme);
  const tabbgcolor = themes === "dark" ? "#121212" : "#f6f7f9";

  const items = new Array(20).fill(null).map((_, i) => {
    const id = String(i + 1);

    return {
      label: id == 1 ? "DashBoard" : id == 2 ? "Holdings" : "Transaction",
      key: id,
      children:
        id == 1 ? (
          <div>
            <DashboardTab dashboardData={dashboardData}></DashboardTab>
          </div>
        ) : id == 2 ? (
          <AcctHoldingRpt
            acctId={acctId}
            selAccount={selAccount}
          ></AcctHoldingRpt>
        ) : (
          "Transaction"
        ),
      style:
        i === 0
          ? {
              height: 200,
            }
          : undefined,
    };
  });

  const renderTabBar1 = (props, DefaultTabBar) => (
    <StickyBox
      offsetTop={0}
      offsetBottom={20}
      style={{
        position: "fixed",
        zIndex: 1,
        top: 40,
        padding: 0,
        height: "38px",
        lineHeight: "20px",
      }}
    >
      <DefaultTabBar {...props} />
    </StickyBox>
  );
  const renderTabBar = (props, DefaultTabBar) => (
    <Layout>
      <Header
        style={{
          position: "fixed",
          zIndex: 1,
          top: 40,
          width: 1000,
          padding: 0,
          height: "38px",
          lineHeight: "20px",
          background: tabbgcolor,
        }}
      >
        <DefaultTabBar
          {...props}
          style={{
            top: 17,
            width: 1000,
          }}
        />
      </Header>
    </Layout>
  );

  return (
    <>
      {/* <Tabs defaultActiveKey="1" renderTabBar={renderTabBar1} items={items} /> */}
      <Tabs
        renderTabBar={renderTabBar}
        defaultActiveKey={localStorage.getItem("activeTab")}
        onChange={handleTabClick}
      >
        <TabPane
          style={{ height: 200 }}
          size={"small"}
          className="bodybg"
          tab="Dashboard"
          key="1"
        >
          <div>
            <DashboardTab dashboardData={dashboardData}></DashboardTab>
          </div>
        </TabPane>

        <TabPane
          style={{ height: 200 }}
          size={"small"}
          // title={disableTab ? "your tooltip" : ""}
          className="bodybg"
          //tab="Holdings"
          tab={
            <Tooltip
              title={
                disableTab ? (
                  <span style={{ fontSize: 12 }}>
                    Please select an account or relationship on left panel to
                    continue with the tab.
                  </span>
                ) : (
                  ""
                )
              }
            >
              <span>Holdings</span>
            </Tooltip>
          }
          key="2"
          disabled={disableTab}
        >
          <AcctHoldingRpt
            acctId={acctId}
            selAccount={selAccount}
          ></AcctHoldingRpt>
        </TabPane>

        <TabPane
          style={{ height: 200 }}
          size={"small"}
          className="bodybg"
          //  tab="Trust Brief"
          tab={
            <Tooltip
            // title={
            //   disbStatusTB ? (
            //     <span style={{ fontSize: 12 }}>
            //       Please select an account or relationship on left panel to
            //       continue with the tab.
            //     </span>
            //   ) : (
            //     ""
            //   )
            // }
            >
              <span>Trust Brief</span>
            </Tooltip>
          }
          disabled={disbStatusTB}
        >
          <TbRpt menuStateData={menuStateData}></TbRpt>
        </TabPane>
        <TabPane
          style={{ height: 200 }}
          size={"small"}
          className="bodybg"
          // tab="Transactions"
          tab={
            <Tooltip
              title={
                disableTab ? (
                  <span style={{ fontSize: 12 }}>
                    Please select an account or relationship on left panel to
                    continue with the tab.
                  </span>
                ) : (
                  ""
                )
              }
            >
              <span>Transactions</span>
            </Tooltip>
          }
          key="3"
          disabled={disableTab}
        >
          <AcctTransactionRpt acctId={acctId} selAccount={selAccount} />
        </TabPane>

        {process.env.REACT_APP_PERF_DISPLAY === "0" ? (
          <></>
        ) : (
          <TabPane
            size={"small"}
            className="bodybg"
            // tab="Performance"
            tab={
              <Tooltip
                title={
                  disableTab ? (
                    <span style={{ fontSize: 12 }}>
                      "Please select an account or relationship on left panel to
                      continue with the tab."
                    </span>
                  ) : (
                    ""
                  )
                }
              >
                <span>Performance</span>
              </Tooltip>
            }
            key="4"
            disabled={rltncd ? disableTab : true}
          >
            {perfTabtoggle === "0" ? (
              <DailyPerformanceRpt acctId={acctId}></DailyPerformanceRpt>
            ) : (
              <TimeWtdRtnRpt acctId={acctId} />
            )}
          </TabPane>
        )}

        {process.env.REACT_APP_CUSTOM === "0" ? (
          <></>
        ) : (
          <TabPane
            size={"small"}
            // tab="Custom"
            tab={
              <Tooltip
                title={
                  disableTab ? (
                    <span style={{ fontSize: 12 }}>
                      "Please select an account or relationship on left panel to
                      continue with the tab."
                    </span>
                  ) : (
                    ""
                  )
                }
              >
                <span>Custom</span>
              </Tooltip>
            }
            key="6"
            disabled={disableTab}
            //disabled={acctId > 0 ? false : true}
          >
            {(
              <CustomDashboardRpt
                acctId={acctId}
                menuStateData={menuStateData}
              />
            ) || (
              <Router>
                <Routes>
                  <Route path="/cstmRptMain" element={<CustomReportMain />} />
                </Routes>
              </Router>
            )}
          </TabPane>
        )}
        <TabPane
          size={"small"}
          //tab="Statements"
          tab={
            <Tooltip
              title={
                disableTab ? (
                  <span style={{ fontSize: 12 }}>
                    Please select an account or relationship on left panel to
                    continue with the tab.
                  </span>
                ) : (
                  ""
                )
              }
            >
              <span>Statements</span>
            </Tooltip>
          }
          key="7"
          disabled={disableTab}
        >
          <StatementRpt></StatementRpt>
        </TabPane>
        <TabPane size={"small"} tab="Relationships" key="8">
          <ConsolidationRpt></ConsolidationRpt>
        </TabPane>
        <TabPane size={"small"} tab="Address Book" key="9">
          <AddressBook addData={dashboardData.lstAddress}></AddressBook>
        </TabPane>
      </Tabs>
    </>
  );
};
export default DashboardContent;
